import { Flex } from "antd";
import styled from "styled-components";

type Custom = {
  border?: string;
  borderWidth?: string;
  itemWidth?: string;
};

export const TabsWrap = styled(Flex)<Custom>`
  border: ${({ border }) => border || "1px solid var(--lightGraySecondary)"};
  border-width: ${({ borderWidth }) => borderWidth || "0.08rem 0 0.08rem 0"};
  gap: 1.2rem;
  margin-top: 1.3rem;
  width: 100%;
  .tab__item {
    padding: 1rem 0.3rem 0.8rem 0.3rem;
    transition: all 0.3s ease-in-out;
    font-size: 0.9rem;
    color: var(--lightGrayPrimary);
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    position: relative;
    width: ${({ itemWidth }) => itemWidth || "max-content"};

    .tab__numbervalue {
      margin: 0;
      padding: 0.2rem 0.5rem;
      border-radius: 100%;
      background-color: #ebebebf7;
      color: var(--lightGrayPrimary);
      font-weight: 500;
      font-size: 0.8rem;
      margin-left: 0.2rem;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      width: 0;
      height: 0.1rem;
      background-color: var(--bluePrimary);
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      transition: all 0.3s ease-in-out;
    }
  }
  .active__tabitem {
    color: var(--bluePrimary);

    .tab__numbervalue {
      color: var(--bluePrimary);
      background-color: #a0c8f880;
    }
    &::after {
      width: 100%;
    }
  }
`;
