// general layout for investor General page

import React, { useState } from "react";
import { Flex } from "antd";
import { watchersTransactionData } from "../../../../../utils/data-helpers/watchers";
import {
  TransferIcon,
  WalletIcon,
} from "../../../../../assets/images/dashboard/svgExports";
import { ViewConnectedWatcherModalWrap } from "../header.styles";
import { formatMoney } from "../../../../../utils/function-helpers/string-functions";
import moment from "moment";

interface IProps {
  setShowViewWatcherModal?: any;
  showViewWatcherModal?: boolean;
  connectedWatcher?: Record<string, any>;
}

const ViewConnectedWatcherModal: React.FC<IProps> = ({
  setShowViewWatcherModal,
  showViewWatcherModal,
  connectedWatcher,
}) => {
  return (
    <ViewConnectedWatcherModalWrap
      open={showViewWatcherModal}
      onCancel={() => {
        setShowViewWatcherModal(false);
      }}
      footer={null}
      centered
    >
      <Flex vertical className="viewconnectedwatcher__section">
        <Flex vertical align="start" className="viewwatcher__headersection">
          <h3 className="viewwatcher__header">Watchers Sling </h3>
          <p className="viewwatcher__subheader">
            Hi, {connectedWatcher?.data?.profile?.firstName}
          </p>
        </Flex>

        <Flex className="watcher__balance__section" vertical align="center">
          <p className="balancesecion__ptext">My Current Balance</p>
          <Flex justify="center" align="center">
            <h3 className="balance">
              {" "}
              {formatMoney(connectedWatcher?.data?.wallet?.balance, true)}
            </h3>
            <p className="balancesecion__ptext">Shekels</p>
          </Flex>

          <p className="balancesecion__ptext">
            in Naira = N
            {formatMoney(connectedWatcher?.data?.wallet?.balance * 200, true)}
          </p>
        </Flex>

        <Flex className="watcherdetails__section" justify="space-between">
          <p className="watcherdetails__p  watcherdetails__left">
            Lohli ID
            <p className="watcherdetails__span">
              {" "}
              {connectedWatcher?.data?.profile?.username}
            </p>
          </p>

          <p className="watcherdetails__p watcherdetails__right">
            Account <br />
            <span className="watcherdetails__span">
              {connectedWatcher?.data?.accountNumber}
            </span>
          </p>
        </Flex>
        <Flex vertical className="transaction__history__section">
          <h3 className="transaction__h3">Transactions History</h3>

          <Flex className="transaction__items__wrap" vertical>
            {connectedWatcher?.data?.transactions?.data?.map(
              (item: any, idx: number) => (
                <Flex
                  key={idx}
                  align="flex-start"
                  justify="space-between"
                  className="transaction__item__main"
                >
                  <Flex className="icon__headerwrap">
                    {item?.type === "DEBIT" ? <TransferIcon /> : <WalletIcon />}{" "}
                    <h3 className="transaction__h3">
                      {item.type}
                      <p className="transaction__description">
                        {item.type === "DEBIT"
                          ? `${formatMoney(
                              item.amount,
                              true
                            )} Shekels has been deducted for ${item.source}`
                          : `${item.amount} Shekels has been credited `}
                        {item.description}
                      </p>
                    </h3>
                  </Flex>
                  <p className="transaction__time">
                    {moment(item.createdAt).fromNow()}
                  </p>
                </Flex>
              )
            )}
          </Flex>
        </Flex>
      </Flex>
    </ViewConnectedWatcherModalWrap>
  );
};

export default ViewConnectedWatcherModal;
