import React, { useState } from "react";
import { Flex } from "antd";
import { QuestionsModalWrap } from "../uploadView.styles";
import { LohButton } from "../../../../components/lib/Button";
import { LohCheckbox } from "../../../../components/lib/Checkbox";

interface IProps {
  showQuestionsModal?: boolean;
  setShowQuestionsModal?: any;
  questions?: any;
}

const QuestionsModal: React.FC<IProps> = ({
  showQuestionsModal,
  setShowQuestionsModal,
  questions,
}) => {
  const closeModal = () => setShowQuestionsModal(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);

  return (
    <QuestionsModalWrap
      open={showQuestionsModal}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <Flex vertical align="start">
        <h3 className="modal__header">Quiz Question</h3>
        <p className="questionmodal__subtitle">
          Question {currentQuestionId + 1} of {questions?.length}
        </p>
      </Flex>
      <Flex vertical className="question__wrap">
        {questions?.map(
          (item: Record<string, any>, idx: number) =>
            idx === currentQuestionId && (
              <Flex className="question__main" vertical>
                <p className="question">{item?.question}</p>

                <span
                  className="answers"
                  id={item.answer === "OPTION_ONE" ? "correct__answer" : ""}
                >
                  A. {item.optionOne}
                </span>
                <span
                  className="answers"
                  id={item.answer === "OPTION_TWO" ? "correct__answer" : ""}
                >
                  B. {item.optionTwo}
                </span>
                <span
                  className="answers"
                  id={item.answer === "OPTION_THREE" ? "correct__answer" : ""}
                >
                  C. {item.optionThree}
                </span>
                <span
                  className="answers"
                  id={item.answer === "OPTION_FOUR" ? "correct__answer" : ""}
                >
                  D. {item.optionFour}
                </span>
              </Flex>
            )
        )}
      </Flex>
      <Flex justify="center" gap="1rem" className="buttons__wrap">
        <LohButton
          backgroundColor="transparent"
          borderColor="var(--lightGraySecondary)"
          color="var(--blackSecondary)"
          htmlType="submit"
          width="13rem"
          padding="0"
          height="2.5rem"
          onClick={() => setCurrentQuestionId(currentQuestionId - 1)}
          disabled={currentQuestionId === 0}
        >
          Previous
        </LohButton>
        <LohButton
          onClick={() => setCurrentQuestionId(currentQuestionId + 1)}
          width="13rem"
          padding="0"
          height="2.5rem"
          disabled={currentQuestionId + 1 === questions?.length}
        >
          Next
        </LohButton>
      </Flex>
    </QuestionsModalWrap>
  );
};
export default QuestionsModal;
