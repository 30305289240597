export const feedData = [
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },

  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
  {
    title: "Eva bottling company",
    userName: "@eva_co",
    descripton: "Elections are close, y’all be safe.",
    likes: "2,070",
    comments: "325",
  },
];
