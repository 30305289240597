import React, { useState } from "react";
import { Flex } from "antd";
import { NotificationModalWrap } from "../notifications.styles";
import { LohButton } from "../../../../components/lib/Button";
import moment from "moment";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { editNotificationUsernameString } from "../../../../utils/function-helpers/string-functions";

interface IProps {
  showReadSingleNotificationModal?: boolean;
  setShowReadSingleNotificationModal?: any;
  singleNotification?: Record<string, any>;
}

const ReadSingleNotificationModal: React.FC<IProps> = ({
  showReadSingleNotificationModal,
  setShowReadSingleNotificationModal,
  singleNotification,
}) => {
  const closeModal = () => setShowReadSingleNotificationModal(false);
  const {
    state: { user },
  } = useAdvertiserContext();

  return (
    <NotificationModalWrap
      open={showReadSingleNotificationModal}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <Flex vertical align="start">
        <Flex
          justify="space-between"
          className="singlenotification__modalheader"
        >
          <h3>Hi {user?.profile?.firstName},</h3>{" "}
        </Flex>

        <p className="notification__body">
          {editNotificationUsernameString(
            singleNotification?.description,
            singleNotification?.user?.profile?.username
          ) || "No Subtext"}
        </p>
        <small>{moment(singleNotification?.createdAt).fromNow()}</small>
      </Flex>
    </NotificationModalWrap>
  );
};
export default ReadSingleNotificationModal;
