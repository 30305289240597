import React, { useState } from "react";
import { Flex, notification } from "antd";
import { VerifyBvnModalWrap } from "../profile.styles";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { userVerifyBvn } from "../../../../network/auth";
import { CustomForm } from "../../../../components/lib/Form";
import { validateBvn } from "../../../../utils/function-helpers/form-validators";

interface IProps {
  showBvnModal?: boolean;
  setShowBvnModal?: any;
  virtualAccountRefetching: any;
}

const VerifyBvn: React.FC<IProps> = ({
  showBvnModal,
  setShowBvnModal,
  virtualAccountRefetching,
}) => {
  const [isVerifying, setIsVerifying] = useState(false);

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsVerifying(true);

    const payload = {
      ...values,
    };

    try {
      await userVerifyBvn(payload);
      await virtualAccountRefetching();
      setIsVerifying(false);
      setShowBvnModal(false);
      notification.open({
        message: `Your BVN verification request has been submitted.`,
        description:
          "Please note that the verification process may take up to 8 hours. You will be notified via email once the verification is complete.",
        style: {
          color: "#FFFFFF",
          backgroundColor: "#027A48",
        },
        duration: 10,
        className: "verify-bvn-success",
      });
    } catch (error: any) {
      setIsVerifying(false);

      if (error?.response) {
        notification.open({
          message: `BVN Verification Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const formDetails = [
    {
      name: "bvn",
      placeholder: "Enter your bvn",
      validator: validateBvn,
      type: "number",
    },
  ];

  return (
    <VerifyBvnModalWrap
      open={showBvnModal}
      onCancel={() => setShowBvnModal(false)}
      footer={null}
      centered
    >
      <Flex vertical align="start" justify="start">
        <h3>Verify BVN</h3>
        <p className="bvn__subheader">
          Please Note: Ensure your First and Last name on your Lohli account
          match your First and Last name on your bvn/bank account.
        </p>
      </Flex>

      <CustomForm
        formFinishButtonText="Verify"
        handleSubmit={handleFormSubmit}
        formDetails={formDetails}
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isVerifying}
      />
    </VerifyBvnModalWrap>
  );
};
export default VerifyBvn;
