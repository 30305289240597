import styled from "styled-components";
import { DatePicker } from "antd";

type Custom = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  opacity?: string;
  radius?: string;
  boxShadow?: string;
  fontSize?: string;
  color?: string;
  hoverColor?: string;
  fontWeight?: string;
  hoverBg?: string;
  outlined?: boolean;
  maxWidth?: string;
  textarea?: string;
  hasicon?: boolean;
  status?: any;
  shekels?: boolean;
};

export const LohDateRangepicker = styled(DatePicker.RangePicker)<Custom>`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "3rem"};
  border: ${({ border }) => border || ".08rem solid var(--lightGraySecondary)"};
  border-radius: ${({ radius }) => radius || "0.7rem"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: ${({ borderColor }) =>
      borderColor || "var(--lightGraySecondary)"};

    .ant-picker-clear {
      display: none;
    }
  }

  .ant-picker-clear {
    background: transparent;
    transform: scalse(1);
  }

  svg {
    color: ${({ color }) => color || "var(--blackPrimary)"};
    margin-bottom: -4px;
    font-size: 1rem;
    opacity: 0.3;
  }
  input {
    color: ${({ color }) => color || "var(--blackPrimary)"};
    font-family: "MadeOuterLight", sans-serif;
    font-size: 1rem;

    ::placeholder {
      color: ${({ color }) => color || "var(--blackPrimary)"};
      opacity: ${({ borderColor }) => (borderColor ? "1" : ".7")};
      font-size: 1rem;
    }
  }
`;

export const LohDatepicker = styled(DatePicker)<Custom>`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "3rem"};
  border: ${({ border }) => border || ".08rem solid var(--lightGraySecondary)"};
  border-radius: ${({ radius }) => radius || "0.7rem"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: ${({ borderColor }) =>
      borderColor || "var(--lightGraySecondary)"};

    .ant-picker-clear {
      display: none;
    }
  }

  .ant-picker-clear {
    background: transparent;
    transform: scalse(1);
  }

  svg {
    color: ${({ color }) => color || "var(--blackPrimary)"};
    margin-bottom: -4px;
    font-size: 1rem;
    opacity: 0.3;
  }
  input {
    color: ${({ color }) => color || "var(--blackPrimary)"};
    font-family: "MadeOuterLight", sans-serif;
    font-size: 1rem;

    ::placeholder {
      color: ${({ color }) => color || "var(--blackPrimary)"};
      opacity: ${({ borderColor }) => (borderColor ? "1" : ".7")};
      font-size: 1rem;
    }
  }
`;
