import { Flex } from "antd";
import styled from "styled-components";

export const AdsWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;

  .adupload__header {
    font-size: 2rem;
  }

  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;
  }
`;
