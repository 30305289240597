import { Flex, Modal } from "antd";
import styled from "styled-components";

type Custom = {
  height?: string;
};

// export const CustomFormContainer = styled("form")`
export const TableWrap = styled(Flex)<Custom>`
  width: 100%;
  margin-top: 1rem;
  height: ${({ height }) => height || "calc(100vh - 19rem)"};
  overflow-y: scroll;

  .web__table {
    width: 100%;
  }

  .nodata__wrap {
    width: 100%;
    height: 80%;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }

  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-container {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 1rem;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    min-width: 100%;
  }
  .ant-table {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .table__image__wrap {
    width: 9rem;
    min-width: 9rem;
    height: 5rem;
  }

  .adtitle__des {
    max-width: 22.5rem;
    p {
      text-align: left;
      margin: 0;
      color: var(--blackPrimary);
      font-size: 1rem;
      white-space: nowrap;
    }

    .table__des {
      max-width: 85%;
      color: var(--lightGrayPrimary);
      white-space: wrap;
    }
  }

  .th__titles {
    margin: 0;
    color: var(--blackPrimary);
    font-weight: 500;
    white-space: nowrap;
    font-size: 1rem;
  }
  .video__th__title {
    text-align: left;
  }
  .number__texts {
    font-size: 0.9rem;
  }

  .icons__wrap {
    gap: 1.2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    svg {
      color: var(--darkGrayPrimary);
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
  .ant-table-thead > tr > th {
    background-color: #ebebebf7;
    border-radius: 0 !important;
    padding: 1rem;
  }

  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }
  .mobile__table {
    width: 100%;

    .adtitle__des {
      width: 18.3rem;
    }
    .table__des {
      width: 100%;
      font-size: 0.9rem;
    }
    .table__image__wrap {
      height: 6rem;
      max-width: 200px;
    }
    .th__titles {
      font-weight: 700;
    }
    .mobile__adheader {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mobile__icons__wrap {
      width: 100%;
      .mobile__iconswrap__left {
        gap: 0.7rem;
        p {
          color: var(--lightGrayPrimary);
          font-size: 1rem;

          svg {
            margin: 0 1px -2px 0;
          }
        }
      }
    }

    .ant-table-tbody > tr > td {
      padding: 16px 0;
    }
    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  @media screen and (max-width: 950px) {
    height: ${({ height }) => height || "calc(100vh - 24rem)"};
  }

  @media screen and (max-width: 600px) {
    .ant-spin-container {
      align-items: center;
    }
    .web__table {
      display: none;
      width: 100%;
    }
  }
`;

export const DeleteModal = styled(Modal)`
  .buttons__wrap {
    margin-top: 2rem;
  }
`;
