import React, { useCallback, useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { LohButton } from "../../../../components/lib/Button";
import { FeedsPageWrap } from "./feeds.styles";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NavTabs from "../../../../components/lib/Tabs/navTabs";
import { feedData } from "../../../../utils/data-helpers/feed";
import PostDemoVidAd from "../../../../assets/images/dashboard/advertiser-feed-demo.png";
import PostDemoVidWather from "../../../../assets/images/dashboard/watcher-feed-demo.png";
import { IoHeartOutline } from "react-icons/io5";
import { TextField } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateVideo,
} from "../../../../utils/function-helpers/form-validators";
import {
  BsChatLeft,
  BsEmojiSmileUpsideDownFill,
  BsEye,
  BsPersonCircle,
} from "react-icons/bs";
import {
  pluralizeString,
  truncateString,
} from "../../../../utils/function-helpers/string-functions";
import PostAvatar from "../../../../assets/images/dashboard/feed-avatar.png";
import { useQuery } from "react-query";
import { getFeedPosts } from "../../../../network/posts";
import ReactPlayer from "react-player";
import moment from "moment";
import LohliAvatar from "../../../../components/lib/Avatar/avatar";
import { advertiserFollowUser } from "../../../../network/auth";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";
import useInfiniteScroll from "../../../../utils/hooks/useInifiniteScroll";
import { debounce } from "../../../../utils/function-helpers/debounce";

const FeedsPage = () => {
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();
  const navigate = useNavigate();

  const [isFollowingLoading, setIsFollowingLoading] = useState(-1);
  const [search, setSearch] = useState<string>("");

  console.log(search);

  const {
    data: advertiserPosts,
    isLoading: advertiserPostsLoading,
    isFetching: advertiserPostsFetching,
    refetch: refetchAdPosts,
    lastElementRef: lastAdvertisersElementRef,
    total: advertiserPostsTotal,
  } = useInfiniteScroll({
    queryFn: ({ pageParam = 1 }) =>
      getFeedPosts("ACTIVE", "ADVERTISER_POST", pageParam, search),
    queryKey: ["activeadvertistpost", search],
  });

  // const {
  //   data: advertiserPosts,
  //   isLoading: advertiserPostsLoading,
  //   refetch: refetchAdPosts,
  // } = useQuery(["activeadvertistpost"], () =>
  //   getFeedPosts("ACTIVE", "ADVERTISER_POST", 1)
  // );

  // console.log(advertiserPosts);

  const [activeFeedTab, setActiveFeedTab] = useState({
    title: "",
    status: "",
    noDataState: "",
    data: [],
    isLoading: false,
    isFetching: false,
    type: "ADVERTISER_POST",
    refetch: refetchAdPosts,
    total: 0,
  });

  // const {
  //   data: watchersPosts,
  //   isLoading: watchersPostsLoading,
  //   refetch: refetchWatcherPosts,
  // } = useQuery(["watcherspost", activeFeedTab.type], () =>
  //   getFeedPosts("ACTIVE", "WATCHER_POST", 1)
  // );

  const {
    data: watchersPosts,
    isLoading: watchersPostsLoading,
    isFetching: watchersPostsFetching,
    refetch: refetchWatcherPosts,
    lastElementRef: lastWatchersElementRef,
    total: watchersPostsTotal,
  } = useInfiniteScroll({
    queryFn: ({ pageParam = 1 }) =>
      getFeedPosts("ACTIVE", "WATCHER_POST", pageParam, search),
    queryKey: ["watcherspost", search],
  });

  useEffect(() => {
    setActiveFeedTab({
      title: "Advertisers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: (advertiserPosts?.data ?? []) as any,
      isLoading: advertiserPostsLoading,
      isFetching: advertiserPostsFetching,
      type: "ADVERTISER_POST",
      refetch: refetchAdPosts,
      total: advertiserPostsTotal,
    });
  }, []);

  const feedTabs = [
    {
      title: "Advertisers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: advertiserPosts?.data,
      isLoading: advertiserPostsLoading,
      isFetching: advertiserPostsFetching,
      type: "ADVERTISER_POST",
      refetch: refetchAdPosts,
      total: advertiserPostsTotal,
    },
    {
      title: "Watchers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: watchersPosts?.data,
      isLoading: watchersPostsLoading,
      isFetching: watchersPostsFetching,
      type: "WATCHER_POST",
      refetch: refetchWatcherPosts,
      total: watchersPostsTotal,
    },
  ];

  const handleFollowUser = async (
    status: boolean | undefined,
    userId: number
  ) => {
    setIsFollowingLoading(userId);
    try {
      await advertiserFollowUser({ status: !status, userId: userId });
      await activeFeedTab.refetch();
      setIsFollowingLoading(-1);
    } catch (error: any) {
      setIsFollowingLoading(-1);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const activeFeedData =
    feedTabs?.find((item) => item.title === activeFeedTab?.title)?.data ?? [];

  const activeFeedLoading =
    feedTabs?.find((item) => item.title === activeFeedTab?.title)?.isLoading ||
    false;
  const activeFeedFetching =
    feedTabs?.find((item) => item.title === activeFeedTab?.title)?.isFetching ||
    false;

  // console.log(activeFeedTab?.data);

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
      },
      1000,
      false
    ),
    []
  );
  return (
    <DashboardLayout>
      <FeedsPageWrap vertical>
        <Flex justify="space-between">
          <h1 className="post__header">Feeds</h1>

          {feedData?.length > 0 && (
            <Flex>
              <TextField
                placeholder="Search"
                handleChange={(e: any) => {
                  handleSearch(e);
                }}
              />
            </Flex>
          )}
        </Flex>

        <NavTabs
          navTabData={feedTabs}
          setActiveTab={setActiveFeedTab}
          activeTab={activeFeedTab}
          hasItemCount
        />
        {activeFeedLoading ? (
          <LohliLoader />
        ) : (
          <Flex className="feedposts__wrap has__coinbg">
            {activeFeedData?.length > 0 ? (
              <div>
                <Flex className="feedposts__wrapsub" vertical>
                  {activeFeedData?.map((item: Record<string, any>, idx) => (
                    <Flex className="feedposts__main" key={idx} vertical>
                      {item?.post?.media[0]?.publicURL && (
                        <div className="feedpost__video">
                          {!!validateVideo(
                            item?.post?.media[0]?.publicURL || ""
                          ) ? (
                            <ReactPlayer
                              url={item?.post?.media[0]?.publicURL}
                              width={"100%"}
                              height={"100%"}
                            />
                          ) : (
                            <img
                              src={item?.post?.media[0]?.publicURL}
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </div>
                      )}
                      <Flex className="feed__body" vertical>
                        <Flex className="feedposter__details">
                          <div
                            className="avatar__wrap"
                            onClick={() =>
                              navigate("/feeds/poster-profile", {
                                state: {
                                  userId: item?.post?.userId,
                                  data: item?.post,
                                  type: activeFeedTab.type,
                                },
                              })
                            }
                          >
                            <LohliAvatar
                              imageUrl={
                                item?.post?.user?.profile?.avatar?.publicURL ||
                                ""
                              }
                            />
                          </div>
                          <h3
                            className="feed__user"
                            onClick={() =>
                              navigate("/feeds/poster-profile", {
                                state: {
                                  userId: item?.post?.userId,
                                  data: item?.post,
                                  type: activeFeedTab.type,
                                },
                              })
                            }
                          >
                            {activeFeedTab?.type === "ADVERTISER_POST" ? (
                              <>{item?.post?.advertiserInfo?.name}</>
                            ) : (
                              <>
                                {item?.post?.user?.profile?.firstName}{" "}
                                {item?.post?.user?.profile?.lastName}
                              </>
                            )}{" "}
                            <span className="feed__username">
                              @{item?.post?.user?.profile?.username}
                            </span>
                            <p className="feed__userfollowers">
                              {item?.post?.user?.totalFollowers}{" "}
                              {pluralizeString(
                                "Follower",
                                item?.post?.user?.totalFollowers
                              )}{" "}
                            </p>
                          </h3>
                          <LohButton
                            width="max-content"
                            backgroundColor={
                              item?.followingUser
                                ? "var(--bluePrimary)"
                                : "#fff"
                            }
                            color={
                              item?.followingUser
                                ? "#fff"
                                : "var(--bluePrimary)"
                            }
                            height="2rem"
                            padding="0 1rem"
                            loading={
                              isFollowingLoading === item?.post?.user?.id
                            }
                            onClick={() =>
                              handleFollowUser(
                                item?.followingUser,
                                item?.post?.user?.id
                              )
                            }
                          >
                            {item?.followingUser ? "Following" : "Follow"}
                          </LohButton>
                        </Flex>

                        <Flex justify="space-between">
                          <h3 className="feed__title">{item?.post?.title}</h3>

                          <span className="feedpost__time">
                            {moment(item?.post?.createdAt).fromNow()}
                          </span>
                        </Flex>
                        <p className="feed__description">
                          {truncateString(item?.post?.body, 150)}{" "}
                          <span
                            className="see__more"
                            onClick={() =>
                              navigate("/uploads/view", {
                                state: {
                                  activity: "View",
                                  id: item?.post?.id,
                                  type: "feed",
                                  data: item,
                                },
                              })
                            }
                          >
                            See more
                          </span>
                        </p>

                        <Flex
                          className="feed__buttons__wrap"
                          justify="space-between"
                          align="center"
                        >
                          <Flex
                            className="feed__buttons__wrap__left"
                            align="center"
                            gap=".5rem"
                          >
                            <span className="feed__buttons">
                              <IoHeartOutline />{" "}
                              {item?.post?.metadata?.totalLikes}{" "}
                              {pluralizeString(
                                "Like",
                                item?.post?.metadata?.totalLikes
                              )}
                            </span>
                            <span className="feed__buttons">
                              <BsChatLeft />{" "}
                              {item?.post?.metadata?.totalComments}{" "}
                              {pluralizeString(
                                "Comment",
                                item?.post?.metadata?.totalComments
                              )}
                            </span>

                            <span className="feed__buttons">
                              <BsEye /> {item?.post?.metadata?.totalViews}{" "}
                              {pluralizeString(
                                "View",
                                item?.post?.metadata?.totalViews
                              )}
                            </span>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
                {activeFeedFetching ? <LohliLoader /> : null}
                {activeFeedTab.type === "WATCHER_POST" ? (
                  <div ref={lastWatchersElementRef} />
                ) : activeFeedTab.type === "ADVERTISER_POST" ? (
                  <div ref={lastAdvertisersElementRef} />
                ) : null}
              </div>
            ) : (
              <Flex
                className="nodata__wrap"
                vertical
                align="center"
                justify="center"
              >
                <h3 className="noads__headtitle">
                  No {activeFeedTab.title} Available
                </h3>
                <p className="noads__subtitle">
                  Posts from{" "}
                  {activeFeedTab.type === "WATCHER_POST"
                    ? "watchers"
                    : "advertisers"}{" "}
                  will appear here
                </p>
              </Flex>
            )}
          </Flex>
        )}
      </FeedsPageWrap>
    </DashboardLayout>
  );
};

export default FeedsPage;
