import React, { useState } from "react";
import { Flex, notification } from "antd";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { LohButton } from "../../../../components/lib/Button";
import { FeedsPosterProfilePageWrap } from "./posterProfile.styles";
import { useLocation, useNavigate } from "react-router-dom";
import NavTabs from "../../../../components/lib/Tabs/navTabs";
import { IoHeartOutline } from "react-icons/io5";
import { validateVideo } from "../../../../utils/function-helpers/form-validators";
import { BsEmojiSmileUpsideDownFill, BsPersonCircle } from "react-icons/bs";
import {
  pluralizeString,
  truncateString,
} from "../../../../utils/function-helpers/string-functions";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiChat3Line } from "react-icons/ri";
import { getAllPosts } from "../../../../network/posts";
import { useQuery } from "react-query";
import { getAllAdverts } from "../../../../network/advert";
import ReactPlayer from "react-player";
import moment from "moment";
import LohTable from "../../../../components/lib/AdsTable/adsTable";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";
import LohliAvatar from "../../../../components/lib/Avatar/avatar";
import { advertiserFollowUser } from "../../../../network/auth";
import { getUser } from "../../../../network/users";

const FeedsPosterProfilePage = () => {
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { data: publishedPosts, isLoading: isPostLoading } = useQuery(
    ["published-posts", state?.data?.user?.id],
    () => getAllPosts("ACTIVE", state?.data?.user?.id)
  );

  const {
    data: publishedAds,
    isLoading,
    refetch: refetchPubished,
  } = useQuery(["ads", state?.data?.user?.id], () =>
    getAllAdverts("PUBLISHED", state?.data?.user?.id)
  );

  const {
    data: userProfile,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery(["user", state?.data?.user?.id], () =>
    getUser(state?.data?.user?.id)
  );

  const [activepostTab, setActivepostTab] = useState({
    title: "Ads",
  });
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);

  const handleFollowUser = async (
    status: boolean | undefined,
    userId: number
  ) => {
    setIsFollowingLoading(true);
    try {
      await advertiserFollowUser({ status: !status, userId: userId });
      setIsFollowingLoading(false);
      refetchUser();
    } catch (error: any) {
      setIsFollowingLoading(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };
  const postTabs = [
    {
      title: "Ads",
      data: [],
      total: publishedAds?.totalDocuments ?? 0,
    },

    {
      title: "Posts",
      data: [],
      total: publishedPosts?.totalDocuments ?? 0,
    },
  ];
  return (
    <DashboardLayout>
      <FeedsPosterProfilePageWrap vertical gap="2rem">
        <h3 className="goback__text" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack /> Go Back
        </h3>

        <Flex className="feedposter__profileheader">
          <div className="avatar__wrap">
            <LohliAvatar
              imageUrl={userProfile?.data?.profile?.avatar?.publicURL || ""}
            />
          </div>

          <Flex className="feedposter__details" vertical>
            <Flex className="feedposter__details__top">
              <h3 className="feedposter__user">
                {state?.type === "ADVERTISER_POST" ? (
                  <>{userProfile?.data?.advertiserInfo?.name}</>
                ) : (
                  <>
                    {userProfile?.data?.profile?.firstName}{" "}
                    {userProfile?.data?.profile?.lastName}
                  </>
                )}
                <p className="feedposter__username">
                  @{userProfile?.data?.profile?.username}
                </p>
              </h3>

              <LohButton
                width="max-content"
                backgroundColor={
                  userProfile?.data?.followingUser
                    ? "var(--bluePrimary)"
                    : "#fff"
                }
                color={
                  userProfile?.data?.followingUser
                    ? "#fff"
                    : "var(--bluePrimary)"
                }
                height="2rem"
                padding="0 1rem"
                loading={isFollowingLoading}
                onClick={() =>
                  handleFollowUser(
                    userProfile?.data?.followingUser,
                    userProfile?.data?.id
                  )
                }
              >
                {userProfile?.data?.followingUser ? "Following" : "Follow"}
              </LohButton>
            </Flex>

            <p className="feedposter__description">
              {userProfile?.data?.advertiserInfo?.description}
            </p>

            <Flex
              className="feedposter__buttons__wrap"
              justify="space-between"
              align="center"
            >
              <Flex
                className="feedposter__buttons__wrap__left"
                align="center"
                gap=".5rem"
              >
                <span className="feedposter__buttons">
                  {userProfile?.data?.totalFollowers}{" "}
                  {pluralizeString(
                    "Follower",
                    userProfile?.data?.totalFollowers
                  )}
                </span>
                <span className="feedposter__buttons">
                  {userProfile?.data?.totalFollowing} Following
                </span>
                {state?.type === "ADVERTISER_POST" && (
                  <span className="feedposter__buttons">
                    {publishedAds?.data?.length}{" "}
                    {pluralizeString("Ad", publishedAds?.data?.length)}
                  </span>
                )}

                <span className="feedposter__buttons">
                  {publishedPosts?.data?.length}{" "}
                  {pluralizeString("Post", publishedPosts?.data?.length)}
                </span>

                {state?.type === "WATCHER_POST" && (
                  <span className="feedposter__buttons">
                    {userProfile?.data?.totalTribes}{" "}
                    {pluralizeString("Tribe", userProfile?.data?.totalTribes)}
                  </span>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {state?.type === "ADVERTISER_POST" && (
          <Flex className="nav__tab__wrap">
            <NavTabs
              navTabData={postTabs}
              setActiveTab={setActivepostTab}
              activeTab={activepostTab}
              hasItemCount
              itemWidth="20%"
            />
          </Flex>
        )}

        <Flex className="feedposts__posterprofile__wrap">
          {activepostTab.title === "Ads" &&
            state?.type === "ADVERTISER_POST" && (
              <>
                {isLoading ? (
                  <LohliLoader />
                ) : (
                  <LohTable tableData={publishedAds?.data} type="analytics" />
                )}
              </>
            )}

          {(activepostTab.title === "Posts" ||
            state?.type === "WATCHER_POST") && (
            <>
              {" "}
              {isPostLoading ? (
                <Flex justify="center" className="post__loading">
                  <LohliLoader />
                </Flex>
              ) : (
                <>
                  {publishedPosts?.data?.length > 0 ? (
                    <Flex
                      className="feedposts__posterprofile__wrapsub"
                      vertical
                    >
                      {publishedPosts?.data?.map(
                        (item: Record<string, any>, idx: number) => (
                          <Flex
                            className="feedposts__posterprofile__main"
                            key={idx}
                            vertical
                          >
                            <Flex className="adpost__header" vertical>
                              <Flex className="adpost__header__top">
                                <div className="adpost__avatar__wrap">
                                  <LohliAvatar
                                    imageUrl={
                                      item?.post?.user?.profile?.avatar
                                        ?.publicURL || ""
                                    }
                                  />
                                </div>
                                <h3 className="adpost__user">
                                  {state?.type === "ADVERTISER_POST" ? (
                                    <>
                                      {userProfile?.data?.advertiserInfo?.name}
                                    </>
                                  ) : (
                                    <>
                                      {item?.post?.user?.profile?.firstName}{" "}
                                      {item?.post?.user?.profile?.lastName}
                                    </>
                                  )}
                                  <p className="adpost__username">
                                    @{item?.post?.user?.profile?.username}
                                  </p>
                                </h3>
                                <span className="adpost__time">
                                  {moment(item?.post?.createdAt).fromNow()}
                                </span>
                              </Flex>
                              <p
                                className="adpost__description"
                                onClick={() =>
                                  navigate("/uploads/view", {
                                    state: {
                                      activity: "View",
                                      id: item?.post?.id,
                                      type: "feed",
                                      data: item,
                                    },
                                  })
                                }
                              >
                                {truncateString(item?.post?.body, 100)}
                              </p>
                            </Flex>

                            <div className="adpost__video">
                              {!!validateVideo(
                                item?.post?.media[0]?.publicURL || ""
                              ) ? (
                                <ReactPlayer
                                  url={item?.post?.media[0]?.publicURL}
                                  width={"100%"}
                                  height={"100%"}
                                />
                              ) : (
                                <img
                                  src={item?.post?.media[0]?.publicURL}
                                  width={"100%"}
                                  height={"100%"}
                                />
                              )}
                            </div>

                            <Flex
                              className="likes__comments__wrap"
                              gap="1.2rem"
                            >
                              <span className="likes__comments">
                                <IoHeartOutline />{" "}
                                {item?.post?.metadata?.totalLikes}
                              </span>
                              <span className="likes__comments">
                                <RiChat3Line />{" "}
                                {item?.post?.metadata?.totalComments}
                              </span>
                            </Flex>
                          </Flex>
                        )
                      )}
                    </Flex>
                  ) : (
                    <Flex
                      className="nodata__wrap"
                      vertical
                      align="center"
                      justify="center"
                    >
                      <h3 className="noads__headtitle">
                        No{" "}
                        {state?.type === "WATCHER_POST"
                          ? "Watcher"
                          : "Advertiser"}{" "}
                        Posts Available
                      </h3>
                      <p className="noads__subtitle">
                        Posts from{" "}
                        {state?.type === "WATCHER_POST"
                          ? "watcher"
                          : "advertiser"}{" "}
                        will appear here
                      </p>
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      </FeedsPosterProfilePageWrap>
    </DashboardLayout>
  );
};

export default FeedsPosterProfilePage;
