import React, { useEffect, useState } from "react";
import { Dropdown, Flex, Form, notification } from "antd";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import { UploadViewWrap } from "./uploadView.styles";
import { LohButton } from "../../../components/lib/Button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BsEmojiSmileUpsideDownFill,
  BsEye,
  BsHeartFill,
  BsPersonCircle,
} from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2, FiPause, FiPlay, FiSend } from "react-icons/fi";
import PostAvatar from "../../../assets/images/dashboard/feed-avatar.png";
import { IoFlagOutline, IoHeartOutline } from "react-icons/io5";
import { FiBarChart2 } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import ProgressBar from "../../../components/lib/ProgressBar/progressBar";
import { LohBarChart, LohPieChart } from "../../../components/lib/Chart/chart";
import {
  barChartData,
  pieChartData,
  topViewersLocations,
} from "../../../utils/data-helpers/ad-metrics";
import { CgSortAz } from "react-icons/cg";
import { TextField } from "../../../components/lib/Form";
import {
  validateComment,
  validateImageFile,
} from "../../../utils/function-helpers/form-validators";
import ReportModal from "./Modals/reportModal";
import {
  PiCaretUpBold as CaretUp,
  PiCaretDownBold as CaretDown,
} from "react-icons/pi";
import DeleteModal from "./Modals/deleteModal";
import {
  advertiserCommentOnPost,
  advertiserDeleteCommentOnPost,
  advertiserDeleteCommentReplyOnPost,
  advertiserGetCommentRepliesOnPost,
  advertiserGetCommentsOnPost,
  advertiserLikePost,
  advertiserLikePostComment,
  advertiserReplyCommentOnPost,
  getSinglePost,
} from "../../../network/posts";
import {
  advertiserCommentOnAd,
  advertiserDeleteCommentOnAd,
  advertiserDeleteCommentReplyOnAd,
  advertiserGetCommentRepliesOnAd,
  advertiserGetCommentsOnAd,
  advertiserLikeAd,
  advertiserLikeAdComment,
  advertiserReplyCommentOnAd,
  getSingleAdvert,
} from "../../../network/advert";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import { useQuery } from "react-query";
import ReactPlayer from "react-player";
import { useAdvertiserContext } from "../../../context/advertiserContext/main";
import { orderBy } from "lodash";
import moment from "moment";
import { AiOutlineLoading } from "react-icons/ai";
import QuestionsModal from "./Modals/questionsModal";
import LohliAvatar from "../../../components/lib/Avatar/avatar";
import {
  formatNumber,
  pluralizeString,
} from "../../../utils/function-helpers/string-functions";

const UploadViewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [currentActiveButton, setCurrentActiveButton] = useState("Comments");
  const [replyId, setReplyId] = useState(-1);
  const [showReplies, setShowReply] = useState(-1);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createCommentForm] = Form.useForm();
  const [replyCommentForm] = Form.useForm();
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);
  const [isCommentReplySubmitting, setIsCommentReplySubmitting] =
    useState(false);
  const [sortByDesc, setSortByDesc] = useState(true);
  const [commentId, setCommentId] = useState(0);
  const [getReplyLoading, setGetReplyLoading] = useState(false);
  const [replies, setReplies] = useState([]);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [commentDeleting, setCommentDeleting] = useState({
    type: "",
    idx: -1,
  });

  const {
    data: upload,
    isLoading: isLoading,
    refetch: refetchUpload,
  } = useQuery(
    ["uplload", state?.id],
    () =>
      state?.type === "ad"
        ? getSingleAdvert(state?.id)
        : getSinglePost(state?.id),
    {
      enabled: !!state?.id,
    }
  );

  const {
    data: comments,
    isLoading: isCommentLoading,
    refetch: refetchComments,
  } = useQuery(
    ["comments", state?.id],
    () =>
      state?.type === "ad"
        ? advertiserGetCommentsOnAd(state?.id)
        : advertiserGetCommentsOnPost(state?.id),
    {
      enabled: !!state?.id,
    }
  );

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  const sortCommentMenuItems = [
    {
      key: "2",
      label: (
        <span className="dropdown__items" onClick={() => setSortByDesc(true)}>
          Newest to Oldest
        </span>
      ),
    },

    {
      key: "4",
      label: (
        <span className="dropdown__items" onClick={() => setSortByDesc(false)}>
          Oldest to Newest
        </span>
      ),
    },
  ];

  const sortMenuProps = {
    items: sortCommentMenuItems,
  };
  const handleCommentFormSubmit = async (values: Record<string, any>) => {
    setIsCommentSubmitting(true);
    const payload = {
      ...values,
    };

    try {
      if (state?.type === "ad") {
        await advertiserCommentOnAd(payload, upload?.data?.advert?.id);
      } else {
        await advertiserCommentOnPost(payload, upload?.data?.id);
      }
      await refetchComments();
      await refetchUpload();

      setIsCommentSubmitting(false);
      createCommentForm.setFieldsValue({
        comment: "",
      });
    } catch (error: any) {
      setIsCommentSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleLikeUpload = async () => {
    try {
      if (state?.type === "ad") {
        await advertiserLikeAd(
          {
            status: !upload?.data?.engagement?.liked,
          },
          upload?.data?.advert?.id
        );
      } else {
        await await advertiserLikePost(
          {
            status: !upload?.data?.engagement?.liked,
          },
          upload?.data?.id
        );
      }
      refetchUpload();
    } catch (error: any) {
      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleLikeComment = async (comentId: number, status: boolean) => {
    try {
      if (state?.type === "ad") {
        await advertiserLikeAdComment(
          {
            status: !status,
          },
          comentId
        );

        const commentReplies = await advertiserGetCommentRepliesOnAd(
          upload?.data?.advert?.id,
          commentId
        );
        setReplies(commentReplies?.data);
      } else {
        await await advertiserLikePostComment(
          {
            status: !status,
          },
          comentId
        );
        const commentReplies = await advertiserGetCommentRepliesOnPost(
          upload?.data?.id,
          commentId
        );
        setReplies(commentReplies?.data);
        setShowReply(showReplies);
      }

      await refetchComments();
    } catch (error: any) {
      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleReplyCommentFormSubmit = async (values: Record<string, any>) => {
    setIsCommentReplySubmitting(true);
    const payload = {
      ...values,
    };

    try {
      if (state?.type === "ad") {
        await advertiserReplyCommentOnAd(
          payload,
          upload?.data?.advert?.id,
          commentId
        );
        await refetchComments();
        const commentReplies = await advertiserGetCommentRepliesOnAd(
          upload?.data?.advert?.id,
          commentId
        );
        setReplies(commentReplies?.data);
      } else {
        await advertiserReplyCommentOnPost(
          payload,
          upload?.data?.id,
          commentId
        );
        await refetchComments();

        const commentReplies = await advertiserGetCommentRepliesOnPost(
          upload?.data?.id,
          commentId
        );

        setReplies(commentReplies?.data);
      }

      setIsCommentReplySubmitting(false);
      setShowReply(commentId);
      replyCommentForm.setFieldsValue({
        reply: "",
      });
    } catch (error: any) {
      setIsCommentReplySubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleGetCommentReplies = async (commentId: number) => {
    setGetReplyLoading(true);

    try {
      if (state?.type === "ad") {
        const commentReplies = await advertiserGetCommentRepliesOnAd(
          upload?.data?.advert?.id,
          commentId
        );
        setReplies(commentReplies?.data);
      } else {
        const commentReplies = await advertiserGetCommentRepliesOnPost(
          upload?.data?.id,
          commentId
        );

        setReplies(commentReplies?.data);
      }
      setGetReplyLoading(false);
    } catch (error: any) {
      setGetReplyLoading(false);
      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleDeleteComment = async (
    commentId: number,
    type: string,
    replyId: number
  ) => {
    setCommentDeleting({
      type: type,
      idx: commentId,
    });

    try {
      if (state?.type === "ad") {
        if (type === "comment") {
          await advertiserDeleteCommentOnAd(
            upload?.data?.advert?.id,
            commentId
          );
        } else {
          await advertiserDeleteCommentReplyOnAd(
            upload?.data?.advert?.id,
            commentId,
            replyId
          );
          const commentReplies = await advertiserGetCommentRepliesOnAd(
            upload?.data?.advert?.id,
            commentId
          );
          setReplies(commentReplies?.data);
        }
      } else {
        if (type === "comment") {
          await advertiserDeleteCommentOnPost(upload?.data?.id, commentId);
        } else {
          await advertiserDeleteCommentReplyOnPost(
            upload?.data?.id,
            commentId,
            replyId
          );
          const commentReplies = await advertiserGetCommentRepliesOnPost(
            upload?.data?.id,
            commentId
          );
          setReplies(commentReplies?.data);
        }
      }
      await refetchComments();

      setCommentDeleting({
        type: "",
        idx: -1,
      });
    } catch (error: any) {
      setCommentDeleting({
        type: "",
        idx: -1,
      });
      if (error?.response) {
        notification.open({
          message: `Error`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const orderedList = orderBy(comments?.data, "id", [
    sortByDesc ? "desc" : "asc",
  ]);
  return (
    <DashboardLayout>
      <UploadViewWrap vertical>
        <h3 className="goback__text" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack /> Go Back
        </h3>

        {isLoading ? (
          <LohliLoader />
        ) : (
          <>
            <div className="demo__image">
              {state?.type === "post" ? (
                !!validateImageFile(upload?.data?.media[0]?.publicURL || "") ? (
                  <img
                    src={upload?.data?.media[0]?.publicURL}
                    width={"100%"}
                    height={"100%"}
                  />
                ) : (
                  <ReactPlayer
                    url={
                      upload?.data?.media[0]?.publicURL ||
                      upload?.data?.advert?.video?.publicURL
                    }
                    width={"100%"}
                    height={"100%"}
                    controls
                  />
                )
              ) : (
                <ReactPlayer
                  url={upload?.data?.advert?.video?.publicURL}
                  width={"100%"}
                  height={"100%"}
                  controls
                />
              )}
            </div>
            <Flex className="upload__body" vertical>
              <Flex justify="space-between">
                <h3 className="upload__title">
                  {upload?.data?.title || upload?.data?.advert?.title}
                </h3>

                {state?.activity === "Edit" && (
                  <Flex gap=".6rem">
                    <RiDeleteBin6Line
                      color="var(--errorPrimary)"
                      size={18}
                      onClick={() => setShowDeleteModal(true)}
                    />
                    <FiEdit2
                      color="var(--darkGrayPrimary)"
                      size={18}
                      onClick={() => {
                        navigate(
                          state?.type === "ad"
                            ? "/ads/upload"
                            : "/posts/upload",
                          {
                            state: { activity: "Edit", id: state?.id },
                          }
                        );
                      }}
                    />
                    {/* <FiPause color="var(--darkGrayPrimary)" size={18} /> */}
                  </Flex>
                )}
              </Flex>
              <p className="upload__description">
                {upload?.data?.body || upload?.data?.advert?.description}
              </p>

              <Flex
                className="uploadbuttons__wrap"
                justify="space-between"
                align="center"
              >
                <Flex className="uploadbuttons__wrap__left" align="center">
                  <div className="avatar__wrap">
                    <LohliAvatar
                      imageUrl={
                        upload?.data?.user?.profile?.avatar?.publicURL ||
                        upload?.data?.advert?.user?.profile?.avatar
                          ?.publicURL ||
                        ""
                      }
                    />
                  </div>
                  <h3 className="upload__user">
                    {upload?.data?.advert?.user?.advertiserInfo
                      ? upload?.data?.advert?.user?.advertiserInfo?.name
                      : upload?.data?.user?.profile?.firstName ||
                        upload?.data?.advert?.user?.profile?.firstName}{" "}
                    {(!upload?.data?.advert?.user?.advertiserInfo &&
                      upload?.data?.user?.profile?.lastName) ||
                      upload?.data?.advert?.user?.profile?.lastName}
                    <p className="upload__username">
                      @
                      {upload?.data?.user?.profile?.username ||
                        upload?.data?.advert?.user?.profile?.username}
                    </p>
                  </h3>
                  <span className="uploadbuttons" onClick={handleLikeUpload}>
                    {upload?.data?.engagement?.liked ? (
                      <BsHeartFill color="var(--errorPrimary)" />
                    ) : (
                      <IoHeartOutline />
                    )}{" "}
                    {upload?.data?.metadata?.totalLikes ||
                      upload?.data?.advert?.metadata?.totalLikes ||
                      0}{" "}
                    {pluralizeString(
                      "Like",
                      upload?.data?.metadata?.totalLikes ||
                        upload?.data?.advert?.metadata?.totalLikes
                    )}
                  </span>

                  <span
                    className="uploadbuttons clickable__uploadbutton"
                    onClick={() => setCurrentActiveButton("Comments")}
                    id={
                      currentActiveButton === "Comments" ? "active__buttom" : ""
                    }
                  >
                    <BsChatLeft />{" "}
                    {upload?.data?.metadata?.totalComments ||
                      upload?.data?.advert?.metadata?.totalComments ||
                      0}{" "}
                    {pluralizeString(
                      "Comment",
                      upload?.data?.metadata?.totalComments ||
                        upload?.data?.advert?.metadata?.totalComments
                    )}
                  </span>

                  {state?.type !== "feed" && (
                    <>
                      <span className="uploadbuttons">
                        <BsEye />{" "}
                        {upload?.data?.metadata?.totalViews ||
                          upload?.data?.advert?.metadata?.totalViews ||
                          0}{" "}
                        {(upload?.data?.metadata?.totalViews ||
                          upload?.data?.advert?.metadata?.totalViews) > 1
                          ? "Views"
                          : "View"}
                      </span>

                      <span
                        className="uploadbuttons clickable__uploadbutton"
                        onClick={() => setCurrentActiveButton("Analytics")}
                        id={
                          currentActiveButton === "Analytics"
                            ? "active__buttom"
                            : ""
                        }
                      >
                        <FiBarChart2 /> Analytics
                      </span>
                    </>
                  )}
                </Flex>

                {state?.type === "ad" && state.activity === "Edit" && (
                  <span
                    className="view__questions"
                    onClick={() => setShowQuestionsModal(true)}
                  >
                    View questions
                  </span>
                )}
              </Flex>

              <Flex className="upload__buttons_content">
                {currentActiveButton === "Analytics" && (
                  <Flex className="analytics__body" vertical>
                    <Flex
                      className="analytics_header"
                      align="center"
                      justify="space-between"
                    >
                      <h3 className="upload__buttons_content__title">
                        Analytics
                      </h3>
                      <Flex
                        align="center"
                        gap={24}
                        className="analytics__shekels"
                      >
                        <Flex align="center" gap={8}>
                          <p className="analytics__shekels__text-one">
                            Budget Amount in Shekels -
                          </p>
                          <p className="analytics__shekels__text-two">
                            {upload?.data?.advert?.metadata?.totalBudget || 0}{" "}
                            (₦
                            {(
                              (upload?.data?.advert?.metadata?.totalBudget ||
                                0) * 200
                            )?.toLocaleString()}
                            )
                          </p>
                        </Flex>
                        <div className="analytics__shekels__divider" />
                        <Flex align="center" gap={8}>
                          <p className="analytics__shekels__text-one">
                            Shekel Given -{" "}
                          </p>
                          <p className="analytics__shekels__text-two">
                            {upload?.data?.advert?.metadata?.totalSpent || 0} (₦
                            {(
                              (upload?.data?.advert?.metadata?.totalSpent ||
                                0) * 200
                            )?.toLocaleString()}
                            )
                          </p>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      justify="space-between"
                      className="analytics__topcards__wrap"
                    >
                      <Flex className="analytics__topcards__main" vertical>
                        <h3 className="activity__titletext">Likes</h3>
                        <h2 className="activity__number">
                          {formatNumber(
                            upload?.data?.advert?.metadata?.totalLikes || 0
                          )}
                        </h2>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Followers
                          </span>{" "}
                          <ProgressBar width="70%" backgroundColor="#E4E4E4" />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.likesStats
                                ?.followers || 0
                            )}
                          </span>{" "}
                        </Flex>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Non-Followers
                          </span>{" "}
                          <ProgressBar
                            color="#E5C487"
                            backgroundColor="#E4E4E4"
                            width="30%"
                          />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.likesStats
                                ?.nonFollowers || 0
                            )}
                          </span>{" "}
                        </Flex>
                      </Flex>

                      <Flex className="analytics__topcards__main" vertical>
                        <h3 className="activity__titletext">Views</h3>
                        <h2 className="activity__number">
                          {" "}
                          {formatNumber(
                            upload?.data?.advert?.metadata?.totalViews || 0
                          )}
                        </h2>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Followers
                          </span>{" "}
                          <ProgressBar width="70%" backgroundColor="#E4E4E4" />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.likesStats
                                ?.followers || 0
                            )}
                          </span>{" "}
                        </Flex>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Non-Followers
                          </span>{" "}
                          <ProgressBar
                            color="#E5C487"
                            backgroundColor="#E4E4E4"
                            width="30%"
                          />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.viewsStats
                                ?.nonFollowers || 0
                            )}
                          </span>{" "}
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      justify="stretch"
                      className="analytics__topcards__wrap"
                    >
                      <Flex
                        className="analytics__topcards__main analytics__topcards__main--earners"
                        vertical
                      >
                        <h3 className="activity__titletext">Earners</h3>
                        <h2 className="activity__number">
                          {" "}
                          {formatNumber(
                            upload?.data?.advert?.metadata?.totalWatch || 0
                          )}
                        </h2>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Followers
                          </span>{" "}
                          <ProgressBar width="70%" backgroundColor="#E4E4E4" />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.earnerStats
                                ?.followers || 0
                            )}
                          </span>{" "}
                        </Flex>
                        <Flex className="activity__progress__wrap">
                          <span className="activity__progessvalue left__preogresstext">
                            Non-Followers
                          </span>{" "}
                          <ProgressBar
                            color="#E5C487"
                            backgroundColor="#E4E4E4"
                            width="30%"
                          />
                          <span className="activity__progessvalue right__preogresstext">
                            {formatNumber(
                              upload?.data?.advert?.metadata?.earnerStats
                                ?.nonFollowers || 0
                            )}
                          </span>{" "}
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex className="charts__wrap" justify="space-between">
                      <Flex className="piechart__wrap chartwrap__main" vertical>
                        <h3 className="sections__header"> Gender</h3>
                        <LohPieChart
                          pieChartData={{
                            label: ["Male", "Female"],
                            datas: [
                              {
                                data: [
                                  [
                                    upload?.data?.advert?.earnerGenderStats
                                      ?.male ?? 0,
                                  ],
                                  upload?.data?.advert?.earnerGenderStats
                                    ?.female ?? 0,
                                ],
                                color: ["#E5C487", "#0062D6"],
                              },
                            ],
                          }}
                          legendPosition="right"
                        />
                      </Flex>

                      <Flex className="barchart__wrap chartwrap__main" vertical>
                        <h3 className="sections__header"> Age</h3>
                        <LohBarChart
                          barChartData={{
                            label: ["<17", "18-27", "28-59", "60+"],
                            datas: [
                              {
                                data: upload?.data?.advert?.earnerAgeStats
                                  ? Object.values(
                                      upload?.data?.advert?.earnerAgeStats
                                    )
                                  : null,
                                color: "#0062D6",
                                hoverLabel: "Percentage",
                              },
                            ],
                          }}
                        />
                      </Flex>
                    </Flex>

                    <Flex className="viewerslocations__wrap" vertical>
                      <h3 className="sections__header">Top Viewers Location</h3>

                      <Flex
                        className="viewerslocations__wrapsub"
                        justify="space-between"
                      >
                        {topViewersLocations.map((item, idx) => (
                          <Flex
                            className="viewerslocations__main"
                            vertical
                            key={idx}
                          >
                            <Flex justify="space-between">
                              <p className="location__view">{item.location}</p>
                              <p className="location__view">{item.views}</p>
                            </Flex>
                            <ProgressBar
                              width={item.percentage + "%"}
                              backgroundColor="#e4e4e4"
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                )}

                {currentActiveButton === "Comments" && (
                  <Flex className="comments__body" vertical>
                    <Flex
                      className="comments__header"
                      align="center"
                      gap="1.7rem"
                    >
                      <h3 className="upload__buttons_content__title">
                        {upload?.data?.metadata?.totalComments ||
                          upload?.data?.advert?.metadata?.totalComments ||
                          0}{" "}
                        {pluralizeString(
                          "Comment",
                          upload?.data?.metadata?.totalComments ||
                            upload?.data?.advert?.metadata?.totalComments
                        )}
                      </h3>
                      <Dropdown
                        menu={sortMenuProps}
                        placement="bottom"
                        trigger={["hover"]}
                      >
                        <a className="uploadbuttons">
                          Sort By <CgSortAz size={18} />
                        </a>
                      </Dropdown>
                    </Flex>
                    <Form
                      form={createCommentForm}
                      onFinish={handleCommentFormSubmit}
                    >
                      <Flex className="comment__input">
                        <TextField
                          validator={validateComment}
                          placeholder="Leave a comment"
                          name="comment"
                          icon={
                            <div className="avatar__wrap__input">
                              <LohliAvatar
                                imageUrl={
                                  user?.profile?.avatar?.publicURL || ""
                                }
                              />
                            </div>
                          }
                        />

                        <LohButton
                          svgOpacity="1"
                          width={isCommentSubmitting ? "7rem" : "5rem"}
                          height="2.2rem"
                          htmlType="submit"
                          loading={isCommentSubmitting}
                        >
                          Send <FiSend color="#fff" />
                        </LohButton>
                      </Flex>
                    </Form>

                    <Flex vertical className="comments__main">
                      {comments?.data?.length > 0 &&
                        orderedList?.map(
                          (item: Record<string, any>, idx: number) => (
                            <Flex className="comment__item" key={idx}>
                              <div className="commenter_avatar">
                                <LohliAvatar
                                  imageUrl={
                                    item?.user?.profile?.avatar?.publicURL || ""
                                  }
                                />
                              </div>
                              <Flex vertical className="comment__item__right">
                                <h3 className="commenter__name">
                                  {item?.user.advertiserInfo
                                    ? item.user.advertiserInfo.name
                                    : item.user.profile.firstName}{" "}
                                  {!item?.user?.advertiserInfo &&
                                    item.user.profile.lastName}{" "}
                                  <span className="commenter__username">
                                    @{item.user.profile.username}
                                  </span>
                                </h3>
                                <p className="commenter__text">
                                  {item.comment}
                                </p>
                                <Flex
                                  gap="1rem"
                                  className="comment__footer__wrap"
                                >
                                  <span className="comment__date comment__footer">
                                    {moment(item.createdAt).fromNow()}
                                  </span>
                                  <span
                                    className="comment__likes comment__footer"
                                    onClick={() =>
                                      handleLikeComment(
                                        item?.id,
                                        item?.engagement?.liked
                                      )
                                    }
                                  >
                                    {item?.engagement?.liked ? (
                                      <BsHeartFill color="var(--errorPrimary)" />
                                    ) : (
                                      <IoHeartOutline />
                                    )}{" "}
                                    {item?.metadata?.totalLikes || 0}
                                  </span>
                                  <span
                                    className="comment__replyclicktext comment__footer"
                                    onClick={() =>
                                      setReplyId(replyId === idx ? -1 : idx)
                                    }
                                  >
                                    Reply
                                  </span>
                                  {/* <span
                                    className="report__comment comment__footer"
                                    onClick={() => setShowReportModal(true)}
                                  >
                                    Report <IoFlagOutline />
                                  </span> */}
                                  {user.id === item?.user?.id && (
                                    <span
                                      className="report__comment comment__footer"
                                      onClick={() =>
                                        handleDeleteComment(
                                          item.id,
                                          "comment",
                                          0
                                        )
                                      }
                                    >
                                      Delete{" "}
                                      {commentDeleting?.type === "comment" &&
                                      commentDeleting?.idx === item.id ? (
                                        <AiOutlineLoading />
                                      ) : (
                                        <RiDeleteBin6Line color="var(--errorPrimary)" />
                                      )}
                                    </span>
                                  )}
                                </Flex>

                                {replyId === idx && (
                                  <Form
                                    form={replyCommentForm}
                                    onFinish={handleReplyCommentFormSubmit}
                                  >
                                    <Flex className="comment__input comment__reply__input">
                                      <TextField
                                        validator={validateComment}
                                        placeholder="Reply this comment"
                                        icon={
                                          <div className="avatar__wrap__input">
                                            <LohliAvatar
                                              imageUrl={
                                                user?.profile?.avatar
                                                  ?.publicURL || ""
                                              }
                                            />
                                          </div>
                                        }
                                        margin="0"
                                        name="reply"
                                      />

                                      <LohButton
                                        svgOpacity="1"
                                        width={
                                          isCommentReplySubmitting
                                            ? "4rem"
                                            : "3rem"
                                        }
                                        height="2rem"
                                        loading={isCommentReplySubmitting}
                                        htmlType="submit"
                                        onClick={() => setCommentId(item?.id)}
                                      >
                                        <FiSend color="#fff" />
                                      </LohButton>
                                    </Flex>
                                  </Form>
                                )}
                                {item.metadata.totalReplies > 0 && (
                                  <LohButton
                                    onClick={() => {
                                      if (showReplies === item?.id) {
                                        setShowReply(-1);
                                        return;
                                      } else {
                                        setShowReply(item?.id);
                                        handleGetCommentReplies(item?.id);
                                      }
                                    }}
                                    width="6rem"
                                    backgroundColor="#fff"
                                    color="var(--bluePrimary)"
                                    height="2rem"
                                    padding="0"
                                    borderColor="transparent"
                                    loading={
                                      getReplyLoading && showReplies === item.id
                                    }
                                  >
                                    {item.metadata.totalReplies}{" "}
                                    {pluralizeString(
                                      "Reply",
                                      item.metadata.totalReplies
                                    )}
                                    {showReplies === item.id ? (
                                      <CaretUp color="var(--bluePrimary)" />
                                    ) : (
                                      <CaretDown color="var(--bluePrimary)" />
                                    )}
                                  </LohButton>
                                )}

                                {showReplies === item.id &&
                                  !getReplyLoading && (
                                    <Flex
                                      vertical
                                      className="comment__replieswrap"
                                    >
                                      {replies?.map(
                                        (
                                          reply: Record<string, any>,
                                          replyIndex: number
                                        ) => (
                                          <Flex
                                            className="commentreplies__main"
                                            vertical
                                            key={replyIndex}
                                          >
                                            <Flex className="commentreplies__header">
                                              <div className="commentreplies_avatar commenter_avatar">
                                                <LohliAvatar
                                                  imageUrl={
                                                    reply?.user?.profile?.avatar
                                                      ?.publicURL || ""
                                                  }
                                                />
                                              </div>
                                              <h3 className="commenter__name">
                                                {reply?.user.advertiserInfo
                                                  ? reply.user.advertiserInfo
                                                      .name
                                                  : reply.user.profile
                                                      .firstName}{" "}
                                                {!reply?.user?.advertiserInfo &&
                                                  reply.user.profile
                                                    .lastName}{" "}
                                                <span className="commenter__username">
                                                  @{reply.user.profile.username}
                                                </span>
                                              </h3>
                                            </Flex>
                                            <p className="commenter__text">
                                              {reply.comment}
                                            </p>

                                            <Flex
                                              gap="1rem"
                                              className="comment__footer__wrap"
                                            >
                                              <span className="comment__date comment__footer">
                                                {moment(
                                                  reply.createdAt
                                                ).fromNow()}
                                              </span>
                                              <span
                                                className="comment__likes comment__footer"
                                                onClick={() =>
                                                  handleLikeComment(
                                                    reply?.id,
                                                    reply?.engagement?.liked
                                                  )
                                                }
                                              >
                                                {reply?.engagement?.liked ? (
                                                  <BsHeartFill color="var(--errorPrimary)" />
                                                ) : (
                                                  <IoHeartOutline />
                                                )}{" "}
                                                {reply?.metadata?.totalLikes ||
                                                  0}
                                              </span>

                                              {/* <span
                                                className="report__comment comment__footer"
                                                onClick={() =>
                                                  setShowReportModal(true)
                                                }
                                              >
                                                Report <IoFlagOutline />
                                              </span> */}
                                              {user.id === reply?.user?.id && (
                                                <span
                                                  className="report__comment comment__footer"
                                                  onClick={() =>
                                                    handleDeleteComment(
                                                      item.id,
                                                      "reply",
                                                      reply.id
                                                    )
                                                  }
                                                >
                                                  Delete{" "}
                                                  {commentDeleting?.type ===
                                                    "reply" &&
                                                  commentDeleting?.idx ===
                                                    reply.id ? (
                                                    <AiOutlineLoading />
                                                  ) : (
                                                    <RiDeleteBin6Line color="var(--errorPrimary)" />
                                                  )}
                                                </span>
                                              )}
                                            </Flex>
                                          </Flex>
                                        )
                                      )}
                                    </Flex>
                                  )}
                              </Flex>
                            </Flex>
                          )
                        )}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </UploadViewWrap>

      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
      />

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        state={state}
      />
      <QuestionsModal
        showQuestionsModal={showQuestionsModal}
        setShowQuestionsModal={setShowQuestionsModal}
        questions={upload?.data?.advert?.questions}
      />
    </DashboardLayout>
  );
};

export default UploadViewPage;
