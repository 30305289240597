export interface ISvgProps {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  pathFill?: string;
  transform?: string;
  circleFill?: string;
}
export const WalletIcon: React.FC<ISvgProps> = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g id="Frame 636">
      <rect
        x="0.5"
        width="20"
        height="20"
        rx="10"
        fill="#0062D6"
        fill-opacity="0.15"
      />
      <path
        id="Vector"
        d="M6.77958 12.2941C6.33317 11.8477 6.62375 11.0947 7.23379 11.0452L7.29796 11.0424L14.5208 11.0424C14.6974 11.0425 14.8671 11.1105 14.9949 11.2324C15.1227 11.3542 15.1987 11.5205 15.2072 11.6969C15.2157 11.8733 15.156 12.0461 15.0405 12.1797C14.925 12.3132 14.7626 12.3972 14.5868 12.4142L14.5208 12.4174L8.84712 12.4174L9.50712 13.0774C9.63214 13.202 9.70447 13.3699 9.70913 13.5463C9.71379 13.7227 9.65042 13.8942 9.53215 14.0252C9.41388 14.1562 9.24977 14.2367 9.07379 14.25C8.89781 14.2633 8.72344 14.2085 8.58679 14.0968L8.53454 14.0496L6.77958 12.2937L6.77958 12.2941ZM6.5 8.52161C6.50001 8.3507 6.56367 8.18593 6.67858 8.05941C6.79348 7.9329 6.95138 7.85372 7.1215 7.83732L7.1875 7.83411L12.8612 7.83411L12.2012 7.17411C12.0762 7.04954 12.0039 6.88166 11.9992 6.70524C11.9945 6.52882 12.0579 6.35736 12.1762 6.22637C12.2945 6.09538 12.4586 6.01489 12.6345 6.00156C12.8105 5.98823 12.9849 6.04309 13.1215 6.15477L13.1738 6.20198L14.9287 7.9574C15.3752 8.40381 15.0846 9.15686 14.4745 9.20636L14.4104 9.20911L7.1875 9.20911C7.00516 9.20911 6.8303 9.13667 6.70136 9.00774C6.57243 8.87881 6.5 8.70394 6.5 8.52161Z"
        fill="#0062D6"
      />
    </g>
  </svg>
);

export const TransferIcon: React.FC<ISvgProps> = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g id="Frame 636">
      <rect
        x="0.5"
        width="20"
        height="20"
        rx="10"
        fill="#0062D6"
        fill-opacity="0.15"
      />
      <path
        id="Vector"
        d="M6.77958 12.2941C6.33317 11.8477 6.62375 11.0947 7.23379 11.0452L7.29796 11.0424L14.5208 11.0424C14.6974 11.0425 14.8671 11.1105 14.9949 11.2324C15.1227 11.3542 15.1987 11.5205 15.2072 11.6969C15.2157 11.8733 15.156 12.0461 15.0405 12.1797C14.925 12.3132 14.7626 12.3972 14.5868 12.4142L14.5208 12.4174L8.84712 12.4174L9.50712 13.0774C9.63214 13.202 9.70447 13.3699 9.70913 13.5463C9.71379 13.7227 9.65042 13.8942 9.53215 14.0252C9.41388 14.1562 9.24977 14.2367 9.07379 14.25C8.89781 14.2633 8.72344 14.2085 8.58679 14.0968L8.53454 14.0496L6.77958 12.2937L6.77958 12.2941ZM6.5 8.52161C6.50001 8.3507 6.56367 8.18593 6.67858 8.05941C6.79348 7.9329 6.95138 7.85372 7.1215 7.83732L7.1875 7.83411L12.8612 7.83411L12.2012 7.17411C12.0762 7.04954 12.0039 6.88166 11.9992 6.70524C11.9945 6.52882 12.0579 6.35736 12.1762 6.22637C12.2945 6.09538 12.4586 6.01489 12.6345 6.00156C12.8105 5.98823 12.9849 6.04309 13.1215 6.15477L13.1738 6.20198L14.9287 7.9574C15.3752 8.40381 15.0846 9.15686 14.4745 9.20636L14.4104 9.20911L7.1875 9.20911C7.00516 9.20911 6.8303 9.13667 6.70136 9.00774C6.57243 8.87881 6.5 8.70394 6.5 8.52161Z"
        fill="#0062D6"
      />
    </g>
  </svg>
);

export const LohliPlayIcon: React.FC<ISvgProps> = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g id="material-symbols:play-arrow-rounded">
      <path
        id="Vector"
        d="M9.525 18.912C9.19167 19.1287 8.854 19.141 8.512 18.949C8.17067 18.7577 8 18.462 8 18.062V7.71204C8 7.31204 8.17067 7.01604 8.512 6.82404C8.854 6.63271 9.19167 6.64538 9.525 6.86204L17.675 12.037C17.975 12.237 18.125 12.5204 18.125 12.887C18.125 13.2537 17.975 13.537 17.675 13.737L9.525 18.912Z"
        fill="white"
      />
    </g>
  </svg>
);

export const ProfileCameraIcon: React.FC<ISvgProps> = ({ width }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.2" y="1.2" width="33.6" height="33.6" rx="16.8" fill="#0062D6" />
    <rect
      x="1.2"
      y="1.2"
      width="33.6"
      height="33.6"
      rx="16.8"
      stroke="white"
      stroke-width="1.6"
    />
    <g clip-path="url(#clip0_4198_127802)">
      <path
        d="M26.8 23.5969C26.8 24.0212 26.6314 24.4282 26.3314 24.7282C26.0313 25.0283 25.6243 25.1969 25.2 25.1969H10.8C10.3756 25.1969 9.96867 25.0283 9.66861 24.7282C9.36855 24.4282 9.19998 24.0212 9.19998 23.5969V14.7969C9.19998 14.3725 9.36855 13.9656 9.66861 13.6655C9.96867 13.3654 10.3756 13.1969 10.8 13.1969H14L15.6 10.7969H20.4L22 13.1969H25.2C25.6243 13.1969 26.0313 13.3654 26.3314 13.6655C26.6314 13.9656 26.8 14.3725 26.8 14.7969V23.5969Z"
        stroke="white"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 21.9969C19.7673 21.9969 21.2 20.5642 21.2 18.7969C21.2 17.0296 19.7673 15.5969 18 15.5969C16.2327 15.5969 14.8 17.0296 14.8 18.7969C14.8 20.5642 16.2327 21.9969 18 21.9969Z"
        stroke="white"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4198_127802">
        <rect
          width="19.2"
          height="19.2"
          fill="white"
          transform="translate(8.39999 8.39844)"
        />
      </clipPath>
    </defs>
  </svg>
);
