import styled from "styled-components";
import { Flex } from "antd";
import CoinBagsBg from "../../../assets/images/auth/coinbags-bg.png";

export const AuthWrap = styled(Flex)`
  width: 100%;
  height: 100vh;
  position: relative;

  .copyright__footer {
    color: var(--lightGrayPrimary);
    position: absolute;
    left: 4%;
    bottom: 3%;
  }

  .auth__left__section__main {
    width: 60%;
    min-width: 800px;
    padding-top: 4%;
    background-image: url(${CoinBagsBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;

    .auth__left__section__sub {
      width: 85%;
      overflow-y: auto;
      gap: 1.5rem;

      .auth__logo__wrap {
        width: 13%;
      }

      .auth__reroute__text {
        text-align: end;
        width: 100%;
      }

      .auth__left__section__form__main {
        margin-top: 50px;
        width: 100%;

        .auth__left__section__form__sub {
          width: 70%;
          min-width: 450px;
          .auth__body__text {
            text-align: start;
          }

          .formoptions__wrap {
            width: 100%;

            button {
              span {
                font-size: 0.85rem;
              }
            }
          }
          .or__divider {
            width: 100%;
            position: relative;
            margin: 0.7rem 0 1rem 0;

            & > div {
              width: 100%;
              background-color: var(--lightGrayPrimary);
              height: 1px;
              position: absolute;
              top: 35%;
              z-index: 1;
            }
            & > p {
              z-index: 2;
              background-color: #fff;
              padding: 0 10px;
            }
          }
          .login__divider {
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .auth__right__section {
    width: 40%;
  }

  @media (min-width: 1800px) {
    .auth__left__section__main {
      .auth__left__section__sub {
        width: 85%;
        overflow-y: auto;
        gap: 3rem;

        .auth__left__section__form__main {
          .auth__left__section__form__sub {
            width: 80%;
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    padding-top: 15%;

    .auth__left__section__main {
      width: 100%;
      min-width: 100%;

      .auth__left__section__sub {
        align-items: center;
        width: 98%;
        max-width: 800px;

        .auth__reroute__text {
          order: 3;
          text-align: center;
        }

        .auth__left__section__form__main {
          .auth__left__section__form__sub {
            align-items: center;
            width: 95%;
            min-width: 95%;
            .formoptions__wrap {
              width: 100%;
              flex-direction: column;
              gap: 1rem;
              margin-bottom: 3rem;
              button {
                width: 100%;
                span {
                  font-size: 1.05rem;
                }
              }
            }

            .or__divider {
              margin: 0 0 20px 0;
            }
            .auth__body__text {
              text-align: center;
            }
          }
        }
      }
    }
    .auth__right__section {
      display: none;
    }
  }

  @media (max-width: 1025px) and (max-height: 600px) {
    padding: 0;

    /* button {
      margin: 5% 0 1rem 0;
    } */
    .auth__left__section__main {
      .auth__left__section__sub {
        .auth__reroute__text {
          margin-bottom: 5%;
        }

        .auth__left__section__form__main {
          .auth__left__section__form__sub {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .copyright__footer {
      font-size: 0.6rem;
    }

    .auth__left__section__main {
      .auth__left__section__sub {
        gap: 0rem;

        .auth__logo__wrap {
          width: 20%;
        }
      }
    }
  }
`;
