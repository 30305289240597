import React from "react";
import { ProgressBarWrapper } from "./progressBar.styles";
import { Flex } from "antd";

interface IProps {
  height?: string;
  transparentBg?: string;
  color?: string;
  width?: string;
  hasPercentageValue?: boolean;
  backgroundColor?: string;
}
const ProgressBar: React.FC<IProps> = ({
  width,
  color,
  transparentBg,
  height,
  hasPercentageValue,
  backgroundColor,
}) => {
  return (
    <ProgressBarWrapper
      justify="flex-start"
      color={color}
      transparentBg={transparentBg}
      height={height}
      width={width}
      backgroundColor={backgroundColor}
    >
      <Flex className="progressbar__main">
        <div className="progressbar__inner"></div>
      </Flex>
      {hasPercentageValue && (
        <span className="progresscount__number">{width}</span>
      )}
    </ProgressBarWrapper>
  );
};
export default ProgressBar;
