import React, { useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getLoggedInAdvertiser } from "../../../network/auth";
import { useAdvertiserContext } from "../../../context/advertiserContext/main";
import { ActionTypes } from "../../../context";

interface Props {
  children?: React.ReactNode;
}

export const Guardian: React.FC<Props> = ({ children }) => {
  const [isLoggedIn] = useState(
    JSON.parse(String(sessionStorage.getItem("advertiser-tokens")))
  );

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  const { data: userData, isLoading } = useQuery(
    ["advertiser", isLoggedIn],
    () => getLoggedInAdvertiser()
  );

  React.useEffect(() => {
    dispatch({
      type: ActionTypes.CurrentUser,
      payload: { ...userData?.data },
    });

    if (!isLoggedIn) {
      window.location.href = `${window.location.origin}/login`;
    }
  }, [isLoggedIn, isLoading]);

  return isLoggedIn ? (
    <React.Fragment>
      {children}
      <Outlet />
    </React.Fragment>
  ) : null;
};
