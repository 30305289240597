import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { LohButton } from "../../../../components/lib/Button";
import { UploadedPostsWrap } from "./postOverview.styles";
import { useNavigate } from "react-router-dom";
import NavTabs from "../../../../components/lib/Tabs/navTabs";
import { FaPlus } from "react-icons/fa6";
import PostCard from "../../../../components/lib/PostCard/postCard";
import { useQuery } from "react-query";
import { getAllPosts } from "../../../../network/posts";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";
import { Pagination } from "antd";

const UploadedPosts = () => {
  const {
    state: { user },
  } = useAdvertiserContext();
  const navigate = useNavigate();

  const [publishedPage, setPublishedPage] = useState(1);
  const [draftsPage, setDraftsPage] = useState(1);
  const [loadmoreLoading, setLoadmoreLoading] = useState(false);

  const {
    data: publishedPosts,
    isLoading,
    refetch: refetchPubished,
    isRefetching: publishedRefetching,
  } = useQuery(["published", user?.id, publishedPage], () =>
    getAllPosts("ACTIVE", user?.id, publishedPage)
  );

  const {
    data: draftsPosts,
    refetch: refetchDrafts,
    isLoading: draftsLoading,
    isRefetching: draftsRefetching,
  } = useQuery(["drafts", user?.id, draftsPage], () =>
    getAllPosts("PENDING", user?.id, draftsPage)
  );

  useEffect(() => {
    setActivepostTab({
      title: "Published Posts",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: publishedPosts?.data,
      refetch: refetchPubished,
      isLoading: isLoading,
      total: publishedPosts?.totalDocuments,
      isRefetching: publishedRefetching,
      page: publishedPage,
    });
  }, [publishedPosts, isLoading]);

  const [activepostTab, setActivepostTab] = useState({
    title: "",
    status: "",
    noDataState: "",
    data: [],
    refetch: refetchPubished,
    isLoading: isLoading,
    total: 0,
    isRefetching: publishedRefetching,
    page: publishedPage,
  });

  const postTabs = [
    {
      title: "Published Posts",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: publishedPosts?.data,
      refetch: refetchPubished,
      isLoading: isLoading,
      total: publishedPosts?.totalDocuments,
      isRefetching: publishedRefetching,
      page: publishedPage,
    },
    {
      title: "Drafts",
      status: "PENDING",
      noDataState: "No News / Articles in your drafts",
      data: draftsPosts?.data,
      refetch: refetchDrafts,
      isLoading: draftsLoading,
      total: draftsPosts?.totalDocuments,
      isRefetching: draftsRefetching,
      page: draftsPage,
    },
  ];

  const handleDataPagination = async (page: number) => {
    try {
      activepostTab.title === "Drafts"
        ? setDraftsPage(page)
        : setPublishedPage(page);

      const response = await getAllPosts(activepostTab.status, user?.id, page);

      setActivepostTab({
        ...activepostTab,
        data: response?.data,
        page: page,
      });

      window.scrollTo(0, 0);
    } catch (e) {}
  };

  return (
    <DashboardLayout>
      <UploadedPostsWrap vertical>
        <Flex justify="space-between">
          <h1 className="post__header">Uploaded Posts</h1>

          {activepostTab?.data?.length > 0 && (
            <LohButton
              width="10rem"
              svgOpacity="1"
              onClick={() => navigate("/posts/upload")}
            >
              <FaPlus color="#fff" /> Upload
            </LohButton>
          )}
        </Flex>

        <NavTabs
          navTabData={postTabs}
          setActiveTab={setActivepostTab}
          activeTab={activepostTab}
          hasItemCount
        />

        {activepostTab.isLoading ? (
          <LohliLoader />
        ) : (
          <>
            {activepostTab?.data?.length > 0 ? (
              <Flex className="postcards__wrap">
                {activepostTab?.data?.map((item: Record<string, any>, idx) => (
                  <Flex className="card__wrap" key={idx}>
                    <PostCard
                      type="feeds_upload"
                      data={item}
                      activepostTab={activepostTab}
                    />
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Flex
                className="nodata__wrap"
                vertical
                align="center"
                justify="center"
              >
                <h3 className="noads__headtitle">
                  {activepostTab?.noDataState}
                </h3>
                <p className="noads__subtitle">
                  Increase Visibility and Engagement Through Your posts
                </p>
                <LohButton
                  svgOpacity="1"
                  width="10rem"
                  onClick={() => navigate("/posts/upload")}
                >
                  <FaPlus color="#fff" /> Upload
                </LohButton>
              </Flex>
            )}
          </>
        )}

        <Flex justify="flex-end">
          <Pagination
            defaultCurrent={1}
            total={activepostTab.total}
            pageSize={18}
            onChange={(e) => handleDataPagination(e)}
            current={activepostTab.page}
          />
        </Flex>
      </UploadedPostsWrap>
    </DashboardLayout>
  );
};

export default UploadedPosts;
