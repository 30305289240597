import { AppReducerState } from "../reducer";
import { ActionTypes, IAction } from "../../types";

export const appReducer = (
  state: AppReducerState,
  { type, payload }: IAction
): AppReducerState => {
  switch (type) {
    case ActionTypes.CurrentAdvert:
      return {
        ...state,
        ...payload,
      };

    case ActionTypes.DeletedQuestionIds:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
