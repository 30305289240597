import { Flex } from "antd";
import styled from "styled-components";

export const UploadAnalyticsOverviewWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  gap: 1rem;

  .metrics__header {
    font-size: 2rem;
  }

  .nodata__wrap {
    width: 100%;
    height: 20rem;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }
  .sections__header {
    font-size: 1rem;
    color: #101828;
    font-family: "LexendMedium", sans-serif;
  }

  .tableLoading {
    height: 20rem;
  }

  .filter__exportbutton__wrap {
    width: 100%;

    .filter__wrap {
      width: 12rem;
      gap: 1.2rem;
      justify-content: space-between;

      .ant-select-selector {
        padding: 0 0.3rem;
      }

      .ant-select-arrow {
        margin-top: -0.5rem;
      }
      .ant-picker-input {
        ::placeholder,
        input {
          font-size: 0.85rem;
          color: var(--blackPrimary);
        }
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 0.85rem;
      }
    }
  }
  .postcards__wrap {
    flex-wrap: wrap;
    padding-top: 1rem;
    .card__wrap {
      width: 33%;
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .postcards__wrap {
      flex-wrap: wrap;
      padding-top: 1rem;
      justify-content: space-between;
      .card__wrap {
        width: 100%;
      }
    }
  }
`;
