import { Flex } from "antd";
import styled from "styled-components";

export const HomePageWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  gap: 1rem;

  .header__section {
    gap: 1rem;
    .homepage__header {
      font-size: 2rem;
    }

    .header__buttons__wrap {
      gap: 1rem;
    }
  }

  .homepage__links {
    font-weight: 300;
    font-size: 0.9rem;
    text-decoration: underline;
    padding-bottom: 0.2rem;
  }
  .homepage__h3 {
    font-size: 1%.1;
  }

  .homepage__section__wrap {
    padding: 2rem 0;
    border-bottom: 0.1rem solid #e4e7ec;
    gap: 1rem;

    .homepage__overviewsums__wrap {
      width: 100%;
      justify-content: space-between;
      gap: 1.4rem;
      overflow-x: auto;

      .overviewsums_main {
        width: 18.5%;
        align-items: flex-start;
        padding: 1rem;
        border: 0.1rem solid #e4e4e4;
        min-width: 14.5rem;
        border-radius: 0.6rem;

        .overviewsums_ptext {
          margin: 0;
          font-weight: 500;
          font-size: 0.9rem;
          margin-bottom: 0.4rem;
          font-family: "LexendMedium", sans-serif;
        }

        .overviewsums_amount {
          color: #353535;
          font-weight: 500;
          font-family: "LexendMedium", sans-serif;
          margin-bottom: 0.5rem;
        }
        .overviewsums_footertext {
          color: #646464;
          font-size: 0.74rem;
        }
      }
    }

    .suggestedbrands__wrap {
      width: 100%;
      justify-content: space-between;
      gap: 0.7rem;
      overflow-x: auto;

      .suggestedbrands_main {
        width: 18.5%;
        align-items: center;
        padding: 1rem 0.5rem;
        border: 0.1rem solid #e4e4e4;
        min-width: 15.2rem;
        border-radius: 0.6rem;
        gap: 0.7rem;

        .logo__wrap {
          width: 10rem;
          height: 10rem;
        }

        .suggested__company {
          margin: 0;
          font-weight: 500;
          line-height: 1rem;
          color: #353535;
        }

        .suggested__username {
          line-height: 1rem;
          font-size: 0.8rem;
        }
        .suggested__brandsbutton {
          width: 100%;
        }
      }
    }
  }
  .homepage__section__wrap__feeds {
    gap: 0;

    .postcards__wrap {
      padding-top: 1rem;
      overflow-x: auto;
      gap: 0.5rem;
      .card__wrap {
        width: 33%;
        min-width: 25.8rem;
      }
    }

    .nodata__wrap {
      padding-top: 2rem;
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .header__section {
      .header__buttons__wrap {
        gap: 0.4rem;
        .ant-btn {
          width: 8rem;
          padding: 0;
          height: 1.2rem;
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
`;
