import React, { useState } from "react";
import { DeleteModal, TableWrap } from "./adsTable.styles";
import { Flex, Modal, Table, notification } from "antd";
import { LohCheckbox } from "../Checkbox";
import { truncateString } from "../../../utils/function-helpers/string-functions";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiBarChart2, FiEdit2, FiPause, FiPlay } from "react-icons/fi";
import { LohButton } from "../Button";
import { FaPlus } from "react-icons/fa6";
import { IoEyeOutline, IoHeartOutline } from "react-icons/io5";
import { RiChat3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import moment from "moment";
import { MenuProps } from "antd";
import { deleteAdvert, deleteMultipleAdverts } from "../../../network/advert";
import {
  BsEmojiSmileFill,
  BsEmojiSmileUpsideDownFill,
  BsHeartFill,
} from "react-icons/bs";

interface IProps {
  tableData: any;
  maxWidth?: string;
  defaultPageSize?: number;
  setSelectedData?: (values: Record<string, any>) => void;
  func?: (values: Record<string, any>) => void;
  setTableChange?: (values: Record<string, any>) => void;
  activeTableTab?: Record<string, any>;
  type?: string;
  isLoading?: boolean;
  tableHeight?: string;
  uploadViewType?: string;
}

const LohTable: React.FC<IProps> = ({
  tableData,
  activeTableTab,
  type,
  isLoading,
  tableHeight,
  uploadViewType,
}) => {
  const [isPlaying, setIsPlaying] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedAds, setSelectedAds] = useState<any>([]);
  const [showDeleteAllAds, setShowDeleteAllAds] = useState(false);

  const navigate = useNavigate();

  const closeDeleteModal = () => {
    setDeleteId(null);
    setShowDeleteAllAds(false);
  };

  const handleSelectAllAds = () => {
    const adIds = [""];
    tableData.map((item: Record<string, any>) =>
      adIds.push(String(item?.advert?.id))
    );

    setSelectedAds(adIds);
  };

  const handleSelectSingleAds = (id: string) => {
    if (!selectedAds.includes(id)) {
      setSelectedAds([...selectedAds, id]);
    } else {
      const filterdAds = selectedAds.filter((item: string) => item !== id);
      setSelectedAds(filterdAds);
    }
  };
  const handleDeleteAd = async () => {
    setIsSubmitting(true);

    try {
      const data = showDeleteAllAds
        ? await deleteMultipleAdverts({ id: [...selectedAds] })
        : await deleteAdvert(deleteId);
      activeTableTab !== undefined && activeTableTab.refetch();
      setIsSubmitting(false);
      setDeleteId(null);
      notification.open({
        message: `Successful`,
        description: "Advert Has Been Deleted",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
    } catch (e: any) {
      setIsSubmitting(false);

      if (e?.response) {
        notification.open({
          message: `Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const webColumns = [
    {
      title: (
        <Flex>
          <LohCheckbox
            onChange={() =>
              selectedAds?.length >= tableData?.length
                ? setSelectedAds([])
                : handleSelectAllAds()
            }
            checked={selectedAds?.length >= tableData?.length}
          />
        </Flex>
      ),
      dataIndex: "checkbox",
      key: "checkbox",
      render: (arr: any, ad: any) => (
        <LohCheckbox
          onChange={() => handleSelectSingleAds(String(ad?.advert?.id))}
          checked={selectedAds.includes(String(ad?.advert?.id))}
        />
      ),
    },

    {
      title: <p className="th__titles video__th__title">Video Content</p>,
      dataIndex: "title",
      key: "title",
      render: (arr: any, ad: any) => (
        <Flex gap=".6rem">
          <Flex className="table__image__wrap">
            <ReactPlayer
              url={ad?.advert?.video?.publicURL}
              width={"100%"}
              height={"100%"}
              playing={isPlaying === ad?.advert?.id}
              onEnded={() => setIsPlaying("")}
            />
          </Flex>
          <Flex
            className="adtitle__des"
            vertical
            align="start"
            onClick={() =>
              navigate("/uploads/view", {
                state: {
                  activity: "Edit",
                  id: ad?.advert?.id,
                  type: uploadViewType || "ad",
                },
              })
            }
          >
            <p>{truncateString(ad?.advert?.title || "", 30)}</p>
            <p className="table__des">
              {truncateString(ad?.advert?.description || "", 70)}
            </p>
          </Flex>
        </Flex>
      ),
    },

    {
      title: <p className="th__titles">Views</p>,
      dataIndex: "views",
      key: "views",
      render: (arr: any, ad: any) => (
        <p className="number__texts">{ad?.advert?.metadata?.totalViews || 0}</p>
      ),
    },
    {
      title: <p className="th__titles">Likes</p>,
      dataIndex: "likes",
      key: "likes",
      render: (arr: any, ad: any) => (
        <p className="number__texts">{ad?.advert?.metadata?.totalLikes || 0}</p>
      ),
    },
    {
      title: <p className="th__titles">Comments</p>,
      dataIndex: "comments",
      key: "comments",
      render: (arr: any, ad: any) => (
        <p className="number__texts">
          {ad?.advert?.metadata?.totalComments || 0}
        </p>
      ),
    },
    {
      title: <p className="th__titles">Shekels Given</p>,
      dataIndex: "shekels",
      key: "shekels",
      render: (arr: any, ad: any) => (
        <p className="number__texts">{ad?.advert?.metadata?.totalSpent || 0}</p>
      ),
    },
    {
      title: <p className="th__titles">Date Published</p>,
      dataIndex: "date",
      key: "date",
      render: (arr: any, ad: any) => (
        <p className="number__texts">
          {moment(ad?.advert?.createdAt).format("MMM D, YYYY")}
        </p>
      ),
    },

    {
      title: <></>,
      dataIndex: "icons",
      key: "icons",
      render: (arr: any, ad: any) => (
        <Flex className="icons__wrap">
          {type === "analytics" ? (
            <span
              className="footer__text"
              onClick={() =>
                navigate("/uploads/view", {
                  state: {
                    activity: "Edit",
                    id: ad?.advert?.id,
                    type: uploadViewType || "ad",
                  },
                })
              }
            >
              <FiBarChart2 size="21" color="var(--bluePrimary)" />
            </span>
          ) : (
            <>
              <RiDeleteBin6Line onClick={() => setDeleteId(ad?.advert?.id)} />
              <FiEdit2
                onClick={() =>
                  navigate("/ads/upload", {
                    state: { activity: "Edit", id: ad?.advert?.id },
                  })
                }
              />

              {isPlaying === ad?.advert?.id ? (
                <FiPause onClick={() => setIsPlaying("")} />
              ) : (
                <FiPlay onClick={() => setIsPlaying(ad?.advert?.id)} />
              )}
            </>
          )}
        </Flex>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Delete</span>,
      icon: <RiDeleteBin6Line />,
    },
    {
      key: "2",
      label: <span>Edit</span>,
      icon: <FiEdit2 />,
    },
  ];

  const mobileColumns = [
    {
      title: <p className="th__titles video__th__title">Video Content</p>,
      dataIndex: "title",
      key: "title",
      render: (arr: any, ad: any) => (
        <Flex gap=".6rem">
          <Flex className="table__image__wrap">
            <ReactPlayer
              url={ad?.advert?.video?.publicURL}
              width={"100%"}
              height={"100%"}
              playing={isPlaying === ad?.advert?.id}
              onEnded={() => setIsPlaying("")}
            />
          </Flex>
          <Flex
            className="adtitle__des"
            vertical
            align="start"
            onClick={() =>
              navigate("/uploads/view", {
                state: {
                  activity: "Edit",
                  id: ad?.advert?.id,
                  type: uploadViewType || "ad",
                },
              })
            }
          >
            <p className="mobile__adheader">
              {truncateString(ad?.advert?.title || "", 30)}
            </p>

            <p className="table__des">
              {truncateString(ad?.advert?.description || "", 60)}
            </p>
            <Flex className="mobile__icons__wrap" justify="space-between">
              <Flex className="mobile__iconswrap__left">
                {" "}
                <p>
                  {ad?.engagement?.liked ? (
                    <BsHeartFill color="var(--errorPrimary)" />
                  ) : (
                    <IoHeartOutline />
                  )}
                  {ad?.advert?.metadata?.totalLikes || 0}
                </p>
                <p>
                  <RiChat3Line />
                  {ad?.advert?.metadata?.totalComments || 0}
                </p>
                <p>
                  <IoEyeOutline />
                  {ad?.advert?.metadata?.totalViews || 0}
                </p>
              </Flex>

              {}

              <Flex gap=".5rem">
                {type === "analytics" ? (
                  <span
                    className="footer__text"
                    onClick={() =>
                      navigate("/uploads/view", {
                        state: {
                          activity: "Edit",
                          id: ad?.advert?.id,
                          type: uploadViewType || "ad",
                        },
                      })
                    }
                  >
                    <FiBarChart2 size="21" color="var(--bluePrimary)" />
                  </span>
                ) : (
                  <>
                    <RiDeleteBin6Line
                      onClick={() => setDeleteId(ad?.advert?.id)}
                    />
                    <FiEdit2
                      onClick={() =>
                        navigate("/ads/upload", {
                          state: {
                            activity: "Edit",
                            id: ad?.advert?.id,
                          },
                        })
                      }
                    />
                    {isPlaying === ad?.advert?.id ? (
                      <FiPause onClick={() => setIsPlaying("")} />
                    ) : (
                      <FiPlay onClick={() => setIsPlaying(ad?.advert?.id)} />
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ),
    },
  ];

  const handleAdUploadRoute = () => {
    window.location.href = "/ads/upload";
  };
  return (
    <TableWrap height={tableHeight}>
      {tableData?.length > 0 || isLoading ? (
        <>
          <Flex className="web__table" vertical align="flex-end">
            {selectedAds?.length > 0 && (
              <LohButton
                width="13rem"
                padding="0"
                height="2rem"
                fontSize=".8rem"
                margin="0 0 1rem 0"
                backgroundColor="transparent"
                color="var(--bluePrimary)"
                onClick={() => setShowDeleteAllAds(true)}
              >
                Delete Selected Items
              </LohButton>
            )}
            <Table
              dataSource={tableData}
              columns={webColumns}
              scroll={{ x: "100" }}
              loading={isLoading}
            />
          </Flex>
          <Flex className="mobile__table">
            <Table
              dataSource={tableData}
              columns={mobileColumns}
              loading={isLoading}
            />
          </Flex>
        </>
      ) : (
        <Flex className="nodata__wrap" vertical align="center" justify="center">
          <p className="noads__headtitle">
            {activeTableTab !== undefined
              ? activeTableTab.noDataState
              : "No ads have been published"}
          </p>
          <p className="noads__subtitle">
            Increase Visibility and Engagement Through Your Ads
          </p>
          {/* {activeTableTab.status === "PUBLISHED" && ( */}
          <LohButton svgOpacity="1" width="10rem" onClick={handleAdUploadRoute}>
            <FaPlus color="#fff" /> Upload Ads
          </LohButton>
          {/* )} */}
        </Flex>
      )}

      <DeleteModal
        open={!!deleteId || showDeleteAllAds}
        onOk={handleDeleteAd}
        onCancel={closeDeleteModal}
        footer={null}
        closeIcon={false}
        centered
      >
        <Flex vertical align="start">
          <h3>Delete {showDeleteAllAds && "All Selected"} Ad</h3>
          <p>
            You are about to delete{" "}
            {showDeleteAllAds ? "all selected ads" : "this ad"}, are you sure?
          </p>
        </Flex>

        <Flex justify="center" gap="1rem" className="buttons__wrap">
          <LohButton
            backgroundColor="transparent"
            borderColor="var(--bluePrimary)"
            color="var(--bluePrimary)"
            htmlType="submit"
            onClick={closeDeleteModal}
            width="13rem"
            padding="0"
            height="2.5rem"
          >
            No! Cancel
          </LohButton>
          <LohButton
            onClick={handleDeleteAd}
            width="13rem"
            loading={isSubmitting}
            padding="0"
            height="2.5rem"
          >
            Yes! Sure
          </LohButton>
        </Flex>
      </DeleteModal>
    </TableWrap>
  );
};
export default LohTable;
