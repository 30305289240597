import { ActionTypes, IAction } from "../../types";
import { UserReducerState } from "../reducer";

export const userReducer = (
  state: UserReducerState,
  { type, payload }: IAction
): UserReducerState => {
  switch (type) {
    case ActionTypes.CurrentUser:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
