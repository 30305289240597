import React, { Dispatch, createContext, useContext, useReducer } from "react";
import { MainReducerState, initialState, mainReducer } from "./reducer";
import { IAction } from "../types/action.interface";

const AdvertiserContext = createContext<{
  state: MainReducerState;
  dispatch: Dispatch<IAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const AdminProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <AdvertiserContext.Provider value={{ state, dispatch }}>
      {children}
    </AdvertiserContext.Provider>
  );
};

export const useAdvertiserContext = () => useContext(AdvertiserContext);
