export const validateBasicField = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length < 3) {
      setStatus({
        error: "This feld cannot contain less than 3 characters",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateNonRequiredField = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    // if (!value) {
    //   resolve(value);
    // }
    resolve(value);
  });
};
export const validateComment = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      return;
    } else if (value.length > 50) {
      setStatus({
        error: "This feld cannot contain more than 50 characters",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};
export const validateBasicFieldNoTextLimit = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length < 1) {
      setStatus({
        error: "This feld cannot contain less than 1 character",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateBasicField50CharactersTextLimit = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length < 20) {
      setStatus({
        error: "This field cannot contain less than 20 characters",
      });
      return;
    } else if (value.length > 250) {
      setStatus({
        error: "This field cannot contain more than 250 characters",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateBasicField1500CharactersTextLimit = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length < 20) {
      setStatus({
        error: "This field cannot contain less than 20 characters",
      });
      return;
    } else if (value.length > 1500) {
      setStatus({
        error: "This field cannot contain more than 1500 characters",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateDescriptionFields = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length < 200) {
      setStatus({
        error: "This feld cannot contain less than 200 characters",
      });
    } else if (value.length > 2500) {
      setStatus({
        error: "This feld cannot contain more than 2500 characters",
      });
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateEmail = (rule: any, value: string, setStatus: any) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({ error: "Please enter your email address 😇" });
      return;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setStatus({
        error: "Email address entered is Invalid",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validatePhoneNumber = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This input field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length > 15) {
      setStatus({
        error: "Please enter a valid phone number",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export function getMachineId() {
  let machineId = localStorage.getItem("MachineId");

  if (!machineId) {
    machineId = crypto.randomUUID();
    localStorage.setItem("MachineId", machineId);
  }

  return machineId;
}

export function onlyNumberKey(
  evt: React.KeyboardEvent<HTMLInputElement>
): void {
  const { keyCode, ctrlKey } = evt;

  if (
    // Allow delete key (backspace and delete)
    keyCode === 8 || // Backspace
    keyCode === 46 || // Delete
    keyCode === 187 || // plus
    // Allow arrow keys
    (keyCode >= 37 && keyCode <= 40) || // Arrow keys: left, up, right, down
    // Allow Ctrl+A (select all)
    (keyCode === 65 && ctrlKey)
  ) {
    return;
  }

  const inputChar = String.fromCharCode(keyCode);

  // Regular expression to match only numeric digits
  const numericRegex = /^[0-9]+$/;

  if (!numericRegex.test(inputChar)) {
    evt.preventDefault();
  }
}

export function onlyBackspaceKey(evt: any): void {
  const { keyCode, ctrlKey } = evt;

  if (
    // Allow delete key (backspace and delete)
    keyCode === 8 || // Backspace
    keyCode === 46 // Delete
  ) {
    return;
  }

  const inputChar = String.fromCharCode(keyCode);

  // Regular expression to match only numeric digits
  const numericRegex = /^[0-9]+$/;

  if (!numericRegex.test(inputChar)) {
    evt.preventDefault();
  }
}

export const validateInstagram = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        success: "success",
      });
      resolve(value);
    } else if (
      !/^(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am|instagr\.com)\/(\w+)/.test(
        value
      )
    ) {
      setStatus({
        error: "Invalid instagram url entered",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateYoutube = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        success: "success",
      });
      resolve(value);
    } else if (
      !/(https?:\/\/)?(www\.)?youtube\.com\/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z\-0-9]+/.test(
        value
      )
    ) {
      setStatus({
        error: "Invalid youtube url entered",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateFacebook = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        success: "success",
      });
      resolve(value);
    } else if (
      !/^(https?:\/\/)?(www\.)?(facebook|fb)\.(com|me)\/(profile\.php\?id=\d+|[a-zA-Z0-9.]+)(\?.*)?$/.test(
        value
      )
    ) {
      setStatus({
        error: "Invalid facebook url entered",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateTiktok = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        success: "success",
      });
      resolve(value);
    } else if (
      !/((?:http|https):\/\/)?(|tiktok.com)\/\/?(\S+\/+[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z\-0-9])([0-9]+)?/.test(
        value
      )
    ) {
      setStatus({
        error: "Invalid tiktok url entered",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateTwitter = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        success: "success",
      });
      resolve(value);
    } else if (
      !/^((?:https?:\/\/)?(?:www\.)?twitter\.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/.test(
        value
      )
    ) {
      setStatus({
        error: "Invalid twitter url entered",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateName = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error:
          "We’re going to need your  name to proceed with this application",
      });
      return;
    } else if (value.length > 20) {
      setStatus({
        error:
          "First Name, Last Name, Other Name cannot be more than 20 characters",
      });

      return;
    } else if (value.length < 3) {
      setStatus({
        error:
          "First Name, Last Name, Other Name cannot be less than 3 characters",
      });

      return;
    } else if (
      !!/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/.test(value) ||
      /\d/.test(value)
    ) {
      setStatus({
        error:
          "First Name, Last Name, Other cannot contain a numeric character",
      });

      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateBasicUrl = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "We need a call to action link",
      });
      return;
    } else if (
      !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        value
      )
    ) {
      setStatus({
        error: "Please enter a valid url",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};

export const validateVideo = (filename: any) => {
  var parts = filename.split(".");
  switch (parts[parts.length - 1].toLowerCase()) {
    case "webm":
    case "mp4":
    case "3gp":
    case "ogg":
    case "flv":
      return true;
  }
  return false;
};

export const validateImageFile = (filename: any) => {
  var parts = filename.split(".");
  switch (parts[parts.length - 1].toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "webp":
    case "png":
      return true;
  }
  return false;
};

export const validateBvn = (
  rule: any,
  value: string,
  setStatus: (value: Record<any, string>) => void
) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        error: "This field is empty. Kindly re-check this entry",
      });
      return;
    } else if (value.length !== 11) {
      setStatus({
        error: "This feld must contain 11 characters",
      });
      return;
    } else {
      setStatus({ success: "success" });
      resolve(value);
    }
  });
};
