import styled from "styled-components";
import { Flex } from "antd";

type Custom = {
  progressBarWidth?: string;
};

export const VerificationsWrap = styled(Flex)<Custom>`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;

  .verification__main {
    width: 100%;
    padding: 2% 4%;
    gap: 2rem;

    .verification__logo__wrap {
      width: 7%;
    }

    .copyright__footer {
      color: var(--lightGrayPrimary);
      position: fixed;
      left: 4%;
      bottom: 3%;
    }
    .verification__body__main {
      width: 100%;
      margin-top: 5rem;

      .verification__body__sub {
        width: 30%;
        min-width: 300px;
        .verification__icon__wrap {
          width: 10rem;
          margin-bottom: 1rem;
          svg {
            font-size: 4.7rem;
          }
        }
        .verification__subheader {
          color: var(--lightGrayPrimary);
          text-align: center;
          margin-bottom: 2rem;

          span {
            color: var(--blackPrimary);
            font-weight: 600;
          }
        }

        .verification__failed__subheader {
          margin-bottom: 3.8rem;
        }

        .reroute__link {
          margin-top: 1.5rem;
          font-weight: 300;
          font-size: 0.9rem;

          svg {
            margin: 0 3px -0.2rem 0;
            font-weight: bold;
          }
        }

        .changeemail__link {
          text-decoration: underline;
          margin-top: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 950px) {
    .verification__main {
      align-items: center;
      padding-top: 40px;
      .verification__logo__wrap {
        width: 25%;
      }
      .verification__body__main {
        margin-top: 30%;

        .verification__body__sub {
          width: 90%;
          min-width: 90%;
        }
      }
    }
  }
`;
