import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import Signup from "../pages/AuthPages/Signup/signup";
import Login from "../pages/AuthPages/Login/login";
import UserDetails from "../pages/AuthPages/UserInformation/user-information";
import AdvertiserType from "../pages/AuthPages/AdvertiserType/advertisertype";
import VerifyCredentials from "../pages/AuthPages/Verification/verifying";
import Authenticating from "../pages/AuthPages/Verification/authenticating";
import { Guardian } from "../components/lib/Guardian";
import Homepage from "../pages/DashboardPages/HomePage/homepage";
import AdsPage from "../pages/DashboardPages/Ads/AdsOverviewPage/adsOverview";
import UploadAds from "../pages/DashboardPages/Ads/UploadAd/uploadAd";
import SlingPage from "../pages/DashboardPages/Sling/sling";
import EngagementMetrics from "../pages/DashboardPages/Analytics/AnalyticsOverview/engagementMetrics";
import UploadPost from "../pages/DashboardPages/Posts/uploads/uploadpost";
import UploadedPosts from "../pages/DashboardPages/Posts/overview/postOverview";
import SearchPage from "../pages/DashboardPages/Search/search";
import NotificationsPage from "../pages/DashboardPages/Notifications/notifications";
import UploadAnalyticsOverview from "../pages/DashboardPages/Analytics/UploadsAnalytics/uploadsAnalyticsOverview";
import UploadViewPage from "../pages/DashboardPages/UploadView/uploadView";
import FeedsPage from "../pages/DashboardPages/Feeds/overview/feeds";
import FeedsPosterProfilePage from "../pages/DashboardPages/Feeds/posterProfile/posterProfile";
import ProfilePage from "../pages/DashboardPages/Profile/profile";

export const MainRoutes = () => {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Login />}>
                <Guardian />
              </React.Suspense>
            }
          >
            <Route path="/" element={<Homepage />} />
            <Route path="/ads" element={<AdsPage />} />
            <Route path="/ads/upload" element={<UploadAds />} />
            <Route path="/analytics/overview" element={<EngagementMetrics />} />
            <Route path="/sling" element={<SlingPage />} />
            <Route path="/posts" element={<UploadedPosts />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/uploads/view" element={<UploadViewPage />} />
            <Route path="/posts/upload" element={<UploadPost />} />
            <Route path="/feeds/overview" element={<FeedsPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/feeds/poster-profile"
              element={<FeedsPosterProfilePage />}
            />

            <Route
              path="/analytics/your-uploads"
              element={<UploadAnalyticsOverview />}
            />
            <Route path="/notifications" element={<NotificationsPage />} />
          </Route>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/advertiser-type" element={<AdvertiserType />} />
          <Route path="/verify-credentials" element={<VerifyCredentials />} />
          <Route path="/authenticating" element={<Authenticating />} />

          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </React.Fragment>
    </Router>
  );
};
