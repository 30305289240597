import { Flex, Modal } from "antd";
import { Header } from "antd/es/layout/layout";
import styled from "styled-components";

type Custom = {
  showViewConnectSuccessfulPopup?: boolean;
};

export const HeaderWrap = styled(Header)<Custom>`
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  background-color: var(--bluePrimary);
  height: 5rem;
  padding: 0 4rem;
  z-index: 9;
  top: 0;

  .watcher__connected {
    position: fixed;
    width: 100%;
    top: 4rem;
    transition: all 0.3s ease;
    height: ${({ showViewConnectSuccessfulPopup }) =>
      showViewConnectSuccessfulPopup ? "3rem" : "0rem"};
    overflow: hidden;

    .watcher__connected__inner {
      align-items: center;
      width: max-content;
      padding: 0.8rem 1rem 0.8rem 0.8rem;
      border-radius: 0.3rem;
      background-color: var(--successPrimary);
      color: #fff;
      gap: 0.5rem;

      .watcher__connected__text {
        margin: 0;
        height: max-content;
        line-height: 1rem;
        font-size: 0.8rem;
      }
    }
  }
  .header__wrap__main {
    align-items: center;
    width: 100%;
    height: 100%;

    .header__logo__wrap {
      width: 5rem;

      img {
        object-fit: contain;
      }
    }

    .nav__items__wrap {
      .nav__item {
        margin: 0;
        padding: 0.2rem 0.2rem;
        font-size: 0.85rem;
        margin: 0 0.3rem;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border-radius: 4px;
      }

      .active__nav__item {
        color: var(--blackPrimary);
        background-color: #e5c487;
        padding: 0.2rem 0.8rem;
        margin: 0;
      }
    }

    .header__profilenav__wrap {
      align-items: center;
      flex-wrap: nowrap;
      .header__iconwrap {
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 100%;
        position: relative;

        .newnotification__alert {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: #fff;
          border-radius: 100%;
          right: 0.5rem;
          bottom: 0.6rem;
          z-index: 9;
          animation: blink 1s infinite ease-in-out;
        }

        svg {
          font-size: 1.2rem;
        }
      }

      .header__iconwrap__active {
        background-color: #e5c487;
      }

      .user__image__wrap {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        margin-left: 0.5rem;
      }

      .profile__headername__wrap {
        p,
        span {
          margin: 0 0 0 0.7rem;
          color: #fff;
          text-align: left;
          font-size: 0.9rem;
        }

        span {
          font-size: 0.8rem;
          color: #eeeeee99;

          span {
            color: #fff;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
    position: relative;

    .header__wrap__main {
      .nav__items__wrap {
        display: none;
      }

      .header__profilenav__wrap {
        .notification__icon {
          width: 1.6rem;
          margin: 0 -0.3rem -0.8rem 0;
        }
        .profile__headername__wrap {
          display: none;
        }
      }
    }
  }
`;

export const ConnectWatcherModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px 1rem;
  }

  .ant-modal-close-icon {
    opacity: 0.5;
  }

  .connectwatcherform__headersection {
    width: 100%;
    .connectwatcher__header {
      font-size: 1.3rem;
      margin: 0;
    }
    .connectwatcher__subheader {
      color: var(--lightGrayPrimary);
      font-size: 0.8rem;
      margin: 0;
    }
  }
  .connect__form__section {
    .or__divider {
      width: 100%;
      position: relative;
      margin: 0.7rem 0 0 0;

      & > div {
        width: 100%;
        background-color: var(--lightGraySecondary);
        height: 1px;
        position: absolute;
        top: 35%;
        z-index: 1;
      }
      & > p {
        z-index: 2;
        background-color: #fff;
        padding: 0 10px;
      }
    }

    ::placeholder {
      font-size: 0.9rem;
    }
    .ant-form-item {
      .ant-select-selection-placeholder {
        margin-top: 0.7rem;
      }

      .ant-select-selection-search {
        input {
          font-size: 1rem;
        }
      }
    }
  }

  .connect__verification__section {
    .verification__icon__wrap {
      width: 7rem;
      margin: 3rem 0 1rem 0;
      svg {
        font-size: 4.7rem;
      }
    }
    .verification__subheader {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 0.85rem;
      color: var(--lightGrayPrimary);
      max-width: 20rem;

      span {
        color: var(--blackPrimary);
        font-size: 0.9rem;
      }
    }
  }

  @keyframes blink {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
`;

export const NotificationsPopupWrap = styled(Flex)`
  width: 16rem;
  min-width: 200px;

  .notification__popup__header__title {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 600;
  }

  .notification__popup__body__title {
    margin-top: 0.5rem;
  }

  .notification__popup__header {
    width: 100%;
    padding: 0.3rem 0 0.5rem 0;
    border-bottom: 0.01rem solid var(--lightGraySecondary);
    margin-bottom: 0.3rem;

    .notification__popup__header__navtext {
      font-size: 0.8rem;
      color: var(--bluePrimary);
    }
  }
  .no__notifications {
    justify-content: center;
    padding: 0.5rem 0.5rem 0 0.5rem;
    width: 100%;
    p {
      font-size: 0.8rem;
    }
  }

  .notification__item {
    margin-bottom: 0.3rem;
    width: 100%;

    .notifcations___avatar {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      margin-top: 0.3rem;
    }

    .notification__body__text {
      text-align: start;
      margin: 0;
      width: calc(100% - 4rem);
      margin-left: 0.3rem;
      white-space: wrap;
      font-size: 0.8rem;
      line-height: 1.1rem;

      .notification__body__time {
        text-align: start;
        color: var(--lightGrayPrimary);
        font-size: 0.7rem;
        margin: 0;
      }
    }

    .notifcations___videoimage {
      width: 2rem;
      height: 2rem;
      overflow: hidden;
      margin-top: 0.3rem;
    }
  }
`;

export const ViewConnectedWatcherModalWrap = styled(Modal)`
  .ant-modal-content {
    padding: 24px 1rem;
  }

  .ant-modal-close-icon {
    opacity: 0.5;
  }

  .viewconnectedwatcher__section {
    gap: 1rem;
    .viewwatcher__headersection {
      width: 100%;
      .viewwatcher__header {
        font-size: 1.3rem;
        margin: 0;
      }
      .viewwatcher__subheader {
        color: var(--lightGrayPrimary);
        font-size: 0.8rem;
        margin: 0;
      }
    }

    .watcher__balance__section {
      padding: 1rem;
      background-color: var(--bluePrimary);
      border-radius: 1rem;

      .balancesecion__ptext {
        margin: 0;
        font-size: 0.7rem;
        color: #fff;
      }

      .balance {
        color: #fff;
        line-height: 0.5rem;
        font-size: 1.7rem;
        margin: 0.9rem 0.2rem 1.1rem 0;
        font-weight: 600;
      }
    }

    .watcherdetails__section {
      padding: 1rem;
      background-color: #d5e4f5;
      border-radius: 0.6rem;

      .watcherdetails__p {
        font-size: 0.8rem;
        color: var(--lightGrayPrimary);
        margin: 0;
        line-height: 1rem;

        .watcherdetails__span {
          font-size: 0.75rem;
          color: var(--blackPrimary);
          margin: 0;
        }
      }

      .watcherdetails__left {
        text-align: left;
      }
      .watcherdetails__right {
        text-align: right;
      }
    }
  }

  .transaction__history__section {
    .transaction__h3 {
      font-size: 0.85rem;
      margin: 0;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #e4e4e4;
      border-radius: 0.3rem;
    }

    .transaction__items__wrap {
      height: calc(100vh - 31rem);
      overflow-y: auto;
      align-items: flex-start;
      padding-right: 0.4rem;

      .transaction__item__main {
        border-top: 0.01rem solid var(--lightGraySecondary);
        padding: 0.7rem 0 0 0;
        gap: 0.3rem;
        flex-wrap: nowrap;
        margin-top: 0.5rem;
        width: 100%;

        .icon__headerwrap {
          svg {
            margin: 0 0.5rem 0 0;
          }
          .transaction__h3 {
            text-align: start;
            line-height: 1rem;

            .transaction__description {
              text-align: start;
              font-size: 0.75rem;
              color: var(--lightGrayPrimary);
              margin: 0;
            }
          }
        }

        .transaction__time {
          text-align: start;
          font-size: 0.65rem;
          color: var(--lightGrayPrimary);
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }
`;
