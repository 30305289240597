import styled from "styled-components";
import { Button, Checkbox } from "antd";

type Custom = {};

export const LohCheckbox = styled(Checkbox)<Custom>`
  margin-bottom: 0;

  .ant-checkbox-inner {
    border-width: 0.1rem;
    border-color: var(--lightGrayPrimary);
  }

  .checkbox__text {
    text-align: left;
    font-size: 0.9rem;
    margin-top: 1rem;

    a {
      font-size: 0.9rem;
      text-decoration: underline;
      font-weight: 400;
    }
  }
`;
