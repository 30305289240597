export function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

export const formatMoney = (amount: number, noKobo: boolean) => {
  if (!amount) return;
  if (noKobo && amount > 0) {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return parseInt(String(amount))
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
};

export const editNotificationUsernameString = (
  description: string,
  username: string
) => {
  let newString = description.replace("[username]", username);
  return newString;
};

export const pluralizeString = (text: string, textLength: number) => {
  let newString =
    textLength > 1 || textLength === 0
      ? `${
          text[text.length - 1] === "y" ? text.replace("y", "ies") : text + "s"
        }`
      : text;

  return newString;
};

export const formatNumber = (num: number) => {
  if (Math.abs(num) >= 1.0e12) {
    return (num / 1.0e12).toFixed(1) + "T"; // Trillions
  } else if (Math.abs(num) >= 1.0e9) {
    return (num / 1.0e9).toFixed(1) + "B"; // Billions
  } else if (Math.abs(num) >= 1.0e6) {
    return (num / 1.0e6).toFixed(1) + "M"; // Millions
  } else if (Math.abs(num) >= 1.0e3) {
    return (num / 1.0e3).toFixed(1) + "k"; // Thousands
  } else {
    return num.toString(); // Less than 1,000, no formatting needed
  }
};
