import React, { useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateFacebook,
  validateInstagram,
  validateTiktok,
  validateTwitter,
  validateYoutube,
} from "../../../../utils/function-helpers/form-validators";

import { LohButton } from "../../../../components/lib/Button";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import {
  getLoggedInAdvertiser,
  updateAdvertiserBasicInfo,
  updateAdvertiserSocialLinks,
} from "../../../../network/auth";
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { ActionTypes } from "../../../../context";

interface IProps {
  activeForm?: number;
  setActiveForm?: any;
}

const ProfilePageSocialLinks: React.FC<IProps> = ({
  setActiveForm,
  activeForm,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fillFormDetails, setFillFormDetails] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
    facebook: "",
    twitter: "",
  });

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();
  console.log(user);

  useEffect(() => {
    setFillFormDetails({
      instagram: user?.advertiserInfo?.socialLinks?.instagram,
      tiktok: user?.advertiserInfo?.socialLinks?.tiktok,
      youtube: user?.advertiserInfo?.socialLinks?.youtube,
      facebook: user?.advertiserInfo?.socialLinks?.facebook,
      twitter: user?.advertiserInfo?.socialLinks?.twitter,
    });
  }, [user]);

  const handleSocialsSubmit = async (values: Record<string, any>) => {
    const payload = {
      ...values,
    };

    setIsSubmitting(true);
    try {
      await updateAdvertiserSocialLinks(payload);
      const userResult = await getLoggedInAdvertiser();

      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...userResult?.data },
      });
      notification.open({
        message: `Success`,
        description: "Basic info updated successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const socialFormDetails = [
    {
      name: "facebook",
      placeholder: "Facebook Link (optional)",
      validator: validateFacebook,
      icon: <FaFacebook />,
    },
    {
      name: "twitter",
      placeholder: "Twitter (X) Link (optional)",
      validator: validateTwitter,
      icon: <FaXTwitter />,
    },
    {
      name: "instagram",
      placeholder: "Instagram Link (optional)",
      validator: validateInstagram,
      icon: <FaInstagram />,
    },
    {
      name: "tiktok",
      placeholder: "Tiktok Link (optional)",
      validator: validateTiktok,
      icon: <FaTiktok />,
    },
    {
      name: "youtube",
      placeholder: "Youtube Link (optional)",
      validator: validateYoutube,
      icon: <FaYoutube />,
    },
  ];

  return (
    <Flex className="details__section">
      <Flex className="details__left" vertical>
        <Flex vertical>
          <h3 className="details__title">Social</h3>
          <p className="details__subtitle">Write a short information</p>
        </Flex>
        {activeForm !== 3 && (
          <LohButton
            padding="0"
            width="7rem"
            height="2.5rem"
            onClick={() => setActiveForm(3)}
          >
            Edit
          </LohButton>
        )}
      </Flex>
      <CustomForm
        formFinishButtonText="Save Changes"
        handleSubmit={handleSocialsSubmit}
        formDetails={socialFormDetails}
        fillFormDetails={fillFormDetails}
        handleCancel={() => setActiveForm(0)}
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
        isFormDisabled={activeForm !== 3}
        gap="1.5rem"
      />
    </Flex>
  );
};

export default ProfilePageSocialLinks;
