import { instance } from "./axios";

export const advertiserConnectWatcher = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post(
    `auth/request-watcher-sling-connect`,
    payload
  );
  return data;
};

export const advertiserGetConnectedWatcher = async (token: any) => {
  const { data } = await instance.get(
    `auth/verify-watcher-sling-connect?token=${token}`
  );
  return data;
};
