import React, { useEffect, useRef, useState } from "react";
import { Flex, Form, notification } from "antd";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { UploadPostWrap } from "./uploadpost.styles";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { TextField } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateImageFile,
  validateVideo,
} from "../../../../utils/function-helpers/form-validators";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { LohButton } from "../../../../components/lib/Button";
import { Editor } from "@tinymce/tinymce-react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import {
  createPost,
  createPostVideoUrl,
  deletePostMediaUrl,
  getSinglePost,
  updateSinglePost,
} from "../../../../network/posts";
import { useQuery } from "react-query";
import { FiLoader, FiUploadCloud } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactPlayer from "react-player";
import { uploadVideoToS3 } from "../../../../network/advert";
import ProgressBar from "../../../../components/lib/ProgressBar/progressBar";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";

const UploadPost = () => {
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();
  const location = useLocation();
  const { state } = location;
  const {
    data: post,
    isLoading: postLoading,
    refetch,
  } = useQuery(["singlepost", state?.id], () => getSinglePost(state?.id), {
    enabled: !!state?.id,
  });

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [mediaId, setMediaId] = useState({
    publicURL: "",
    postUUID: "",
    id: null,
  });
  const [isMediaSubmitting, setIsMediaSubmitting] = useState(false);
  const [isMediaDeleting, setIsMediaDeleting] = useState(false);

  const editorRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          title: post?.data?.title,
          body: post?.data?.body,
        });
        setMediaId({
          publicURL: post?.data?.media[0]?.publicURL,
          postUUID: post?.data?.uuid,
          id: post?.data?.media[0]?.id,
        });
        setEditorValue(post?.data?.body);
      } catch (error) {}
    }
    fillFormDetails();
  }, [post]);

  async function handleFileUpload(event: any, droppedFile: any) {
    if (!event?.target?.files[0]) {
      return;
    }
    const maxFileLimit = 10048576; // 64kb
    let file = droppedFile || event?.target?.files[0];

    if (!validateVideo(file?.name) && !validateImageFile(file?.name)) {
      notification.open({
        message: `Upload Failed`,
        description: "Invalid file Type. Please re-try",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }

    setIsMediaSubmitting(true);

    const payload = {
      filename: file.name,
      uuid: post?.data?.uuid,
    };

    try {
      const mediaId = await createPostVideoUrl(payload);
      await uploadVideoToS3(mediaId.data.signedUrl, file);
      setMediaId({
        ...mediaId?.data,
      });

      notification.open({
        message: `Success`,
        description: "Media uploaded successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsMediaSubmitting(false);
    } catch (e: any) {
      setIsMediaSubmitting(false);

      if (e?.response) {
        notification.open({
          message: `FIle Upload Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  }

  const handleDeleteVideo = async (type: string) => {
    setIsMediaSubmitting(false);
    setIsMediaDeleting(true);

    try {
      await deletePostMediaUrl(mediaId?.id);
      notification.open({
        message: `Success`,
        description: "Media deleted successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });

      setMediaId({
        publicURL: "",
        postUUID: "",
        id: null,
      });
      setIsMediaDeleting(false);
    } catch (e: any) {
      setIsMediaDeleting(false);

      if (e?.response) {
        notification.open({
          message: `File Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleFormSubmit = async (values: Record<string, any>) => {
    if (!mediaId?.postUUID) {
      notification.open({
        message: `Error`,
        description: "Please upload a video or image for this post",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }

    setIsSubmitting(true);

    const payload = {
      ...values,
      uuid: mediaId?.postUUID,
    };

    try {
      const postResult = (await state?.id)
        ? updateSinglePost(state?.id, payload)
        : createPost(payload);
      setIsSubmitting(false);
      navigate("/posts");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };
  return (
    <DashboardLayout>
      <UploadPostWrap vertical>
        <Flex justify="space-between">
          <h1 className="newsupload__header">Upload Post</h1>

          <div className="close__route" onClick={() => navigate(-1)}>
            <AiOutlineClose size={25} color="#9F9F9F" />
          </div>
        </Flex>

        {state?.type === "Edit" && postLoading ? (
          <LohliLoader />
        ) : (
          <Form form={form} onFinish={handleFormSubmit}>
            <Flex vertical align="flex-start" className="form__subwrap">
              <label>Title</label>
              <TextField
                validator={validateBasicField}
                placeholder="Enter Title"
                name="title"
              />{" "}
              <label>Body</label>
              <TextField
                validator={validateBasicField}
                placeholder="Enter Body"
                name="body"
                textarea
              />{" "}
              {!!mediaId?.id || isMediaSubmitting ? (
                <Flex
                  className="upload__field fieldwith__content"
                  vertical
                  align="center"
                  justify="center"
                >
                  <Flex vertical align="start">
                    <label>Media Content</label>
                    {isMediaSubmitting ? (
                      <ProgressBar width="80%" hasPercentageValue />
                    ) : (
                      <Flex className="video__wrap" vertical>
                        {validateVideo(mediaId?.publicURL || "") ? (
                          <ReactPlayer
                            url={mediaId?.publicURL}
                            width={"100%"}
                            height={"100%"}
                            controls
                          />
                        ) : (
                          <img
                            src={mediaId?.publicURL}
                            width={"100%"}
                            height={"100%"}
                          />
                        )}
                      </Flex>
                    )}

                    <Flex justify="flex-end" className="delete__pause__wrap">
                      <span
                        className="delete__file"
                        onClick={() => handleDeleteVideo("image")}
                      >
                        {isMediaDeleting ? <FiLoader /> : <RiDeleteBin6Line />}{" "}
                        Delete
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  className={`upload__field`}
                  vertical
                  align="center"
                  justify="center"
                >
                  <Flex justify="center" className="upload__icon">
                    <FiUploadCloud size={20} />
                  </Flex>
                  <span className="upload__text">
                    <input
                      hidden
                      type="file"
                      id="upload__video"
                      onChange={(e) => handleFileUpload(e, null)}
                    />
                    <label className="click__touploap" htmlFor="upload__video">
                      Click to upload
                    </label>{" "}
                    or drag and drop
                  </span>
                  <span className="max__size">
                    mp4 , svg, jpg, jpeg, png(max-size:500mb)
                  </span>
                </Flex>
              )}
              {/* <Flex className="editor__wrap">
                <Editor
                  onEditorChange={handleEditorChange}
                  initialValue=""
                  apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",

                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  value={editorValue}
                />
              </Flex> */}
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  htmlType="submit"
                  width="10rem"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </LohButton>
                <LohButton
                  htmlType="submit"
                  width="10rem"
                  loading={isSubmitting}
                >
                  Publish
                </LohButton>
              </Flex>
            </Flex>
          </Form>
        )}
      </UploadPostWrap>
    </DashboardLayout>
  );
};

export default UploadPost;
