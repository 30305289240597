import styled from "styled-components";

// export const CustomFormContainer = styled("form")`
export const CustomFormContainer = styled("div")`
  width: 100%;
  margin-top: 20px;

  form {
    justify-content: space-between;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: 950px) {
      .field__wrap {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }

  .form__flexwrap {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .form__buttons {
    width: 100%;
    border-top: 0.1rem solid #e4e7ec;
    margin-top: 1.5rem;
  }
  .datepicker__wrap {
    width: 100%;
    position: relative;
    .ant-picker {
      border-radius: 1.5rem;
    }
  }
  label {
    font-size: 0.85rem;
    margin-bottom: 0.15rem;
    white-space: nowrap;
    position: absolute;
    top: -1.3rem;
  }
`;
