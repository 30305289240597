import { instance } from "./axios";

export const advertiserGetAllNotifications = async () => {
  const { data } = await instance.get("user/notifications");
  return data;
};

export const advertiserReadAllNotifications = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post("user/read-all-notifications", payload);
  return data;
};

export const advertiserReadSingleNotifications = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post(
    "user/read-single-notification",
    payload
  );
  return data;
};
export const advertiserDeleteSingleNotifications = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.delete(
    `user/notifications/${payload.notificationId}`
  );
  return data;
};
