import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryOptions, useInfiniteQuery } from "react-query";

interface IUseInfiniteScrollProps {
  queryKey: UseInfiniteQueryOptions["queryKey"];
  queryFn: UseInfiniteQueryOptions["queryFn"];
}

const useInfiniteScroll = ({ queryFn, queryKey }: IUseInfiniteScrollProps) => {
  const [lastElementRef, inView] = useInView();

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery<any, any>({
      queryKey: queryKey,
      queryFn: queryFn,
      getNextPageParam: (lastPage) => {
        return lastPage && lastPage?.currentPage < lastPage.totalPages
          ? lastPage.currentPage + 1
          : null;
      },
    });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);
  const totalNumberOfData =
    data?.pages?.length && data?.pages[0] && "data" in data?.pages[0]
      ? data?.pages[0]?.totalDocuments
      : 0;

  return {
    data: {
      data: data?.pages?.flatMap((page) =>
        page && "data" in page ? page.data : []
      ),
    },
    total: totalNumberOfData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    refetch,
    lastElementRef,
  };
};

export default useInfiniteScroll;
