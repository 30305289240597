import React, { useState } from "react";
import { SlingTableWrap } from "./slingtable.styles";
import { Flex, Table } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2, FiPause } from "react-icons/fi";
import moment from "moment";
import { formatMoney } from "../../../../utils/function-helpers/string-functions";

interface IProps {
  tableData: any;
  maxWidth?: string;
  setSelectedData?: (values: Record<string, any>) => void;
  func?: (values: Record<string, any>) => void;
  onPaginationChange?: (page: number) => void;
  total?: number;
  isLoading?: boolean;
}

const SlingTable: React.FC<IProps> = ({
  tableData,
  onPaginationChange,
  total,
  isLoading,
}) => {
  const webColumns = [
    {
      title: <p className="th__titles"></p>,
      dataIndex: "views",
      key: "views",
      render: (arr: any, sling: any) => (
        <Flex
          className={`tablerow__wrap ${
            sling.type.toLowerCase() === "credit" && "tablerow__wrap__credited"
          }`}
          justify="space-between"
          align="center"
        >
          <Flex vertical className="table__wrapleft" align="start">
            <h3 className="sling__header">{sling.type}</h3>
            <p className="sling__description">
              {sling.type === "DEBIT"
                ? `${formatMoney(
                    sling.amount,
                    true
                  )} Shekels has been deducted from ${sling.source}`
                : `${sling.amount} Shekels has been credited `}
            </p>
            {sling.description && (
              <span className="sling__description__sub">
                {sling.description}
              </span>
            )}
          </Flex>
          <span className="sling__time">
            {moment(sling.createdAt).fromNow()}
          </span>
        </Flex>
      ),
    },
  ];
  return (
    <SlingTableWrap>
      {tableData?.length === 0 ? (
        <Flex className="nodata__wrap" vertical align="center" justify="center">
          <p className="noads__headtitle">No Transactions Yet</p>
          <p className="noads__subtitle">
            Increase Transactions By Funding Wallet
          </p>
        </Flex>
      ) : (
        <Flex className="web__table">
          <Table
            dataSource={tableData}
            columns={webColumns}
            scroll={{ x: "100" }}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              total: total,
              onChange: (page) => {
                onPaginationChange?.(page);
              },
            }}
            loading={isLoading}
          />
        </Flex>
      )}
    </SlingTableWrap>
  );
};
export default SlingTable;
