import React, { useState, useEffect } from "react";
import { Flex, notification } from "antd";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import { useNavigate } from "react-router";
import VerifyIcon from "../../../assets/images/auth/verification.png";
import { VerificationsWrap } from "../../../components/layouts/AuthLayout/verification.styles";
import { LohButton } from "../../../components/lib/Button";
import { IoCloseCircleSharp as Close } from "react-icons/io5";
import {
  loginAdvertiser,
  resendAdvertiserVerificationToken,
  verifyAdvertiser,
} from "../../../network/auth";
import { getMachineId } from "../../../utils/function-helpers/form-validators";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";

const Authenticating = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [accessType] = useState<any>(
    JSON.parse(String(localStorage.getItem("signup-details")))
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    handleVerifyAdvertiser();
  }, [accessType]);

  const handleVerifyAdvertiser = async () => {
    const emailPayload = {
      email: searchParams.get("email"),
      token: searchParams.get("token"),
    };
    const phoneNumberPayload = {
      phoneNumber: searchParams.get("phoneNumber"),
      token: searchParams.get("token"),
    };

    try {
      const response = await verifyAdvertiser(
        accessType?.type === "EMAIL" ? emailPayload : phoneNumberPayload
      );

      sessionStorage.setItem(
        "advertiser-tokens",
        JSON.stringify(response?.data?.tokens)
      );

      if (!!response?.data?.user?.updatedProfile) {
        window.location.href = "/";
      } else {
        window.location.href = "/advertiser-type";
      }
    } catch (error: any) {
      setError(true);
      setErrorMessage(error.response.data.message);
    }
  };

  const handleResendVerifyAdvertiserToken = async (
    values: Record<string, any>
  ) => {
    setIsSubmitting(true);

    const signupResendPayload = {
      phoneNumber: accessType?.phoneNumber || "",
      email: accessType?.email || "",
      notificationMedium: accessType?.type,
      deviceId: getMachineId(),
      redirectUrl: `${window.location.origin}/authenticating`,
    };

    const loginResendPayload = {
      email: accessType?.email || "",
      phoneNumber: accessType?.phoneNumber || "",
      redirectUrl: `${window.location.origin}/authenticating`,
    };

    try {
      (await accessType.from) === "signup"
        ? resendAdvertiserVerificationToken(signupResendPayload)
        : loginAdvertiser(loginResendPayload);
      setIsSubmitting(false);
      navigate("/verify-credentials");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Signup Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <VerificationsWrap>
      <Flex className="verification__main" vertical align="start">
        <div className="verification__logo__wrap">
          <img src={LohliLogo} alt="" />
        </div>
        <Flex className="verification__body__main" justify="center">
          {!!error ? (
            <Flex className="verification__body__sub" vertical align="center">
              <Flex className="verification__icon__wrap" justify="center">
                <Close color="#E33F3F" />
              </Flex>

              <h2>Authenticating...</h2>
              <span className="verification__subheader verification__failed__subheader">
                {errorMessage ||
                  " The magic link you received has expired. Don't worry; you can request a new link."}
              </span>
              <LohButton
                onClick={handleResendVerifyAdvertiserToken}
                loading={isSubmitting}
              >
                Resend magic link
              </LohButton>
              <p
                className="changeemail__link"
                onClick={() => navigate("/signup")}
              >
                or change your email
              </p>
            </Flex>
          ) : (
            <Flex className="verification__body__sub" vertical align="center">
              <div className="verification__icon__wrap">
                <img src={VerifyIcon} alt="" />
              </div>

              <h2>Authenticating...</h2>
              <span className="verification__subheader verification__failed__subheader">
                Please wait while your account is being verified
              </span>
              <LohliLoader height="max-content" />
            </Flex>
          )}
        </Flex>

        <span className="copyright__footer">© Lohli 2023</span>
      </Flex>
    </VerificationsWrap>
  );
};

export default Authenticating;
