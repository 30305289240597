import React, { useState } from "react";
import { Flex } from "antd";
import { ReportModalWrap } from "../uploadView.styles";
import { LohButton } from "../../../../components/lib/Button";
import { LohCheckbox } from "../../../../components/lib/Checkbox";

interface IProps {
  showReportModal?: boolean;
  setShowReportModal?: any;
}

const ReportModal: React.FC<IProps> = ({
  showReportModal,
  setShowReportModal,
}) => {
  const closeModal = () => setShowReportModal(false);
  return (
    <ReportModalWrap
      open={showReportModal}
      onCancel={closeModal}
      footer={null}
      closeIcon={false}
      centered
    >
      <Flex vertical align="start">
        <h3>Report</h3>
        <p className="reportmiodal__subtitle">
          Why are you reporting this post?
        </p>
      </Flex>
      <Flex vertical className="checkbox__wrap">
        <LohCheckbox>False information</LohCheckbox>
        <LohCheckbox>Hate speech</LohCheckbox>
        <LohCheckbox>Violence</LohCheckbox>
        <LohCheckbox>Spam</LohCheckbox>
      </Flex>
      <Flex justify="center" gap="1rem" className="buttons__wrap">
        <LohButton
          backgroundColor="transparent"
          borderColor="var(--bluePrimary)"
          color="var(--bluePrimary)"
          htmlType="submit"
          onClick={closeModal}
          width="13rem"
          padding="0"
          height="2.5rem"
        >
          Cancel
        </LohButton>
        <LohButton
          // onClick={handleDeleteAd}
          width="13rem"
          // loading={isSubmitting}
          padding="0"
          height="2.5rem"
        >
          Submit
        </LohButton>
      </Flex>
    </ReportModalWrap>
  );
};
export default ReportModal;
