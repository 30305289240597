import styled from "styled-components";
import { Form } from "antd";

type Custom = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  opacity?: string;
  radius?: string;
  boxShadow?: string;
  fontSize?: string;
  color?: string;
  hoverColor?: string;
  fontWeight?: string;
  hoverBg?: string;
  outlined?: boolean;
  maxWidth?: string;
  textarea?: string;
  hasicon?: boolean;
  status?: any;
  shekels?: boolean;
};

export const FormItem = styled(Form.Item)<Custom>`
  width: 100%;
  height: ${({ height }) => height || "max-content"};
  padding: 0;
  background: ${({ backgroundColor }) => backgroundColor || "transparent"};
  border: ${({ border }) => border || ".08rem solid var(--lightGraySecondary)"};
  border-radius: ${({ radius, textarea }) =>
    textarea ? "1rem" : radius || "2rem"};
  z-index: 1;
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  border-color: ${({ borderColor, status }) =>
    status?.error
      ? "var(--errorPrimary)"
      : borderColor || "var(--lightGraySecondary)"};
  text-align: start;
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;
  margin: 0;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: var(--grayPrimary);
  }

  input,
  textarea {
    width: ${({ status, shekels }) =>
      status.error ? "80%" : shekels ? "60%" : "100%"};
    height: ${({ height }) => height || "max-content"};
    outline: none;
    border: none;
    color: ${({ color }) => color || "var(--blackSecondary)"};
    text-align: start;
    border: none;
    padding: ${({ hasicon }) =>
      hasicon ? "0.7rem 1rem 0.7rem 2rem" : "0.6rem 1rem"};
    font-size: 1rem;
    background: ${({ backgroundColor }) => backgroundColor || "transparent"};

    :hover,
    :active,
    :focus,
    :visited {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }
  }
  textarea {
    text-align: start;
    min-height: 7rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000000;
  }

  .ant-form .ant-form-item {
    .ant-form-item-control-input-content {
      height: ${({ height }) => height || "max-content"};
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
    }
  }
`;

export const TextFieldParentWrap = styled.div<Custom>`
  height: max-content;
  margin: ${({ margin }) => margin || "0 0 1rem 0"};
  width: ${({ width }) => width || "100%"};
  position: relative;
  border-radius: ${({ radius, textarea }) =>
    textarea ? "1rem" : radius || "2rem"};

  .input__icon {
    position: absolute;
    left: 0.7rem;
    top: 0.9rem;
    font-size: 1.1rem;
    z-index: 9;

    svg {
      opacity: 0.4;
    }
  }

  .error__text,
  .character__count,
  .character__at__zero {
    width: 100%;
    text-align: end;
    color: var(--errorPrimary);
    font-size: 0.74rem;
    margin: 0.1rem 0 0 0;
    font-family: "LexendLight", sans-serif;
  }
  .character__count {
    color: var(--lightGrayPrimary);
    font-family: "LexendRegular", sans-serif;
    text-align: start;
    margin: 0.13rem 0 0 0;
  }

  .error__icon {
    position: absolute;
    right: 0.8rem;
    top: 1.2rem;
    font-size: 0.8rem;
    color: var(--errorPrimary);
  }

  .checkbox__wrap {
    position: absolute;
    right: ${({ status }) => (status?.error ? "2rem" : "0.8rem")};
    top: 1rem;
  }

  .shekels__text {
    position: absolute;
    right: 0.8rem;
    top: 0.7rem;
    font-size: 0.8rem;
    margin: 0;
    border-left: 0.1rem solid #e4e7ec;
    padding-left: 0.4rem;
  }

  @media (max-width: 950px) {
    width: 100%;
  }
`;
