// general layout for investor General page

import React, { useState } from "react";
import { Flex, notification } from "antd";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../../utils/function-helpers/form-validators";
import { IoMailSharp as MailIcon } from "react-icons/io5";
import {
  BsEmojiSmileUpsideDownFill,
  BsTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs";
import { LohButton } from "../../../../lib/Button";
import { CustomForm } from "../../../../lib/Form";
import VerifyIcon from "../../../../../assets/images/auth/verification.png";
import LohliLoader from "../../../../lib/PageLoader/pageLoader";
import {
  advertiserConnectWatcher,
  advertiserGetConnectedWatcher,
} from "../../../../../network/watcher";
import { useMutation, useQueryClient } from "react-query";

interface IProps {
  setShowConnectModal?: any;
  setConnectStep?: any;
  connectStep?: string;
  setShowViewConnectSuccessfulPopup?: any;
  onSuccess?: any;
  setWatcherConnectToken: any;
  watcherConnectToken: any;
}

const ConnectWatcherModalBody: React.FC<IProps> = ({
  setShowConnectModal,
  setConnectStep,
  connectStep,
  setShowViewConnectSuccessfulPopup,
  onSuccess,
  setWatcherConnectToken,
  watcherConnectToken,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [watcherEmail, setWatcherEmail] = useState("");
  const [activeInputType, setActiveInputType] = useState({
    name: "email",
    placeholder: "Enter your email address",
    validator: validateEmail,
    icon: <MailIcon />,
    notificationMedium: "EMAIL",
  });

  const queryClient = useQueryClient();

  const {
    data: connectedWatcher,
    isLoading: connectedWatcherLoading,
    mutateAsync: mutateConnectedWatcher,
  } = useMutation({
    mutationFn: advertiserGetConnectedWatcher,
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(["connected-watcher", watcherConnectToken]);
    },
  });

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
      redirectUrl: `${window.location.origin}/`,
    };
    try {
      const result = await advertiserConnectWatcher(payload);
      setIsSubmitting(false);
      setConnectStep("Verification");
      setWatcherEmail(values.email);
      sessionStorage.setItem("connect__watcher__token", result?.data?.token);
      setWatcherConnectToken(result?.data?.token);

      // setTimeout(() => {
      //   setShowConnectModal(false);
      // }, 5000);

      const showpopup = setInterval(async () => {
        try {
          await mutateConnectedWatcher(result?.data?.token);
          clearInterval(showpopup);
        } catch (e) {
          return;
        }
      }, 3000);

      // setTimeout(() => {
      //   setConnectStep("Enter Details");
      //   sessionStorage.removeItem("connect__watcher__token");
      //   clearInterval(showpopup);
      // }, 90000);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Signup Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const formDetails = [
    {
      name: "email",
      placeholder: "Enter your email address",
      validator: validateEmail,
      icon: <MailIcon />,
      notificationMedium: "EMAIL",
      buttonText: "Use Email Address",
    },
    {
      name: "phoneNumber",
      placeholder: "Enter your WhatsApp Number",
      validator: validatePhoneNumber,
      icon: <BsWhatsapp />,
      notificationMedium: "WHATSAPP",
      buttonText: "Use WhatsApp number",
    },
    {
      name: "phoneNumber",
      placeholder: "Enter your Phone Number",
      validator: validatePhoneNumber,
      icon: <BsTelephoneFill />,
      notificationMedium: "SMS",
      buttonText: "Use Phone number",
    },
  ];

  return (
    <>
      {connectStep === "Enter Details" && (
        <Flex vertical className="connect__form__section">
          <Flex
            vertical
            align="start"
            className="connectwatcherform__headersection"
          >
            <h3 className="connectwatcher__header">
              Connect to Watchers Sling{" "}
            </h3>
            <p className="connectwatcher__subheader">Enter login details</p>
          </Flex>
          <CustomForm
            formFinishButtonText="Continue"
            handleSubmit={handleFormSubmit}
            formDetails={[activeInputType]}
            buttonMarginTop="1rem 0 0 0"
            isSubmitting={isSubmitting}
          />

          <Flex className="or__divider" justify="center">
            <p>or</p>
            <div></div>
          </Flex>
          <Flex
            justify="space-between"
            className="formoptions__wrap"
            vertical
            gap="1rem"
          >
            {formDetails.map(
              (item: any, idx: number) =>
                item.notificationMedium !==
                  activeInputType.notificationMedium && (
                  <LohButton
                    width="100%"
                    backgroundColor="transparent"
                    borderColor="var(--lightGraySecondary)"
                    onClick={() => setActiveInputType(item)}
                    color="var(--blackSecondary)"
                  >
                    {item.icon} {item.buttonText}
                  </LohButton>
                )
            )}
          </Flex>
        </Flex>
      )}

      {connectStep === "Verification" && (
        <Flex
          vertical
          className="connect__verification__section"
          align="center"
        >
          <Flex
            vertical
            align="start"
            className="connectwatcherform__headersection"
          >
            <h3 className="connectwatcher__header">Awaiting Verification</h3>
          </Flex>

          <div className="verification__icon__wrap">
            <img src={VerifyIcon} alt="" />
          </div>

          <h3 className="connectwatcher__header">Request Sent</h3>
          <span className="verification__subheader">
            {watcherEmail} has recieved this request, you will be notified once
            the status has changed
          </span>
        </Flex>
      )}
    </>
  );
};

export default ConnectWatcherModalBody;
