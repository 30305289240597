import React from "react";
import { TabsWrap } from "./navTabs.styles";

interface IProps {
  setActiveTab?: any;
  activeTab?: Record<string, any>;
  hasItemCount?: boolean;
  navTabData?: any;
  borderWidth?: string;
  itemWidth?: string;
}

const NavTabs: React.FC<IProps> = ({
  navTabData,
  setActiveTab,
  activeTab,
  hasItemCount,
  borderWidth,
  itemWidth,
}) => {
  return (
    <TabsWrap borderWidth={borderWidth} itemWidth={itemWidth}>
      {navTabData.map((item: any, idx: number) => (
        <p
          key={idx}
          className={`tab__item ${
            activeTab?.title === item.title && "active__tabitem"
          }`}
          onClick={() => setActiveTab(item)}
        >
          {item.title}{" "}
          {hasItemCount && (
            <span className="tab__numbervalue">
              {item?.total || item?.data?.length || 0}
            </span>
          )}
        </p>
      ))}
    </TabsWrap>
  );
};

export default NavTabs;
