import React, { useEffect, useState } from "react";
import { FormItem, TextFieldParentWrap } from "./textfield.styles";
import { BsExclamationCircleFill } from "react-icons/bs";
import {
  onlyBackspaceKey,
  onlyNumberKey,
} from "../../../../utils/function-helpers/form-validators";
import { LohCheckbox } from "../../Checkbox";
import { Flex } from "antd";

interface IProps {
  props?: any;
  placeholder?: string;
  validator?: any;
  textarea?: boolean;
  type?: string;
  name?: string;
  icon?: any;
  totalCharacters?: number;
  radius?: string;
  shekels?: boolean;
  handleChange?: any;
  hasCheckBox?: string;
  handleCheckChange?: any;
  isChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  margin?: string;
  width?: string;
  label?: string;
  readonly?: boolean;
}

type StatusProps = {
  success: string;
  error: string;
};

export const TextField: React.FC<IProps> = (
  {
    placeholder,
    validator,
    type,
    textarea,
    name,
    icon,
    totalCharacters,
    radius,
    shekels,
    handleChange,
    hasCheckBox,
    handleCheckChange,
    isChecked,
    defaultValue,
    disabled,
    margin,
    width,
    label,
    readonly,
  },
  props
) => {
  const [status, setStatus] = useState<StatusProps>();
  const [characterCount, setCharacterCount] = useState(0);

  return (
    <TextFieldParentWrap
      hasicon={!!icon ? true : false}
      status={status}
      margin={margin}
      width={width}
      radius={radius}
    >
      {label && <label>{label}</label>}

      {icon && <div className="input__icon">{icon}</div>}

      <FormItem
        {...props}
        name={name}
        textarea={textarea}
        hasicon={!!icon ? true : false}
        status
        radius={radius}
        rules={[
          {
            validator: (rules, value) =>
              validator &&
              validator(rules, value, setStatus, setCharacterCount),
          },
        ]}
        initialValue={defaultValue}
        backgroundColor={disabled ? "#f2f2f2" : "#fff"}
      >
        {textarea ? (
          <textarea
            readOnly={readonly}
            placeholder={placeholder}
            onChange={(e) => {
              handleChange ||
                (validator &&
                  validator(
                    e,
                    e?.target?.value,
                    setStatus,
                    setCharacterCount
                  ) &&
                  setCharacterCount(e.target.value.length));
            }}
            onBlur={(e) => {
              handleChange ||
                (validator &&
                  validator(
                    e,
                    e?.target?.value,
                    setStatus,
                    setCharacterCount
                  ) &&
                  setCharacterCount(e.target.value.length));
            }}
            onKeyDown={(e) => {
              characterCount === totalCharacters && onlyBackspaceKey(e);
            }}
          />
        ) : (
          <input
            readOnly={readonly}
            onChange={(e) => {
              (handleChange && handleChange(e?.target?.value)) ||
                (validator &&
                  validator(
                    e,
                    e?.target?.value,
                    setStatus,
                    setCharacterCount
                  ) &&
                  setCharacterCount(e.target.value.length));
            }}
            onBlur={(e) => {
              (handleChange && handleChange(e?.target?.value)) ||
                (validator &&
                  validator(
                    e,
                    e?.target?.value,
                    setStatus,
                    setCharacterCount
                  ) &&
                  setCharacterCount(e.target.value.length));
            }}
            placeholder={placeholder}
            type={type || "text"}
            onKeyDown={(e) => {
              name === "phoneNumber"
                ? onlyNumberKey(e)
                : characterCount === totalCharacters && onlyBackspaceKey(e);
            }}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
      </FormItem>
      {hasCheckBox && (
        <Flex className="checkbox__wrap">
          <LohCheckbox
            name={hasCheckBox}
            onChange={handleCheckChange}
            checked={isChecked}
          />
        </Flex>
      )}

      {status?.error && !shekels && (
        <div className="error__icon">
          <BsExclamationCircleFill />
        </div>
      )}

      {totalCharacters && (
        <p
          className={`character__count ${
            totalCharacters - characterCount === 0 && "character__at__zero"
          }`}
        >
          {totalCharacters - characterCount} characters left{" "}
        </p>
      )}
      {status?.error && <p className="error__text">{status.error}</p>}
      {shekels && <p className="shekels__text">Shekels</p>}
    </TextFieldParentWrap>
  );
};
