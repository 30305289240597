import { Flex } from "antd";
import styled from "styled-components";

export const FeedsPageWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;

  .post__header {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }

  .nodata__wrap {
    width: 100%;
    height: 20rem;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }

  .has__coinbg {
    background-position: center -500% !important;
  }

  .feedposts__wrap {
    width: 100%;
    padding: 2rem 0;

    .feedposts__wrapsub {
      padding: 0 12.5rem;
      width: 100%;
      gap: 1.5rem;

      .feedposts__main {
        width: 100%;
        gap: 1rem;
        padding: 1rem;
        border-bottom: 0.1rem solid #e4e4e4;
        transition: all 0.3s ease-in-out;

        .feedpost__video {
          width: 100%;
          height: 22rem;
          border-radius: 0.5rem;
          overflow: hidden;

          img,
          video {
            object-fit: cover;
          }
        }

        .feed__body {
          .feedposter__details {
            gap: 0.5rem;
            cursor: pointer;

            .avatar__wrap {
              width: 3rem;
              height: 3rem;
              border-radius: 100%;
            }

            .feed__user {
              margin: 0.2rem 0.5rem 0 0;
              white-space: nowrap;

              .feed__username {
                text-align: start;
                font-size: 0.75rem;
                color: var(--darkGrayPrimary);
              }
              .feed__userfollowers {
                text-align: start;
                font-size: 0.75rem;
              }
            }
          }

          .feed__title {
            line-height: 2rem;
            width: 80%;
            margin: 0;
          }
          .feed__description {
            color: var(--lightGrayPrimary);
            text-align: start;
            font-size: 0.9rem;

            .see__more {
              font-size: 0.8rem;
              color: var(--bluePrimary);
              cursor: pointer;
            }
          }

          .feedpost__time {
            font-size: 0.7rem;
            color: var(--lightGrayPrimary);
            margin-top: 0.5rem;
          }

          .feed__buttons {
            border-radius: 1rem;
            padding: 0.3rem 1rem;
            background-color: #f2f2f2;
            color: var(--darkGrayPrimary);
            font-size: 0.8rem;
            border: 0.08rem solid #f2f2f2;
            transition: all 0.3s ease;

            svg {
              font-size: 1rem;
              margin: 0 0.1rem -0.2rem 0;
            }
          }
        }

        &:hover {
          background-color: #f2f2f2;
          border-radius: 0.5rem;

          .feed__buttons {
            background-color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 0;

    & > div {
      padding: 0 15px;
    }
    .feedposts__wrap {
      .feedposts__wrapsub {
        padding: 0;
      }
    }
  }
`;
