import React from "react";
import "antd/dist/reset.css";
import "./styles/index.css";
import { AdminProvider } from "./context/advertiserContext/main";
import { QueryClientProvider, QueryClient } from "react-query";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { MainRoutes } from "./routes";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function App() {
  const queryClient = new QueryClient();

  return (
    <React.Fragment>
      <AdminProvider>
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
        </QueryClientProvider>
      </AdminProvider>
    </React.Fragment>
  );
}

export default App;
