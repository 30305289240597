import React, { useState, useEffect } from "react";
import { Flex, notification } from "antd";
import { AuthWrap } from "../../../components/layouts/AuthLayout/registration.styles";
import { CustomForm } from "../../../components/lib/Form";
import { validateEmail } from "../../../utils/function-helpers/form-validators";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import SignupImage from "../../../assets/images/auth/signup-image.gif";
import { IoMailSharp as MailIcon } from "react-icons/io5";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { loginAdvertiser } from "../../../network/auth";

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeInputType] = useState({
    name: "email",
    placeholder: "Enter your email address",
    validator: validateEmail,
    icon: <MailIcon />,
    notificationMedium: "EMAIL",
  });

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("signup-details");
  }, []);

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      email: values?.email || "",
      phoneNumber: values?.phoneNumber || "",
      redirectUrl: `${window.location.origin}/authenticating`,
    };

    try {
      await loginAdvertiser(payload);

      setIsSubmitting(false);

      localStorage.setItem(
        "signup-details",
        JSON.stringify({
          type: activeInputType.notificationMedium,
          phoneNumber: payload.phoneNumber,
          email: payload.email,
          from: "login",
        })
      );
      navigate("/verify-credentials");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Login Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  // const formDetails = [
  //   {
  //     name: "email",
  //     placeholder: "Enter your email address",
  //     validator: validateEmail,
  //     icon: <MailIcon />,
  //     notificationMedium: "EMAIL",
  //     buttonText: "Use Email Address",
  //   },
  // {
  //   name: "phoneNumber",
  //   placeholder: "Enter your WhatsApp Number",
  //   validator: validatePhoneNumber,
  //   icon: <BsWhatsapp />,
  //   notificationMedium: "WHATSAPP",
  //   buttonText: "Use WhatsApp number",
  // },
  // {
  //   name: "phoneNumber",
  //   placeholder: "Enter your Phone Number",
  //   validator: validatePhoneNumber,
  //   icon: <BsTelephoneFill />,
  //   notificationMedium: "SMS",
  //   buttonText: "Use Phone number",
  // },
  // ];

  return (
    <AuthWrap>
      <Flex className="auth__left__section__main" justify="center">
        <Flex className="auth__left__section__sub" vertical align="start">
          <div className="auth__logo__wrap">
            <img src={LohliLogo} alt="" />
          </div>

          <span className="auth__reroute__text">
            Don’t have an account? <a href="/signup">Create an Account</a>
          </span>

          <Flex className="auth__left__section__form__main" justify="center">
            <Flex
              vertical
              align="start"
              className="auth__left__section__form__sub"
            >
              <h1 className="auth__header__text">Login</h1>
              <p className="auth__body__text">
                Welcome back! Enter your details
              </p>
              <CustomForm
                formFinishButtonText="Continue"
                handleSubmit={handleFormSubmit}
                formDetails={[activeInputType]}
                buttonMarginTop="1rem 0 0 0"
                isSubmitting={isSubmitting}
              />
              {/* 
              <Flex className="or__divider login__divider" justify="center">
                <p>or</p>
                <div></div>
              </Flex>
              <Flex justify="space-between" className="formoptions__wrap">
                {formDetails.map(
                  (item: any, idx: number) =>
                    item.notificationMedium !==
                      activeInputType.notificationMedium && (
                      <LohButton
                        width="48%"
                        backgroundColor="transparent"
                        borderColor="var(--lightGraySecondary)"
                        onClick={() => setActiveInputType(item)}
                        color="var(--blackSecondary)"
                      >
                        {item.icon} {item.buttonText}
                      </LohButton>
                    )
                )}
              </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="auth__right__section">
        <img src={SignupImage} alt="" />
      </Flex>
      <span className="copyright__footer">© Lohli 2023</span>
    </AuthWrap>
  );
};

export default Login;
