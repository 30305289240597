import React, { useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateBasicField1500CharactersTextLimit,
  validateBasicFieldNoTextLimit
} from "../../../../utils/function-helpers/form-validators";
import {
  BsEmojiSmileUpsideDownFill,
  BsPersonFill as PersonIcon,
} from "react-icons/bs";
import { MdDescription as About } from "react-icons/md";
import { FaIndustry } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { updateAdvertiserBasicInfo } from "../../../../network/auth";

interface IProps {
  setStep: (values: string) => void;
  setProgressBarWidth: (values: string) => void;
}

const BasicInformation: React.FC<IProps> = ({
  setStep,
  setProgressBarWidth,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const { state } = location;

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
      type: state?.advertiserType,
    };

    try {
      await updateAdvertiserBasicInfo(payload);
      setIsSubmitting(false);
      setStep("Socials");
      setProgressBarWidth("50%");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const formDetails = [
    {
      name: "name",
      placeholder: `${state?.advertiserType.toLowerCase()} name`,
      validator: validateBasicField,
      icon: <PersonIcon />,
    },
    {
      name: "industry",
      placeholder: "Industry",
      validator: validateBasicFieldNoTextLimit,
      type: "select",
      dataSet: [
        "Agriculture",
        "Arts & Entertainment ",
        "Banking and Finance",
        "Faith-Based & Non-Profit",
        "Fast Moving Consumer Goods (FMCG)",
        "Healthcare",
        "Hospitality, Recreation and Travel",
        "Tech & Telecomms",
        "Transportation and Logistics",
        "Real Estate",
      ],
      icon: <FaIndustry />,
    },
    {
      name: "description",
      placeholder: "About (Description)",
      validator: validateBasicField1500CharactersTextLimit,
      type: "textarea",
      icon: <About />,
      totalCharacters: 1500,
    },
  ];

  return (
    <Flex className="userdetails__form__main" vertical align="center">
      <h2>Basic information</h2>
      <span className="userdetails__subheader">
        You will be able to change this later.
      </span>
      <CustomForm
        formDetails={formDetails}
        handleSubmit={handleFormSubmit}
        formFinishButtonText="Continue"
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
      />
    </Flex>
  );
};

export default BasicInformation;
