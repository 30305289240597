export const decodeAnswerAoption = (value: any, index: any) => {
  let answer;
  if (value?.includes("optiona")) {
    answer = "OPTION_ONE";
  }
  if (value?.includes("optionb")) {
    answer = "OPTION_TWO";
  }
  if (value?.includes("optionc")) {
    answer = "OPTION_THREE";
  }
  if (value?.includes("optiond")) {
    answer = "OPTION_FOUR";
  }

  if (value?.includes("ONE")) {
    answer = `optiona${index}`;
  }
  if (value?.includes("TWO")) {
    answer = `optionb${index}`;
  }
  if (value?.includes("THREE")) {
    answer = `optionc${index}`;
  }
  if (value?.includes("FOUR")) {
    answer = `optiond${index}`;
  }
  return answer;
};
