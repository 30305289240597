import { Flex } from "antd";
import styled from "styled-components";

type Custom = {
  height?: string;
  transparentBg?: string;
  color?: string;
  width?: string;
  backgroundColor?: string;
};

export const ProgressBarWrapper = styled(Flex)<Custom>`
  width: 100%;
  gap: 0.3rem;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: ${({ height }) => height || ".5rem"};

  .progressbar__main {
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
    justify-content: flex-start;
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;

    .progressbar__inner {
      height: 100%;
      width: ${({ width }) => width || "0%"};
      background-color: ${({ color }) => color || "var(--bluePrimary)"};
      transition: all 0.3s ease-in-out;
      border-radius: 1rem;
    }
  }
  .progresscount__number {
    font-size: 0.8rem;
    margin: 0;
    line-height: 0.4rem;
  }
`;
