import React from "react";
import { ChartWrap } from "./chart.styles";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import {
  Chart,
  ChartData,
  ChartOptions,
  LayoutPosition,
  registerables,
} from "chart.js";
import { Flex } from "antd";

!!registerables && Chart.register(...registerables);

interface IProps {
  max?: number;
  min?: number;
  stepSize?: number;
  hovLabel?: string;
  options?: ChartOptions<"bar">;
  pieOptions?: ChartOptions<"doughnut">;
  barChartData?: any;
  pieChartData?: any;
  legendPosition?: any;
}

export const LohBarChart: React.FC<IProps> = ({
  barChartData,
  max,
  stepSize,
  min,
  hovLabel,
}) => {
  const data = {
    labels: barChartData?.label,
    datasets: barChartData?.datas?.map((data: any) => {
      return {
        label: data?.hoverLabel || "Data",
        backgroundColor: data.color,
        borderColor: data.color,
        data: data.data,
        borderWidth: 1.5,
        pointBackgroundColor: data.color,
        pointBorderColor: data.color,
        pointBorderWidth: 1.5,
        pointRadius: 3,
        lineTension: 0,
        pointHoverRadius: 3,
        pointHoverBorderColor: data.color,
        pointHoverBorderWidth: 1,
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: 10,
    },
    scales: {
      y: {
        grid: {
          display: false,
          borderColor: "transparent",
        },
        min: min || 0,
        max: max || 100,
        display: false,
        ticks: {
          stepSize: stepSize || 0,
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: "transparent",
        },
        ticks: {
          color: "#525252",
          font: {
            size: 15,
            family: "LexendLight",
          },
        },
      },
    },
  };

  return (
    <ChartWrap>
      <Bar data={data} options={options} />
    </ChartWrap>
  );
};

export const LohPieChart: React.FC<IProps> = ({
  pieChartData,
  legendPosition,
}) => {
  const data = {
    labels: pieChartData?.label,
    datasets: pieChartData?.datas?.map((data: any) => {
      return {
        label: data?.hoverLabel || "Data",
        backgroundColor: data.color,
        borderColor: data.color,
        data: data.data,
        borderWidth: 1.5,
        pointBackgroundColor: data.color,
        pointBorderColor: data.color,
        pointBorderWidth: 1.5,
        pointRadius: 3,
        lineTension: 0,
        pointHoverRadius: 3,
        pointHoverBorderColor: data.color,
        pointHoverBorderWidth: 1,
      };
    }),
  };

  const pieOptions = {
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position:
          (legendPosition as LayoutPosition) || ("bottom" as LayoutPosition),

        labels: {
          usePointStyle: true,
          pointStyleWidth: 5,
          boxHeight: 4,
          padding: 20,
          font: {
            size: 12,
            family: "LexendRegular",
          },
        },
      },
    },
    layout: {
      padding: 10,
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  };

  return (
    <ChartWrap>
      {pieChartData?.datas[0].data[0] < 1 &&
      pieChartData?.datas[0].data[1] < 1 ? (
        <Flex className="piechart__nodata">
          <h3>No Data Yet</h3>
        </Flex>
      ) : (
        <Doughnut data={data} options={pieOptions} />
      )}
    </ChartWrap>
  );
};
