export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number,
  immediate: boolean
) => {
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  return (...args: Parameters<T>) => {
    const context = this;

    clearTimeout(timeout);

    if (immediate && !timeout) {
      func.apply(context, args);
    }

    timeout = setTimeout(() => {
      timeout = undefined;

      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
  };
};
