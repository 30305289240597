import axios from "axios";

let advertiserTokens = JSON.parse(
  String(sessionStorage.getItem("advertiser-tokens")) || ""
);

console.log(process.env.REACT_APP_API_URL);

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${advertiserTokens?.accessToken}`,
  },
});

instance.interceptors.request.use(
  async (config) => {
    if (advertiserTokens?.accessToken) {
      config.headers["Authorization"] =
        `Bearer ${advertiserTokens?.accessToken}` || null; // for Spring Boot back-end
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    // Access Token was expired

    if (
      err?.response?.status === 401 &&
      !originalConfig._retry &&
      !!advertiserTokens?.accessToken
    ) {
      originalConfig._retry = true;

      await getRefreshToken(advertiserTokens?.refreshToken, err);
    } else {
      return Promise.reject(err);
    }
  }
);

const getRefreshToken = async (refreshToken: string, err: any) => {
  try {
    const response = await axios.post(
      `https://devapi.lohli.com//auth/auth/refresh-token`,
      undefined,
      {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      }
    );
    sessionStorage.setItem(
      "advertiser-tokens",
      JSON.stringify(response?.data?.data?.tokens)
    );

    advertiserTokens = response?.data?.data?.tokens;

    return await instance(err.config);
  } catch (_error: any) {
    if (
      _error?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.pathname = "/login";
      sessionStorage.removeItem("advertiser-tokens");
    }
  }
};
