import React, { useEffect, useState } from "react";
import { Flex, Form, notification } from "antd";
import { validateBasicField } from "../../../../../utils/function-helpers/form-validators";
import { TextField } from "../../../../../components/lib/Form";
import { LohButton } from "../../../../../components/lib/Button";
import { MdArrowBack } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  BsChatSquare,
  BsEmojiSmileFill,
  BsEmojiSmileUpsideDownFill,
} from "react-icons/bs";
import { useAdvertiserContext } from "../../../../../context/advertiserContext/main";
import { ActionTypes } from "../../../../../context";
import { createAdvert, updateAdvert } from "../../../../../network/advert";
import { decodeAnswerAoption } from "../../../../../utils/function-helpers/getAnswerOptions";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  setActiveTabeTab?: any;
  activeTableTab: number;
  advert?: Record<string, any>;
  refetch?: any;
}
const Quiz: React.FC<IProps> = ({
  setActiveTabeTab,
  activeTableTab,
  advert,
  refetch,
}) => {
  const {
    dispatch,
    state: { app },
  } = useAdvertiserContext();

  const answerSet: string[] = [];
  app?.currentAdvert?.questions?.map((item: any, idx: number) =>
    answerSet.push(decodeAnswerAoption(item.answer, item.id) || "")
  );
  const [form] = Form.useForm();
  const questionsObj = Form.useWatch([], form);
  const location = useLocation();
  const [action, setAction] = useState("continue");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(answerSet);
  const [serverQuestionIds, setQuestionIds] = useState([-1]);
  const { state } = location;
  const [questionGuide, setShowQuestionGuide] = useState("chat-icon");
  const questionSuggestions = [
    "What product is being advertised? ",
    "What is the name of the brand ad you just watched? ",
    "How much is the product or service being advertised?",
    "Where was the advert shot?",
  ];

  useEffect(() => {
    const answerSet: string[] = [];
    app?.currentAdvert?.questions?.map((item: any, idx: number) =>
      answerSet.push(decodeAnswerAoption(item.answer, item.id) || "")
    );

    setCorrectAnswers(answerSet);
  }, [app]);

  useEffect(() => {
    const questionIdSet: number[] = [];
    advert?.data?.questions?.map((item: any, idx: number) =>
      questionIdSet.push(item.id)
    );
    setQuestionIds(questionIdSet);
  }, [app]);

  const addNewQuestionBlock = () => {
    dispatch({
      type: ActionTypes.CurrentAdvert,
      payload: {
        currentAdvert: {
          ...app.currentAdvert,
          questions: [
            ...app?.currentAdvert?.questions,
            {
              question: "",
              options: ["", "", "", ""],
              answer: "",
              id: uuidv4(),
            },
          ],
        },
      },
    });
  };

  const removeNewQuestionBlock = (questionId: number) => {
    const newQuestions = app?.currentAdvert?.questions.filter(function (
      item: any
    ) {
      return questionId !== item.id;
    });
    dispatch({
      type: ActionTypes.CurrentAdvert,
      payload: {
        currentAdvert: {
          ...app.currentAdvert,
          questions: [...newQuestions],
        },
      },
    });
    if (
      advert?.data?.questions?.length > 0 &&
      advert?.data?.questions?.find((item: any) => item.id === questionId)
    ) {
      dispatch({
        type: ActionTypes.DeletedQuestionIds,
        payload: {
          deletedQuestionIds: [...app.deletedQuestionIds, questionId],
        },
      });
    }
  };

  const handleFormSubmit = async (values: Record<string, any>) => {
    if (
      correctAnswers.length <= app?.currentAdvert?.questions?.length &&
      correctAnswers.includes("")
    ) {
      notification.open({
        message: `Error`,
        description: "Please ensure that every question has a selected answer",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }
    setIsSubmitting(true);

    const payload = [{}];
    const editPayload = [{}];

    app?.currentAdvert?.questions?.map((item: any, idx: number) => {
      const correctAnswer = correctAnswers.filter(function (answer, index) {
        return answer.includes(`${item.id}`);
      });

      payload.push({
        question: values[`question${item.id}`],
        options: [
          values[`optiona${item.id}`],
          values[`optionb${item.id}`],
          values[`optionc${item.id}`],
          values[`optiond${item.id}`],
        ],
        answer: decodeAnswerAoption(correctAnswer[0], 0) || "UNKNOWN",
      });
      if (serverQuestionIds.includes(item.id)) {
        editPayload.push({
          question: values[`question${item.id}`],
          options: [
            values[`optiona${item.id}`],
            values[`optionb${item.id}`],
            values[`optionc${item.id}`],
            values[`optiond${item.id}`],
          ],
          answer: decodeAnswerAoption(correctAnswer[0], 0) || "UNKNOWN",
          id: item.id,
        });
      } else {
        editPayload.push({
          question: values[`question${item.id}`],
          options: [
            values[`optiona${item.id}`],
            values[`optionb${item.id}`],
            values[`optionc${item.id}`],
            values[`optiond${item.id}`],
          ],
          answer: decodeAnswerAoption(correctAnswer[0], 0) || "UNKNOWN",
        });
      }
    });
    payload.shift();
    editPayload.shift();

    const apiQuestionPayload: {
      questions?: any;
      videoUrl?: string;
      thumbnailUrl?: string;
    } = {
      ...app?.currentAdvert,
      questions:
        state?.id && !!app?.currentAdvert?.questions[0].question
          ? editPayload
          : payload,
    };
    delete apiQuestionPayload["videoUrl"];
    delete apiQuestionPayload["thumbnailUrl"];

    const apiQuestionPayloadWithDeletedQuestions: {
      deletedQuestionIds?: any;
    } = {
      ...apiQuestionPayload,
      deletedQuestionIds: app?.deletedQuestionIds,
    };

    try {
      if (action === "draft" && !state?.id) {
        const draftId = await createAdvert(apiQuestionPayload);

        location.state = {
          activity: state?.activity,
          id: draftId?.data?.advert?.id,
        };

        dispatch({
          type: ActionTypes.CurrentAdvert,
          payload: {
            currentAdvert: {
              ...app.currentAdvert,
              questions: [...draftId?.data?.questions],
            },
          },
        });

        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (
        (action === "draft" || state?.activity === "Edit") &&
        state?.id
      ) {
        const draftId = await updateAdvert(
          app?.deletedQuestionIds?.length > 0
            ? apiQuestionPayloadWithDeletedQuestions
            : apiQuestionPayload,
          state.id
        );
        dispatch({
          type: ActionTypes.CurrentAdvert,
          payload: {
            currentAdvert: {
              ...app.currentAdvert,
              questions: [...draftId?.data?.questions],
            },
          },
        });

        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else {
        dispatch({
          type: ActionTypes.CurrentAdvert,
          payload: {
            currentAdvert: {
              ...app.currentAdvert,
              questions: [...editPayload],
            },
          },
        });
        setActiveTabeTab(3);
      }
      refetch();
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleSetAnswer = (value: string, id: number) => {
    const allAnswers = correctAnswers.filter(function (item, index) {
      return item !== value && !item.includes(`${id}`);
    });

    const newCorrectAnswers = [...allAnswers, value];

    setCorrectAnswers(newCorrectAnswers);

    if (questionsObj) {
      const payload: any[] = [];
      app?.currentAdvert?.questions?.forEach((item: any, idx: number) => {
        const correctAnswer = newCorrectAnswers.filter(function (
          answer,
          index
        ) {
          return answer.includes(`${item.id}`);
        });
        payload.push({
          question: questionsObj[`question${item.id}`],
          options: [
            questionsObj[`optiona${item.id}`],
            questionsObj[`optionb${item.id}`],
            questionsObj[`optionc${item.id}`],
            questionsObj[`optiond${item.id}`],
          ],
          answer: decodeAnswerAoption(correctAnswer[0], 0) || "UNKNOWN",
          id: item.id,
        });
      });
      dispatch({
        type: ActionTypes.CurrentAdvert,
        payload: {
          currentAdvert: {
            ...app.currentAdvert,
            questions: [...payload],
          },
        },
      });
    }
  };

  return (
    <Flex vertical>
      <Flex
        className="uploadad__subsection__header"
        vertical
        align="flex-start"
      >
        <h3 className="header__text">Quiz Question</h3>
        <span className="header__description">
          Create specific quiz questions related to the ad to ensure viewers
          comprehend the ad after watching. When watchers engage quiz questions,
          it qualifies them to earn shekels.
        </span>
      </Flex>
      <Form form={form} onFinish={handleFormSubmit}>
        <Flex vertical>
          <Flex justify="center">
            <Flex vertical className="form__sub__wrap" justify="center">
              {app?.currentAdvert?.questions?.map((item: any, idx: number) => (
                <Flex vertical key={idx} className="question__blocks">
                  <Flex justify="space-between" align="flex-end">
                    <label>Question {idx + 1}</label>
                    <label className="rightinformation__label nrightinformation__label__nobg">
                      Note: Please click on the correct answer to confirm your
                      choice for this question.
                    </label>
                  </Flex>
                  <TextField
                    validator={validateBasicField}
                    placeholder="Enter Question"
                    name={`question${item.id}`}
                    defaultValue={item.question}
                  />
                  <TextField
                    validator={validateBasicField}
                    placeholder="A. Enter Answer"
                    name={`optiona${item.id}`}
                    hasCheckBox={`optiona${item.id}`}
                    handleCheckChange={() =>
                      handleSetAnswer(`optiona${item.id}`, item.id)
                    }
                    isChecked={correctAnswers.includes(`optiona${item.id}`)}
                    defaultValue={item.options[0]}
                  />
                  <TextField
                    validator={validateBasicField}
                    placeholder="B. Enter Answer"
                    name={`optionb${item.id}`}
                    hasCheckBox={`optionb${item.id}`}
                    handleCheckChange={() =>
                      handleSetAnswer(`optionb${item.id}`, item.id)
                    }
                    isChecked={correctAnswers.includes(`optionb${item.id}`)}
                    defaultValue={item.options[1]}
                  />
                  <TextField
                    validator={validateBasicField}
                    placeholder="C. Enter Answer"
                    name={`optionc${item.id}`}
                    hasCheckBox={`optionc${item.id}`}
                    handleCheckChange={() =>
                      handleSetAnswer(`optionc${item.id}`, item.id)
                    }
                    isChecked={correctAnswers.includes(`optionc${item.id}`)}
                    defaultValue={item.options[2]}
                  />
                  <TextField
                    validator={validateBasicField}
                    placeholder="D. Enter Answer"
                    name={`optiond${item.id}`}
                    hasCheckBox={`optiond${item.id}`}
                    handleCheckChange={() =>
                      handleSetAnswer(`optiond${item.id}`, item.id)
                    }
                    isChecked={correctAnswers.includes(`optiond${item.id}`)}
                    defaultValue={item.options[3]}
                  />

                  {idx > 0 && (
                    <Flex justify="flex-end" gap="1rem">
                      <LohButton
                        width="14rem"
                        onClick={() => removeNewQuestionBlock(item.id)}
                        margin="0 0 1.5rem 0"
                      >
                        Delete Question
                      </LohButton>
                    </Flex>
                  )}
                </Flex>
              ))}

              <Flex
                className="addquestion__button__wrap"
                justify="flex-start"
                gap="0.5rem"
              >
                <LohButton
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  color="var(--blackSecondary)"
                  width="14rem"
                  onClick={addNewQuestionBlock}
                >
                  Add another Question
                </LohButton>

                {state?.activity !== "Edit" && (
                  <LohButton
                    onClick={() => setActiveTabeTab(3)}
                    backgroundColor="transparent"
                    borderColor="var(--lightGraySecondary)"
                    color="var(--blackSecondary)"
                    width="18rem"
                  >
                    Skip and use default question
                  </LohButton>
                )}
              </Flex>

              <Flex className="questionguide__wrap" vertical align="end">
                <Flex
                  className={`questionguide__suggestionbox ${
                    questionGuide === "suggestion-header"
                      ? "questionguide__suggestionbox__headervidible"
                      : questionGuide === "suggestion-fullbox" &&
                        "questionguide__suggestionbox__bodyvisible"
                  }`}
                  justify="start"
                  align="center"
                  vertical
                >
                  <label
                    className="suggestion__header"
                    onClick={() => setShowQuestionGuide("suggestion-fullbox")}
                  >
                    Question Guide
                  </label>
                  <span className="suggestion__subheader">
                    Choose questions you will like to include in your quiz
                  </span>
                  {questionSuggestions?.map((item, idx) => (
                    <p key={idx} className="suggestion__questions">
                      {item}
                    </p>
                  ))}
                </Flex>

                <Flex
                  className="questionguide__popupicon"
                  justify="center"
                  align="center"
                  onClick={() =>
                    setShowQuestionGuide(
                      questionGuide === "suggestion-fullbox" ||
                        questionGuide === "suggestion-header"
                        ? "chat-icon"
                        : "suggestion-header"
                    )
                  }
                >
                  {" "}
                  <BsChatSquare color="#fff" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {state?.activity === "Edit" ? (
            <Flex
              className="buttonandback__wrap"
              justify="flex-end"
              align="center"
            >
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  onClick={() => setActiveTabeTab(0)}
                >
                  Cancel
                </LohButton>
                <LohButton loading={isSubmitting} htmlType="submit">
                  Save
                </LohButton>
              </Flex>
            </Flex>
          ) : (
            <Flex
              className="buttonandback__wrap"
              justify="space-between"
              align="center"
            >
              <p
                className="goback"
                onClick={() => setActiveTabeTab(activeTableTab - 1)}
              >
                <MdArrowBack /> Go Back
              </p>
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  htmlType="submit"
                  onClick={() => setAction("draft")}
                  loading={isSubmitting}
                >
                  Save to draft
                </LohButton>
                <LohButton
                  onClick={() => setAction("continue")}
                  htmlType="submit"
                >
                  Continue
                </LohButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};

export default Quiz;
