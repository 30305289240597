import React, { useState, useEffect, useRef } from "react";
import { Flex } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateName,
  validatePhoneNumber,
  validateBasicFieldNoTextLimit
} from "../../../../utils/function-helpers/form-validators";
import { BsPersonFill as PersonIcon } from "react-icons/bs";
import { GiSmartphone as Phone } from "react-icons/gi";
import { BsGenderAmbiguous as Gender } from "react-icons/bs";

interface IProps {
  setStep: (values: string) => void;
  setProgressBarWidth: (values: string) => void;
  setProfileDetails: (values: Record<string, any>) => void;
}

const PersonalDetails: React.FC<IProps> = ({
  setStep,
  setProgressBarWidth,
  setProfileDetails,
}) => {
  const handleFormSubmit = (values: Record<string, any>) => {
    setProfileDetails({ ...values });
    setStep("Username");
    setProgressBarWidth("100%");
  };

  const formDetails = [
    {
      name: "firstName",
      placeholder: "First Name",
      validator: validateName,
      icon: <PersonIcon />,
    },
    {
      name: "lastName",
      placeholder: "Last Name",
      validator: validateName,
      icon: <PersonIcon />,
    },

    {
      name: "phoneNumber",
      placeholder: "Phone Number",
      validator: validatePhoneNumber,
      icon: <Phone />,
    },

    {
      name: "gender",
      placeholder: "Gender",
      validator: validateBasicFieldNoTextLimit,
      type: "select",
      dataSet: ["MALE", "FEMALE"],
      icon: <Gender />,
    },
  ];

  return (
    <Flex className="userdetails__form__main" vertical align="center">
      <h2>Enter Profile Details</h2>
      <span className="userdetails__subheader">Fill in the details below</span>
      <CustomForm
        formDetails={formDetails}
        handleSubmit={handleFormSubmit}
        buttonMarginTop="1.5rem 0 0 0"
        formFinishButtonText="Continue"
      />
    </Flex>
  );
};

export default PersonalDetails;
