import React, { useState } from "react";
import { Avatar, Flex, notification } from "antd";
import { useNavigate } from "react-router-dom";
import PostAvatar from "../../../assets/images/dashboard/feed-avatar.png";
import PostDemoVid from "../../../assets/images/dashboard/feed-image.png";
import { IoHeartOutline } from "react-icons/io5";
import { RiChat3Line } from "react-icons/ri";
import { DeleteModal, PostCardWrap } from "./postCard.styles";
import { FiBarChart2, FiPlay } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { LohButton } from "../Button";
import moment from "moment";
import { truncateString } from "../../../utils/function-helpers/string-functions";
import { deletePost } from "../../../network/posts";
import {
  BsEmojiSmileFill,
  BsEmojiSmileUpsideDownFill,
  BsHeartFill,
} from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa6";
import { validateVideo } from "../../../utils/function-helpers/form-validators";
import LohliAvatar from "../Avatar/avatar";

interface IProps {
  data: any;
  type?: string;
  activepostTab?: any;
  uploadViewType?: string;
}

const PostCard: React.FC<IProps> = ({
  data,
  type,
  activepostTab,
  uploadViewType,
}) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const handleDeletePost = async () => {
    setIsSubmitting(true);

    try {
      const deleteResult = await deletePost(data?.post?.id);
      activepostTab.refetch();
      setIsSubmitting(false);
      notification.open({
        message: `Successful`,
        description: "Post Has Been Deleted",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      closeDeleteModal();
    } catch (e: any) {
      setIsSubmitting(false);

      if (e?.response) {
        notification.open({
          message: `Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <PostCardWrap vertical>
      <Flex
        className="postcard__header"
        onClick={() =>
          navigate("/uploads/view", {
            state: { activity: "Edit", id: data?.post?.id, type: "post" },
          })
        }
        justify="space-between"
      >
        <Flex className="post__avatar" justify="center" align="center">
          <LohliAvatar
            imageUrl={data?.post?.user?.profile?.avatar?.publicURL || ""}
          />
        </Flex>
        <Flex className="postcard__header__textwrap" vertical>
          <p className="post__title">{data?.post?.title}</p>
          <span className="post__username">
            {data?.post?.user?.profile?.username}
          </span>
        </Flex>
        <span className="post__date">
          {moment(data?.post?.createdAt).fromNow()}
        </span>
      </Flex>

      <p className="post__description">{data?.post?.body}</p>
      <div
        className="post__video"
        onClick={() =>
          navigate("/uploads/view", {
            state: { activity: "Edit", id: data?.post?.id, type: "post" },
          })
        }
      >
        {!!validateVideo(data?.post?.media[0]?.publicURL || "") ? (
          <ReactPlayer
            url={data?.post?.media[0]?.publicURL}
            width={"100%"}
            height={"100%"}
            // controls
          />
        ) : (
          <img
            src={data?.post?.media[0]?.publicURL}
            width={"100%"}
            height={"100%"}
          />
        )}
        {!!validateVideo(data?.post?.media[0]?.publicURL || "") && (
          <Flex className="play__icon" align="center" justify="center">
            <Flex className="play__icon__main" align="center" justify="center">
              <FaPlay color="#fff" size={25} />
            </Flex>
          </Flex>
        )}
      </div>

      <Flex className="card__footer" justify="space-between">
        <Flex>
          <span className="footer__text">
            <RiChat3Line size="18" /> {data?.post?.metadata?.totalComments || 0}
          </span>
          <span className="footer__text">
            {data?.engagement?.liked ? (
              <BsHeartFill color="var(--errorPrimary)" size="18" />
            ) : (
              <IoHeartOutline size="18" />
            )}{" "}
            {data?.post?.metadata?.totalLikes || 0}
          </span>
        </Flex>
        <Flex>
          {type === "analytics" ? (
            <span
              className="footer__text"
              onClick={() =>
                navigate("/uploads/view", {
                  state: {
                    activity: "Edit",
                    id: data?.post?.id,
                    type: uploadViewType || "post",
                    data: data,
                  },
                })
              }
            >
              <FiBarChart2 size="21" color="var(--bluePrimary)" />
            </span>
          ) : type === "feeds_upload" ? (
            <>
              <span
                className="footer__text"
                onClick={() =>
                  navigate("/posts/upload", {
                    state: {
                      activity: "Edit",
                      id: data?.post?.id,
                      type: "post",
                    },
                  })
                }
              >
                <FiEdit2 size="18" />
              </span>
              <span
                className="footer__text"
                onClick={() => setShowDeleteModal(true)}
              >
                <RiDeleteBinLine size="18" />
              </span>
            </>
          ) : null}
        </Flex>
      </Flex>

      <DeleteModal
        open={showDeleteModal}
        onCancel={closeDeleteModal}
        footer={null}
        closeIcon={false}
        centered
      >
        <Flex vertical align="start">
          <h3>Delete Post</h3>
          <p>You are about to delete this post, are you sure?</p>
        </Flex>

        <Flex justify="center" gap="1rem" className="buttons__wrap">
          <LohButton
            backgroundColor="transparent"
            borderColor="var(--bluePrimary)"
            color="var(--bluePrimary)"
            htmlType="submit"
            onClick={closeDeleteModal}
            width="13rem"
            padding="0"
            height="2.5rem"
          >
            No! Cancel
          </LohButton>
          <LohButton
            onClick={handleDeletePost}
            width="13rem"
            loading={isSubmitting}
            padding="0"
            height="2.5rem"
          >
            Yes! Sure
          </LohButton>
        </Flex>
      </DeleteModal>
    </PostCardWrap>
  );
};

export default PostCard;
