import { instance } from "./axios";

export const getLatestVideoPerformance = async () => {
  const { data } = await instance.get(
    `advertiser-metrics/latest-video-performance`
  );
  return data;
};

export const getAllStats = async (
  location?: string,
  from?: string,
  to?: string
) => {
  const { data } = await instance.get(
    `advertiser-metrics/stat?location=${location}&from=${from}&to=${to}`
  );
  return data;
};

export const getTopViewersLocations = async (
  location?: string,
  from?: string,
  to?: string
) => {
  const { data } = await instance.get(
    `advertiser-metrics/top-viewers-location?location=${location}&from=${from}&to=${to}`
  );
  return data;
};

export const getTopWatchersAgeGender = async (
  location?: string,
  from?: string,
  to?: string
) => {
  const { data } = await instance.get(
    `advertiser-metrics/top-watchers-age-gender-distribution?location=${location}&startDate=${from}&endDate=${to}`
  );
  return data;
};

export const getTopPerformingAds = async (userId: number) => {
  const { data } = await instance.get(
    `advertisement-metrics/top-performing-ads?status=PUBLISHED&userId=${userId}`
  );
  return data;
};
