import React, { useState } from "react";
import { Flex, Pagination } from "antd";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { LohButton } from "../../../../components/lib/Button";
import { LohDatepicker } from "../../../../components/lib/Form/Datepicker/datepicker";
import { BsDownload } from "react-icons/bs";
import { UploadAnalyticsOverviewWrap } from "./uploadsOverviewAnalytics.styles";
import { getAllAdverts } from "../../../../network/advert";
import { useQuery } from "react-query";
import NavTabs from "../../../../components/lib/Tabs/navTabs";
import LohTable from "../../../../components/lib/AdsTable/adsTable";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import PostCard from "../../../../components/lib/PostCard/postCard";
import { getAllPosts } from "../../../../network/posts";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import moment from "moment";

const UploadAnalyticsOverview = () => {
  const [activeTableTab, setActiveTabeTab] = useState({
    title: "Uploaded Ads",
    data: [],
    noDataState: "",
  });
  const navigate = useNavigate();
  const [date, setDate] = useState<any>("");

  const {
    state: { user },
  } = useAdvertiserContext();
  const [postPage, setPostPage] = useState(1);

  const {
    data: publishedAds,
    isLoading,
    refetch: refetchAds,
    isRefetching,
  } = useQuery(["published", user?.id], () =>
    getAllAdverts("PUBLISHED", user?.id, date)
  );

  const { data: publishedPosts, refetch: refetchPosts } = useQuery(
    ["published", user?.id, postPage],
    () => getAllPosts("ACTIVE", user?.id, postPage, date)
  );

  const tableTabs = [
    {
      title: "Uploaded Ads",
      data: publishedAds?.data,
      noDataState: "No ads have been uploaded",
    },
    {
      title: "Uploaded Posts",
      data: publishedPosts?.data,
      noDataState: "",
    },
  ];

  const handleDataPagination = async (page: number) => {
    try {
      setPostPage(page);
      const response = await getAllPosts("ACTIVE", user?.id, page);
      setActiveTabeTab({
        ...activeTableTab,
        data: response?.data,
      });

      window.scrollTo(0, 0);
    } catch (e) {}
  };

  return (
    <DashboardLayout>
      <UploadAnalyticsOverviewWrap vertical>
        <Flex justify="space-between">
          <h1 className="metrics__header">Engagement Metrics - Your Uploads</h1>
        </Flex>

        <Flex justify="space-between" className="filter__exportbutton__wrap">
          <Flex className="filter__wrap">
            <LohDatepicker
              onChange={async (e: any) => {
                const newDate = new Date(e).toISOString();
                setDate(newDate);

                await refetchAds();
                await refetchPosts();
              }}
            />
          </Flex>

          <LohButton
            backgroundColor="transparent"
            borderColor="var(--bluePrimary)"
            color="var(--bluePrimary)"
            width="10rem"
            padding=".5rem 0"
            svgOpacity="1"
          >
            <BsDownload color="var(--bluePrimary)" /> Export
          </LohButton>
        </Flex>

        <NavTabs
          setActiveTab={setActiveTabeTab}
          activeTab={activeTableTab}
          navTabData={tableTabs}
          hasItemCount
        />

        {activeTableTab.title === "Uploaded Ads" &&
          (isLoading || isRefetching ? (
            <LohliLoader />
          ) : (
            <LohTable
              tableData={publishedAds?.data}
              activeTableTab={activeTableTab}
              type="analytics"
            />
          ))}

        {activeTableTab.title === "Uploaded Posts" && (
          <>
            {publishedPosts?.data?.length > 0 ? (
              <Flex className="postcards__wrap">
                {publishedPosts?.data?.map(
                  (item: Record<string, any>, idx: number) => (
                    <Flex className="card__wrap" key={idx}>
                      <PostCard type="analytics" data={item} />
                    </Flex>
                  )
                )}
              </Flex>
            ) : (
              <Flex
                className="nodata__wrap"
                vertical
                align="center"
                justify="center"
              >
                <h3 className="noads__headtitle">
                  {" "}
                  No News / Articles has been uploaded
                </h3>
                <p className="noads__subtitle">
                  Increase Visibility and Engagement Through Your posts
                </p>
                <LohButton
                  svgOpacity="1"
                  width="10rem"
                  onClick={() => navigate("/post/upload")}
                >
                  <FaPlus color="#fff" /> Upload
                </LohButton>
              </Flex>
            )}
          </>
        )}

        {activeTableTab.title === "Uploaded Posts" && (
          <Flex justify="flex-end">
            <Pagination
              defaultCurrent={1}
              total={publishedPosts?.totalDocuments}
              pageSize={18}
              onChange={(e) => handleDataPagination(e)}
              current={postPage}
            />
          </Flex>
        )}
      </UploadAnalyticsOverviewWrap>
    </DashboardLayout>
  );
};

export default UploadAnalyticsOverview;
