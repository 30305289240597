// general layout for investor General page

import React from "react";
import { FooterWrap } from "./footer.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";
import { BsPlusSquare } from "react-icons/bs";
import { Dropdown, Flex } from "antd";
import { FiLayers } from "react-icons/fi";
import { IoTrendingUp } from "react-icons/io5";
import { PiNewspaperLight } from "react-icons/pi";

interface IProps {}

const Footer: React.FC<IProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const uploadItems = [
    {
      key: "1",
      label: (
        <a rel="noopener noreferrer" href="/ads" className="dropdown__items">
          Ads Upload
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a rel="noopener noreferrer" href="/posts" className="dropdown__items">
          Posts Uploads
        </a>
      ),
    },
  ];
  const analyticsItems = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href="/analytics/overview"
          className="dropdown__items"
        >
          Overview
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          rel="noopener noreferrer"
          href="/analytics/your-uploads"
          className="dropdown__items"
        >
          Your Uploads
        </a>
      ),
    },
  ];

  const menuItems = [
    {
      navTitle: "Home",
      navRoute: "/",
      icon: <MdHomeFilled size={25} />,
    },
    {
      navTitle: "Feeds",
      navRoute: "/feeds/overview",
      icon: <PiNewspaperLight size={25} />,
    },
    {
      navTitle: "Analytics",
      subroutes: ["/analytics/overview", "/analytics/your-uploads"],
      doprdownItems: true,
      type: "analytics",
      icon: <IoTrendingUp size={25} />,
    },
    {
      navTitle: "Uploads",
      subroutes: ["/posts", "/ads"],
      doprdownItems: true,
      icon: <BsPlusSquare size={20} className="upload__icon" />,
    },
    {
      navTitle: "Sling",
      navRoute: "/sling",
      icon: <FiLayers size={25} />,
    },
  ];

  const uploadMenuProps = {
    items: uploadItems,
  };

  const analyticsMenuProps = {
    items: analyticsItems,
  };
  return (
    <FooterWrap>
      <Flex
        className="footernav__items__wrap"
        justify="space-between"
        align="flex-end"
      >
        {menuItems.map((item: Record<string, any>, index: number) =>
          !item.doprdownItems ? (
            <Flex
              className={`footernav__item ${
                location.pathname === item.navRoute && "active__footernav__item"
              }`}
              key={index}
              vertical
              align="center"
              justify="flex-end"
            >
              {item.icon}
              <p
                onClick={() => navigate(item.navRoute)}
                className="footer__navitem__text"
              >
                {item.navTitle}
              </p>
            </Flex>
          ) : (
            <Dropdown
              menu={
                item.type === "analytics" ? analyticsMenuProps : uploadMenuProps
              }
              placement="top"
            >
              <a>
                <Flex
                  className={`footernav__item ${
                    location.pathname === item.navRoute ||
                    (item?.subroutes?.includes(location.pathname) &&
                      "active__footernav__item")
                  }`}
                  key={index}
                  vertical
                  align="center"
                  justify="flex-end"
                >
                  {item.icon}
                  <p className="footer__navitem__text">{item.navTitle}</p>
                </Flex>
              </a>
            </Dropdown>
          )
        )}
      </Flex>
    </FooterWrap>
  );
};

export default Footer;
