import { Flex, Modal } from "antd";
import styled from "styled-components";

export const SearchWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  gap: 1.5rem;

  .search__header {
    font-size: 2rem;
  }

  .close__route {
    cursor: pointer;
  }
  .suggested_avatar,
  .suggested__usertitle {
    cursor: pointer;
  }

  .search__wrap {
    .ant-form-item {
      height: 2.8rem;
      ::placeholder {
        font-size: 0.9rem;
      }
      input {
        font-size: 0.9rem;
      }
    }

    .cancel {
      color: var(--bluePrimary);
      font-size: 0.9rem;
      margin: 0;
      border-bottom: 0.1rem solid var(--bluePrimary);
      height: max-content;
      cursor: pointer;
    }
  }

  .body__wrap {
    gap: 0.5rem;
    .body__titletext {
      font-size: 0.9rem;
      border-bottom: 0.1rem solid #e4e7ec;
      margin: 0;
      padding-bottom: 0.8rem;

      svg {
        margin: 0 0.25rem -0.25rem 0;
        font-size: 1.2rem;
        opacity: 0.4;
      }
    }

    .body__item {
      border-bottom: 0.1rem solid #e4e7ec;
      padding: 0.2rem 0 0.8rem 0;

      .suggested_avatar {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
      }

      .suggested__usertitle {
        font-size: 0.9rem;
        margin: 0;
        color: var(--blackPrimary);
      }
      .suggested__username {
        font-size: 0.8rem;
        margin: 0;
        color: var(--darkGrayPrimary);
      }

      .recent__text {
        font-size: 0.82rem;
        color: var(--lightGrayPrimary);
        svg {
          margin: 0 0.25rem -0.25rem 0;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;
  }
`;
