import React from "react";
import { AvatarWrap } from "./avatar.styles";
import AvatarImage from "../../../assets/images/dashboard/avatar.svg";
interface IProps {
  imageUrl?: string;
}

const LohliAvatar: React.FC<IProps> = ({ imageUrl }) => {
  return (
    <AvatarWrap>
      <img src={imageUrl || AvatarImage} alt="" />
    </AvatarWrap>
  );
};

export default LohliAvatar;
