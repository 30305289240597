import React, { useEffect, useState } from "react";
import { Flex, Select } from "antd";
import { EngagementMetricsWrap } from "./engagementMetrics.styles";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { LohButton } from "../../../../components/lib/Button";
import SelectField from "../../../../components/lib/Form/SelectFeild/select";
import { validateBasicField } from "../../../../utils/function-helpers/form-validators";
import {
  barChartData,
  pieChartData,
  topViewersLocations,
} from "../../../../utils/data-helpers/ad-metrics";
import { LohDateRangepicker } from "../../../../components/lib/Form/Datepicker/datepicker";
import { BsDownload } from "react-icons/bs";
import ProgressBar from "../../../../components/lib/ProgressBar/progressBar";
import {
  LohBarChart,
  LohPieChart,
} from "../../../../components/lib/Chart/chart";
import { RiChat3Line } from "react-icons/ri";
import { IoHeartOutline } from "react-icons/io5";
import LatestAdDemo from "../../../../assets/images/dashboard/latest-ad-demo.png";
import { states } from "../../../../utils/data-helpers/locationDropdownData";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";
import { useQuery } from "react-query";
import {
  getAllStats,
  getLatestVideoPerformance,
  getTopViewersLocations,
  getTopWatchersAgeGender,
} from "../../../../network/metrics";
import {
  formatMoney,
  truncateString,
} from "../../../../utils/function-helpers/string-functions";
import ReactPlayer from "react-player";
import dayjs from "dayjs";

const EngagementMetrics = () => {
  const [locationFilter, setLocationFilter] = useState("");
  const [dateFilter, setDateFilter] = useState<null | [string, string]>(null);

  const fromDate = dateFilter ? dateFilter[0] : "";
  const toDate = dateFilter ? dateFilter[1] : "";
  const location = locationFilter === "ALL" ? "" : locationFilter;

  const { data: stats, isLoading: statsLoading } = useQuery(
    ["statsanalytics", dateFilter, location],
    () => getAllStats(location, fromDate, toDate)
  );

  const { data: watchersAgeGender, isLoading: watchersAgeGenderLoading } =
    useQuery(["watchersAgeGender", location, dateFilter], () =>
      getTopWatchersAgeGender(location, fromDate, toDate)
    );

  const {
    data: latestVideoPerformance,
    isLoading: latestVideoPerformanceLoading,
  } = useQuery(["latestVideoPerformance"], getLatestVideoPerformance);

  const {
    data: topViewersLocationLocation,
    isLoading: topViewersLocationLoading,
  } = useQuery(["topViewersLocationLocation", location, dateFilter], () =>
    getTopViewersLocations(location, fromDate, toDate)
  );

  const [watchersAgeFormat, setWatchersAgeFormat] = useState({
    label: [""],
    datas: [{}],
  });

  const genderFormat = {
    label: ["Male", "Female"],
    datas: [
      {
        data: [
          [watchersAgeGender?.data?.genderDistribution?.maleCount ?? 0],
          watchersAgeGender?.data?.genderDistribution?.femaleCount ?? 0,
        ],
        color: ["#E5C487", "#0062D6"],
      },
    ],
  };

  useEffect(() => {
    if (watchersAgeGender?.data?.ageDistribution) {
      const count = Object.values(watchersAgeGender?.data?.ageDistribution);
      const ageFormat = {
        label: ["<18", "19-27", "28-59", "60+"],
        datas: [
          {
            data: count,
            color: "#0062D6",
            hoverLabel: "Percentage",
          },
        ],
      };

      setWatchersAgeFormat(ageFormat);
    }
  }, [watchersAgeGender]);

  return (
    <DashboardLayout>
      <EngagementMetricsWrap vertical>
        <Flex justify="space-between">
          <h1 className="metrics__header">Engagement Metrics - Overview</h1>
        </Flex>

        <>
          <Flex justify="space-between" className="filter__exportbutton__wrap">
            <Flex className="filter__wrap">
              <LohDateRangepicker
                value={
                  dateFilter
                    ? [dayjs(dateFilter[0]), dayjs(dateFilter[1])]
                    : undefined
                }
                onChange={(value) => {
                  if (!value) {
                    setDateFilter(null);
                  }
                  if (Array.isArray(value) && value.length === 2) {
                    setDateFilter([
                      dayjs(value[0], "DD/MM/YYYY").toISOString(),
                      dayjs(value[1], "DD/MM/YYYY").toISOString(),
                    ]);
                  }
                }}
              />
              <SelectField
                placeholder="Location"
                name="tribe"
                value={locationFilter}
                validator={validateBasicField}
                radius=".7rem"
                borderColor="#e4e4e4"
                color="var(--blackPrimary)"
                onChange={(value: any) => {
                  console.log(value);
                  setLocationFilter(value);
                }}
              >
                {["ALL", ...states]?.map((value: any, index: number) => (
                  <Select.Option value={value} key={index}>
                    {value}
                  </Select.Option>
                ))}
              </SelectField>
            </Flex>

            {/* <Flex className="export__wrap">
              <LohButton
                backgroundColor="transparent"
                borderColor="var(--bluePrimary)"
                color="var(--bluePrimary)"
                width="10rem"
                padding=".5rem 0"
                svgOpacity="1"
              >
                <BsDownload color="var(--bluePrimary)" /> Export
              </LohButton>
            </Flex> */}
          </Flex>

          {statsLoading ? (
            <LohliLoader />
          ) : (
            <Flex className="overviewsums__wrap">
              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Uploaded Ads</p>
                <h2 className="overviewsums_amount">
                  {stats?.data?.uploadedAds}
                </h2>
              </Flex>

              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Uploaded Posts</p>
                <h2 className="overviewsums_amount">
                  {stats?.data?.uploadedPosts}
                </h2>
              </Flex>
              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Total Followers</p>
                <h2 className="overviewsums_amount">
                  {stats?.data?.totalFollowers}
                </h2>
              </Flex>

              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Ads Views</p>
                <h2 className="overviewsums_amount">
                  {stats?.data?.totalAdsViews}
                </h2>
              </Flex>

              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Shekels Spent on Ads</p>
                <h2 className="overviewsums_amount">
                  ₦
                  {formatMoney(stats?.data?.totalShekels * 200, false) ||
                    "0.00"}
                </h2>
                <h3 className="overviewsums_footertext">
                  ({formatMoney(stats?.data?.totalShekels, false) || "0"} in
                  Shekel)
                </h3>
              </Flex>

              <Flex className="overviewsums_main" vertical>
                <p className="overviewsums_ptext">Watchers /Audience Size</p>
                <h2 className="overviewsums_amount">
                  {stats?.data?.audienceSize}
                </h2>
              </Flex>
            </Flex>
          )}

          {latestVideoPerformanceLoading ? (
            <LohliLoader />
          ) : (
            <Flex
              className="latestad__performance"
              vertical
              align="flex-start"
              gap="1rem"
            >
              <h3 className="latestad__performance__header sections__header">
                Latest Ad Video Performance
              </h3>
              <Flex className="latestad__performance__body">
                <div className="latestad__image">
                  <ReactPlayer
                    url={latestVideoPerformance?.data?.advert?.video?.publicURL}
                    width={"100%"}
                    height={"100%"}
                    controls
                  />
                </div>
                <Flex
                  className="latestad__textsection"
                  vertical
                  align="flex-start"
                >
                  <h3 className="latestad__title sections__header">
                    {truncateString(
                      latestVideoPerformance?.data?.advert?.title || "",
                      70
                    )}
                  </h3>
                  <p className="latestad__descriptiom">
                    {truncateString(
                      latestVideoPerformance?.data?.advert?.description || "",
                      120
                    )}
                  </p>
                  <Flex className="adlikes__comments">
                    <h3 className="likescomment__text">
                      <RiChat3Line />{" "}
                      {latestVideoPerformance?.data?.advert?.stats
                        ?.totalComments || 0}
                    </h3>
                    <h3 className="likescomment__text">
                      <IoHeartOutline />{" "}
                      {latestVideoPerformance?.data?.advert?.stats
                        ?.totalLikes || 0}
                    </h3>
                  </Flex>

                  <h3 className="latestad__footer__metrics">
                    Views:{" "}
                    <span>
                      {latestVideoPerformance?.data?.advert?.stats
                        ?.totalViews || 0}
                    </span>
                  </h3>
                  <h3 className="latestad__footer__metrics">
                    Earners:{" "}
                    <span>
                      {latestVideoPerformance?.data?.advert?.stats
                        ?.totalEarners || 0}
                    </span>
                  </h3>
                  <h3 className="latestad__footer__metrics">
                    Amount of shekels given:{" "}
                    <span>
                      {" "}
                      {latestVideoPerformance?.data?.advert?.stats
                        ?.totalShekelsEarned || 0}{" "}
                      Shekels (₦
                      {formatMoney(
                        latestVideoPerformance?.data?.advert?.stats
                          ?.totalShekelsEarned * 200,
                        false
                      ) || "0.00"}
                      )
                    </span>
                  </h3>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Flex className="viewerslocations__wrap" vertical>
            <h4 className="sections__header">Top Viewers Location</h4>

            <Flex className="viewerslocations__wrapsub" justify="space-between">
              {topViewersLocationLocation?.data?.map(
                (item: Record<string, any>, idx: number) => (
                  <Flex className="viewerslocations__main" vertical key={idx}>
                    <Flex justify="space-between">
                      <p className="location__view">
                        {item.location.map(
                          (location: string, locationIndex: number) => (
                            <>
                              {location}
                              {locationIndex !== item.location.length - 1 &&
                                ","}{" "}
                            </>
                          )
                        )}
                      </p>
                      <p className="location__view">{item.totalViews}</p>
                    </Flex>
                    <ProgressBar
                      width={item.percentage + "%"}
                      backgroundColor="#e4e4e4"
                    />
                  </Flex>
                )
              )}
            </Flex>
          </Flex>

          <Flex className="charts__wrap" justify="space-between">
            <Flex className="barchart__wrap chartwrap__main" vertical>
              <h4 className="sections__header">Watchers Age</h4>
              <LohBarChart barChartData={watchersAgeFormat} />
            </Flex>

            <Flex className="piechart__wrap chartwrap__main" vertical>
              <h4 className="sections__header">Viewers Gender</h4>
              <LohPieChart pieChartData={genderFormat} />
            </Flex>
          </Flex>
        </>
      </EngagementMetricsWrap>
    </DashboardLayout>
  );
};

export default EngagementMetrics;
