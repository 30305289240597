import { instance } from "./axios";
import axios from "axios";

export const createAdvert = async (payload: Record<string, any>) => {
  const { data } = await instance.post("advertisements", payload);
  return data;
};

export const getAllAdverts = async (
  status: string,
  userId: string,
  date?: string
) => {
  const { data } = await instance.get(
    `advertisements?status=${status}&page=1&limit=1000&userId=${userId}&from=${
      date || ""
    }&to=${date || ""}`
  );
  return data;
};

export const getSingleAdvert = async (advertId: number) => {
  const { data } = await instance.get(`advertisements/${advertId}`);
  return data;
};

export const updateAdvert = async (
  payload: Record<string, any>,
  advertId: any
) => {
  const { data } = await instance.patch(`advertisements/${advertId}`, payload);
  return data;
};

export const deleteAdvert = async (advertId: any) => {
  const { data } = await instance.delete(`advertisements/${advertId}`);
  return data;
};
export const deleteMultipleAdverts = async (payload: Record<string, any>) => {
  const { data } = await instance.delete(`advertisements/many`, payload);
  return data;
};

export const publishAdvert = async (advertId: number) => {
  const { data } = await instance.patch(`advertisements/${advertId}/publish`);
  return data;
};

export const unpublishAdvert = async (
  payload: Record<string, any>,
  advertId: string
) => {
  const { data } = await instance.patch(
    `advertisements/${advertId}/unpublish`,
    payload
  );
  return data;
};

export const createAdvertVideoUrl = async (payload: Record<string, any>) => {
  const { data } = await instance.post(
    `advertisements/create-presigned-video-url`,
    payload
  );
  return data;
};

export const createAdvertThumbnailUrl = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post(
    `advertisements/create-presigned-thumnail-url`,
    payload
  );
  return data;
};

export const uploadVideoToS3 = async (url: string, file: any) => {
  const res = await axios({
    method: "put",
    url: url,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  });
  return res.status;
};

export const deleteThumbnailUrl = async (thumbnailId: any) => {
  const { data } = await instance.delete(
    `advertisements/thumbnail/${thumbnailId}`
  );
  return data;
};

export const deleteVideoUrl = async (videoId: any) => {
  const { data } = await instance.delete(`advertisements/video/${videoId}`);
  return data;
};
export const advertiserCommentOnAd = async (
  payload: Record<string, any>,
  advertisementId: number
) => {
  const { data } = await instance.post(
    `advertisement-engagements/${advertisementId}/comment`,
    payload
  );
  return data;
};

export const advertiserReplyCommentOnAd = async (
  payload: Record<string, any>,
  advertisementId: number,
  parentCommentId: number
) => {
  const { data } = await instance.post(
    `advertisement-engagements/${advertisementId}/comment/${parentCommentId}/reply`,
    payload
  );
  return data;
};

export const advertiserGetCommentsOnAd = async (advertisementId: number) => {
  const { data } = await instance.get(
    `advertisement-engagements/${advertisementId}/comments`
  );
  return data;
};

export const advertiserGetCommentRepliesOnAd = async (
  advertisementId: number,
  parentCommentId: number
) => {
  const { data } = await instance.get(
    `advertisement-engagements/${advertisementId}/comments/${parentCommentId}/replies`
  );
  return data;
};

export const advertiserLikeAd = async (
  payload: Record<string, any>,
  advertisementId: number
) => {
  const { data } = await instance.post(
    `advertisement-engagements/${advertisementId}/like`,
    payload
  );
  return data;
};

export const advertiserLikeAdComment = async (
  payload: Record<string, any>,
  commentId: number
) => {
  const { data } = await instance.post(
    `advertisement-engagements/${commentId}/like-comment`,
    payload
  );
  return data;
};

export const advertiserDeleteCommentOnAd = async (
  advertisementId: number,
  commentId: number
) => {
  const { data } = await instance.delete(
    `advertisement-engagements/${advertisementId}/comment/${commentId}`
  );
  return data;
};

export const advertiserDeleteCommentReplyOnAd = async (
  advertisementId: number,
  parentCommentId: number,
  replyId: number
) => {
  const { data } = await instance.delete(
    `advertisement-engagements/${advertisementId}/comment/${parentCommentId}/reply/${replyId}`
  );
  return data;
};
