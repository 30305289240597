import React, { useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateBasicField50CharactersTextLimit,
} from "../../../../utils/function-helpers/form-validators";

import { LohButton } from "../../../../components/lib/Button";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { FaIndustry } from "react-icons/fa6";
import {
  getLoggedInAdvertiser,
  updateAdvertiserBasicInfo,
} from "../../../../network/auth";
import { MdDescription as About } from "react-icons/md";
import { ActionTypes } from "../../../../context";

interface IProps {
  activeForm?: number;
  setActiveForm?: any;
}

const ProfilePageBasicInformation: React.FC<IProps> = ({
  setActiveForm,
  activeForm,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fillFormDetails, setFillFormDetails] = useState({
    name: "",
    description: "",
    industry: "",
    type: "",
  });

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  useEffect(() => {
    setFillFormDetails({
      name: user?.advertiserInfo?.name,
      description: user?.advertiserInfo?.description,
      industry: user?.advertiserInfo?.industry,
      type: user?.advertiserInfo?.type,
    });
  }, [user]);

  const handleBasicInformationSubmit = async (values: Record<string, any>) => {
    const payload = {
      ...values,
    };

    setIsSubmitting(true);
    try {
      await updateAdvertiserBasicInfo(payload);
      const userResult = await getLoggedInAdvertiser();

      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...userResult?.data },
      });
      notification.open({
        message: `Success`,
        description: "Social links updated successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const basicInfoFormDetails = [
    {
      name: "name",
      placeholder: `${user?.advertiserInfo?.type} Name`,
      validator: validateBasicField,
      width: "48.4%",
      label: "Name",
    },
    {
      name: "industry",
      placeholder: "Industry",
      validator: validateBasicField,
      type: "select",
      width: "48.4%",
      label: "Industry",

      dataSet: [
        "Agriculture",
        "Arts & Entertainment ",
        "Banking and Finance",
        "Faith-Based & Non-Profit",
        "Fast Moving Consumer Goods (FMCG)",
        "Healthcare",
        "Hospitality, Recreation and Travel",
        "Tech & Telecomms",
        "Transportation and Logistics",
        "Real Estate",
      ],
      icon: <FaIndustry />,
    },

    {
      name: "type",
      placeholder: "Advertiser Type",
      type: "select",
      validator: validateBasicField,
      dataSet: ["AGENCY", "BRAND"],
      label: "Advertiser Type",
    },

    {
      label: "Bio",
      name: "description",
      placeholder: "About (Description)",
      validator: validateBasicField50CharactersTextLimit,
      type: "textarea",
      icon: <About />,
      totalCharacters: 150,
    },
  ];

  return (
    <Flex className="details__section">
      <Flex className="details__left" vertical>
        <Flex vertical>
          <h3 className="details__title">Basic info</h3>
          <p className="details__subtitle">Write a short information</p>
        </Flex>
        {activeForm !== 1 && (
          <LohButton
            padding="0"
            width="7rem"
            height="2.5rem"
            onClick={() => setActiveForm(1)}
          >
            Edit
          </LohButton>
        )}
      </Flex>
      <CustomForm
        formFinishButtonText="Save Changes"
        handleSubmit={handleBasicInformationSubmit}
        formDetails={basicInfoFormDetails}
        fillFormDetails={fillFormDetails}
        handleCancel={() => setActiveForm(0)}
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
        isFormDisabled={activeForm !== 1}
        gap="1.5rem"
      />
    </Flex>
  );
};

export default ProfilePageBasicInformation;
