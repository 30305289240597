import { Flex } from "antd";
import styled from "styled-components";

export const UploadPostWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;

  .newsupload__header {
    font-size: 1.9rem;
    margin-bottom: 3rem;
  }

  .close__route {
    cursor: pointer;
  }

  .form__subwrap {
    .ant-form-item {
      border-radius: 0.6rem;
      border-color: 0.1rem solid #e4e7ec;
    }

    label {
      font-size: 0.85rem;
      margin-bottom: 0.15rem;
      white-space: nowrap;
    }
    .editor__wrap {
      min-height: 25rem;
      width: 100%;
    }
    .tox-tinymce {
      width: 100%;
    }
    textarea {
      min-height: 22rem;
    }
    .upload__field {
      border: 0.1rem solid #e4e7ec;
      border-radius: 0.6rem;
      height: max-content;
      margin-bottom: 1.5rem;
      width: 100%;
      min-height: 8rem;
      padding: 1rem 0;

      .upload__icon {
        background-color: #e4e7ec;
        padding: 0.7rem;
        border-radius: 100%;
        margin-bottom: 0.7rem;
      }
      .upload__text {
        color: var(--lightGrayPrimary);
        .click__touploap {
          color: var(--bluePrimary);
        }
      }
      .max__size {
        color: var(--lightGrayPrimary);
        margin-top: 0.15rem;
        font-size: 0.7rem;
      }
    }

    .fieldwith__content {
      border: none;
      background-color: #f7f7f7;
      & > div {
        width: 100%;
        padding: 0 1rem;
        gap: 0.6rem;
        .video__wrap {
          width: 100%;
          height: 20rem;
        }

        .delete__pause__wrap {
          width: 100%;
          gap: 0.5rem;
          margin-top: 0.3rem;
          z-index: 6;
          .delete__file {
            color: var(--errorPrimary);
            font-size: 0.8rem;
            cursor: pointer;
            svg {
              margin: 0 0 -0.15rem 0;
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .button__wrap {
      width: 100%;
      border-top: 0.1rem solid #e4e7ec;
      padding-top: 1rem;
      margin-top: 1.5rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;
  }
`;
