import styled from "styled-components";
import { Select, Form } from "antd";

type Custom = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  opacity?: string;
  radius?: string;
  boxShadow?: string;
  fontSize?: string;
  color?: string;
  hoverColor?: string;
  fontWeight?: string;
  hoverBg?: string;
  outlined?: boolean;
  maxWidth?: string;
  hasicon?: boolean;
};

export const LohSelect = styled(Select)<Custom>`
  height: 100%;

  .ant-select-selector {
    height: 100%;
    background: transparent !important;
    border: none !important;
    font-size: 2rem;
    width: 100%;
    box-shadow: none !important;
    padding-top: 5px !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: ${({ color }) => color || "var(--lightGraySecondary)"};
    height: 100%;
    opacity: ${({ opacity }) => opacity || "1"};
    font-family: "LexendRegular", sans-serif;

    @media (max-width: 800px) {
      margin-top: -10px;
    }
    @media (min-width: 1600px) {
      margin-bottom: -1.9rem;
      margin-left: ${({ hasicon }) => (hasicon ? ".5rem" : "0.5rem")};
    }
  }
  .ant-select-selection-item {
    color: ${({ color }) => color || "var(--blackPrimary)"};
  }

  .ant-select-selection-search-input {
    color: ${({ color }) => color || "var(--lightGraySecondary)"};
  }

  .ant-select-arrow {
    svg {
      color: ${({ color }) => color || "var(--lightGraySecondary)"};
      font-size: 1.3rem;
      margin-bottom: -0.3rem;
    }
  }

  input {
    background-color: transparent;
    box-shadow: none;
    height: 100%;
    display: flex;
  }

  button {
    width: max-content;
    height: max-content;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
    :hover,
    :active,
    :focus,
    :visited {
      width: max-content;
      height: max-content;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      box-shadow: none;
      background-color: transparent !important;
    }
    @media (max-width: 800px) {
      width: max-content !important;
      min-width: max-content !important;
    }
  }
`;

export const FormItem = styled(Form.Item)<Custom>`
  min-height: 3rem;
  margin: 0;
  padding: ${({ hasicon }) =>
    hasicon ? ".2rem .3rem .2rem 1.3rem" : ".2rem .3rem"};
  background: ${({ backgroundColor }) => backgroundColor || "transparent"};
  border: ${({ border }) => border || ".08rem solid var(--lightGraySecondary)"};
  border-radius: ${({ radius }) => (radius ? radius : "2rem")};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 1rem;
  border-color: ${({ color, borderColor }) =>
    borderColor ? borderColor : color || "var(--lightGraySecondary)"};
  text-align: left;
  transition: all 0.3s ease;
  outline: none;
  overflow-x: hidden;
  overflow-y: scroll;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: var(--grayPrimary);
  }

  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "max-content"};
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    font-family: "LexendRegular", sans-serif;
  }
`;

export const SelectMainWrap = styled.div<Custom>`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0 0 1rem 0"};
  height: ${({ height }) => height || "max-content"};
  position: relative;

  .select__icon {
    position: absolute;
    left: 0.7rem;
    top: 0.7rem;
    font-size: 1.1rem;
    z-index: 9;
    opacity: 0.4;

    @media (min-width: 1600px) {
      top: 0.9rem;
    }
  }

  .error__text {
    width: 100%;
    text-align: end;
    color: var(--errorPrimary);
    font-size: 0.74rem;
    margin: 3px 0 0 0;
    font-family: "LexendLight", sans-serif;
  }
  @media (max-width: 950px) {
    width: 100%;
  }
`;
