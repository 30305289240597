import styled from "styled-components";
import { Flex } from "antd";

type Custom = {
  progressBarWidth?: string;
};

export const UserDetailsWrap = styled(Flex)<Custom>`
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 2% 0 1% 0;
  align-items: center;

  .userdetails__main {
    width: 100%;
    padding: 0 4%;
    gap: 2rem;
    height: 100%;
    overflow-y: auto;

    .userdetails__logo__wrap {
      width: 7%;
    }

    .copyright__footer {
      color: var(--lightGrayPrimary);
      position: fixed;
      left: 4%;
      bottom: 3%;
    }
    .userdetails__form__main {
      width: 100%;
      margin-top: 2rem;

      .userdetails__form__sub {
        width: 40%;
        min-width: 550px;
        margin-bottom: 2%.5;
        position: relative;
        .advertisertype__wrap {
          width: 100%;
          margin: 2.5rem 0;
          gap: 1rem;
          .advertiser__type {
            border-radius: 1rem;
            border: 1px solid #c8c8c8;
            border-color: #c8c8c8;
            transition: all 0.3s ease-in-out;
            width: 50%;
            padding: 1.2rem 0 0 0;
            cursor: pointer;
            position: relative;

            :hover {
              border-color: var(--bluePrimary);
            }
            .type__logo__wrap {
              width: 6.5rem;
              margin-bottom: 10px;
            }
            .active__pointer {
              position: absolute;
              width: 0.85rem;
              height: 0.85rem;
              border-radius: 100%;
              border: 1px solid #c8c8c8;
              left: 1rem;
              transition: all 0.3s ease-in-out;
            }

            #active__type {
              border: 1px solid var(--bluePrimary);
              transition: all 0.3s ease-in-out;

              div {
                width: 0.3rem;
                height: 0.3rem;
                background-color: var(--bluePrimary);
                transition: all 0.3s ease-in-out;
                border-radius: 100%;
              }
            }
          }
        }
        .progress__bar {
          width: 100%;
          height: 0.3rem;
          background-color: #e4e4e4;
          border-radius: 3.5rem;

          .progress__bar__inner {
            width: ${({ progressBarWidth }) => progressBarWidth};
            transition: all 0.3s ease-in-out;
            height: 100%;
            background-color: var(--bluePrimary);
          }
        }

        .userdetails__subheader {
          margin-bottom: 0.7rem;
          color: var(--lightGrayPrimary);
          text-align: center;
        }

        .skip__text {
          color: var(--bluePrimary);
          text-decoration: underline;
          cursor: pointer;
          position: absolute;
          right: 0;
          font-size: 0.85rem;
        }

        button {
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (min-width: 1800px) {
    .userdetails__main {
      .userdetails__form__main {
        margin-top: 2.1rem;

        .userdetails__form__sub {
          width: 55%;
          min-width: 400px;
        }
      }
    }
  }

  @media (max-width: 950px) {
    .userdetails__main {
      align-items: center;
      padding-top: 40px;
      .userdetails__logo__wrap {
        width: 25%;
      }
      .userdetails__form__main {
        .userdetails__form__sub {
          width: 97%;
          min-width: 97%;
        }
      }
    }
  }
`;
