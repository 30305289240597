import { Flex } from "antd";
import styled from "styled-components";

export const UploadedPostsWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;

  .post__header {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }

  .nodata__wrap {
    width: 100%;
    height: 20rem;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }

  .postcards__wrap {
    flex-wrap: wrap;
    padding-top: 1rem;
    .card__wrap {
      width: 33%;
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .postcards__wrap {
      flex-wrap: wrap;
      padding-top: 1rem;
      justify-content: space-between;
      .card__wrap {
        width: 48%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    padding: 2.5rem 15px;

    .postcards__wrap {
      .card__wrap {
        width: 100%;
      }
    }
  }
`;
