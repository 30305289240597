import React, { useState } from "react";
import { Flex } from "antd";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import { UserDetailsWrap } from "../../../components/layouts/AuthLayout/userdetails.styles";
import { LohButton } from "../../../components/lib/Button";
import { useNavigate } from "react-router";
import BrandLogo from "../../../assets/images/auth/brand-tyoe.png";
import AgencyLogo from "../../../assets/images/auth//agency.png";

const AdvertiserType = () => {
  const navigate = useNavigate();
  const [advertiserTypeSelected, setAdvertiserTypeSelected] = useState("");

  const setAdvertiserType = () => {
    navigate("/user-details", {
      state: { advertiserType: advertiserTypeSelected },
    });
  };

  return (
    <UserDetailsWrap>
      <Flex className="userdetails__main" vertical align="start">
        <div className="userdetails__logo__wrap">
          <img src={LohliLogo} alt="" />
        </div>

        <Flex className="userdetails__form__main" justify="center">
          <Flex className="userdetails__form__sub" vertical>
            <Flex className="userdetails__form__main" vertical align="center">
              <h2>What type of Advertiser are you?</h2>
              <span className="userdetails__subheader">
                Choose the type that you fall under as an advertiser.{" "}
              </span>
              <Flex className="advertisertype__wrap">
                <Flex
                  className="advertiser__type"
                  vertical
                  align="center"
                  onClick={() => setAdvertiserTypeSelected("BRAND")}
                >
                  <Flex
                    className="active__pointer"
                    align="center"
                    justify="center"
                    id={
                      advertiserTypeSelected === "BRAND" ? "active__type" : ""
                    }
                  >
                    <div></div>
                  </Flex>
                  <div className="type__logo__wrap">
                    <img src={BrandLogo} alt="" />
                  </div>
                  <p>A Brand</p>
                </Flex>
                <Flex
                  className="advertiser__type"
                  vertical
                  align="center"
                  onClick={() => setAdvertiserTypeSelected("AGENCY")}
                >
                  <Flex
                    className="active__pointer"
                    align="center"
                    justify="center"
                    id={
                      advertiserTypeSelected === "AGENCY" ? "active__type" : ""
                    }
                  >
                    <div></div>
                  </Flex>
                  <div className="type__logo__wrap">
                    <img src={AgencyLogo} alt="" />
                  </div>
                  <p>An Agency</p>
                </Flex>
              </Flex>
              <LohButton onClick={setAdvertiserType}>Continue</LohButton>
            </Flex>
          </Flex>
        </Flex>

        <span className="copyright__footer">© Lohli 2023</span>
      </Flex>
    </UserDetailsWrap>
  );
};

export default AdvertiserType;
