import React, { useState, useEffect } from "react";
import { Flex, Form, Select, notification } from "antd";
import {
  validateBasicField,
  validateBasicFieldNoTextLimit,
  validateBasicUrl,
  validateDescriptionFields,
  validateImageFile,
  validateVideo,
} from "../../../../../utils/function-helpers/form-validators";
import { TextField } from "../../../../../components/lib/Form";
import { FiLoader, FiPause, FiPlay, FiUploadCloud } from "react-icons/fi";
import { LohButton } from "../../../../../components/lib/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { useAdvertiserContext } from "../../../../../context/advertiserContext/main";
import {
  createAdvert,
  createAdvertThumbnailUrl,
  createAdvertVideoUrl,
  deleteThumbnailUrl,
  deleteVideoUrl,
  updateAdvert,
  uploadVideoToS3,
} from "../../../../../network/advert";
import ProgressBar from "../../../../../components/lib/ProgressBar/progressBar";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ActionTypes } from "../../../../../context";
import { LohCheckbox } from "../../../../../components/lib/Checkbox";
import SelectField from "../../../../../components/lib/Form/SelectFeild/select";
import { states } from "../../../../../utils/data-helpers/locationDropdownData";

interface IProps {
  setActiveTabeTab: any;
  activeTableTab: number;
  refetch?: any;
}
const AdContent: React.FC<IProps> = ({
  setActiveTabeTab,
  activeTableTab,
  refetch,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { state } = location;
  const [action, setAction] = useState("continue");
  const [videoId, setVideoId] = useState({ id: "", publicURL: "" });
  const [thumbnailId, setThumbnailId] = useState({ id: "", publicURL: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVideoSubmitting, setIsVideoSubmitting] = useState(false);
  const [isThumbnailSubmitting, setIsThumbnailSubmitting] = useState(false);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [thumbnailUploadStatus, setThumbnailUploadStatus] = useState("");
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isMediaDeleting, setIsMediaDeleting] = useState("");

  // const [youtubeUrlExists, setYoutubeUrlExists] = useState(true);

  const navigate = useNavigate();
  const {
    dispatch,
    state: { app },
  } = useAdvertiserContext();

  useEffect(() => {
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
  }, []);

  useEffect(() => {
    function fillFormDetails() {
      try {
        // prefill Personal Information form
        form.setFieldsValue({
          title: app?.currentAdvert?.title,
          description: app?.currentAdvert?.description,
          callToActionURL: app?.currentAdvert?.callToActionURL,
          callToActionTitle: app?.currentAdvert?.callToActionTitle,
          locations: app?.currentAdvert?.locations,
          // youtubeVideoURL: app?.currentAdvert?.youtubeVideoURL,
        });
        setVideoId({
          id: app?.currentAdvert?.videoId,
          publicURL: app?.currentAdvert?.videoUrl,
        });
        setThumbnailId({
          id: app?.currentAdvert?.thumbnailId,
          publicURL: app?.currentAdvert?.thumbnailUrl,
        });
        setIsTermsChecked(!!app?.currentAdvert?.title);
      } catch (error) {}
    }
    fillFormDetails();
  }, [app]);

  const readFileUplodProgress = (file: any) => {
    const reader = new FileReader();

    reader.addEventListener("progress", (event) => {
      const percent = Math.round((event.loaded / event.total) * 100);
      if (!validateVideo(file?.name)) {
        setThumbnailUploadProgress(percent);
      } else {
        setVideoUploadProgress(percent);
      }

      if (videoUploadStatus === "pause" || videoUploadStatus === "delete") {
        return;
      }
    });

    reader.readAsText(file);
  };

  async function handleFileUpload(event: any, droppedFile: any) {
    if (!event?.target?.files[0]) {
      return;
    }
    const maxFileLimit = 10048576; // 64kb
    let file = droppedFile || event?.target?.files[0];

    if (!validateVideo(file?.name)) {
      notification.open({
        message: `Upload Failed`,
        description: "Invalid file Type. Please re-try",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }

    setIsVideoSubmitting(true);
    readFileUplodProgress(file);

    try {
      const videoId = await createAdvertVideoUrl({ fileName: file.name });
      await uploadVideoToS3(videoId.data.signedUrl, file);
      setVideoId(videoId?.data);

      notification.open({
        message: `Success`,
        description: "Video uploaded successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsVideoSubmitting(false);
    } catch (e: any) {
      setIsVideoSubmitting(false);

      if (e?.response) {
        notification.open({
          message: `FIle Upload Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  }

  async function handleThumbnailUpload(event: any, droppedFile: any) {
    if (!event?.target?.files[0]) {
      return;
    }
    const maxFileLimit = 1000000; // 64kb
    let file = droppedFile || event?.target?.files[0];

    if (file.size > maxFileLimit) {
      notification.open({
        message: `Upload Failed`,
        description: "Maximum file size is 1mb",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }
    if (!validateImageFile(file?.name)) {
      notification.open({
        message: `Upload Failed`,
        description: "Invalid file Type. Please re-try",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }

    const img = document.createElement("img");

    const objectURL = URL.createObjectURL(file);

    img.src = objectURL;

    img.onload = async function handleLoad() {
      if (img.width < 315 || img.height < 164) {
        return notification.open({
          message: `Upload Failed`,
          description: "Image dimension must not be less than 315/164",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        setIsThumbnailSubmitting(true);
        readFileUplodProgress(file);

        try {
          const thumbnnailId = await createAdvertThumbnailUrl({
            fileName: file.name,
          });
          await uploadVideoToS3(thumbnnailId.data.signedUrl, file);
          setThumbnailId(thumbnnailId?.data);

          notification.open({
            message: `Success`,
            description: "Thumbnail uploaded successfully",
            icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
          });
          setIsThumbnailSubmitting(false);
        } catch (e: any) {
          setIsThumbnailSubmitting(false);

          if (e?.response) {
            notification.open({
              message: `FIle Upload Failed`,
              description: e.response.data.message,
              icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
            });
          } else {
            notification.open({
              message: `Error`,
              description:
                "There was an issue with your network. Pls try again later",
              icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
            });
          }
        }
      }
    };
  }

  const handleDeleteVideo = async (type: string) => {
    setIsMediaDeleting(type);
    try {
      if (type === "video") {
        await deleteVideoUrl(videoId?.id);
        setIsVideoSubmitting(false);
        setVideoId({ id: "", publicURL: "" });
        setVideoUploadStatus("delete");
      } else {
        await deleteThumbnailUrl(thumbnailId?.id);
        setIsThumbnailSubmitting(false);
        setThumbnailId({ id: "", publicURL: "" });
        setThumbnailUploadStatus("delete");
      }

      notification.open({
        message: `Success`,
        description: "Media deleted successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsMediaDeleting("");
    } catch (e: any) {
      setIsMediaDeleting("");

      if (e?.response) {
        notification.open({
          message: `File Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  // onDragLeave sets inDropZone to false
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // onDragOver sets inDropZone to true
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = "copy";
  };

  // onDrop sets inDropZone to false and adds files to fileList
  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);

    // get files from event on the dataTransfer object as an array
    let files = [...e.dataTransfer.files];

    await handleFileUpload(null, files[0]);
  };

  const handleFormSubmit = async (values: Record<string, any>) => {
    if (!videoId?.id) {
      notification.open({
        message: `Upload Failed`,
        description: "Please upload an advert video",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }

    if (!thumbnailId?.id) {
      notification.open({
        message: `Upload Failed`,
        description: "Please upload a thumbnail for the video",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }
    // if (!values?.youtubeVideoUrl && !videoId?.id) {
    //   notification.open({
    //     message: `Upload Failed`,
    //     description: "Please upload an advert video",
    //     icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
    //   });
    //   console.log(values);
    //   return;
    // }
    setIsSubmitting(true);

    const payload: {
      videoId?: any;
      thumbnailId?: any;
      // youtubeVideoUrl?: any;
    } = {
      ...values,
      videoId: videoId?.id,
      thumbnailId: thumbnailId?.id,
    };

    const editPayload: {
      videoUrl?: string;
      videoId?: any;
      thumbnailId?: any;
      thumbnailUrl?: string;
      // youtubeVideoUrl?: any;
    } = {
      ...app?.currentAdvert,
      ...values,
      videoId: videoId?.id,
      thumbnailId: thumbnailId?.id,
    };

    delete editPayload["videoUrl"];
    delete editPayload["thumbnailUrl"];

    // if (!editPayload.videoId) {
    //   delete editPayload["videoId"];
    // }

    // if (!editPayload.youtubeVideoUrl) {
    //   delete editPayload["youtubeVideoUrl"];
    // }

    try {
      if (action === "draft" && !state?.id) {
        const draftId = await createAdvert(payload);

        location.state = {
          activity: state?.activity,
          id: draftId?.data?.advert?.id,
        };

        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (
        (action === "draft" || state?.activity === "Edit") &&
        state?.id
      ) {
        await updateAdvert(editPayload, state.id);
        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else {
        dispatch({
          type: ActionTypes.CurrentAdvert,
          payload: {
            currentAdvert: {
              ...app.currentAdvert,
              ...payload,
              videoUrl: videoId?.publicURL,
              thumbnailUrl: thumbnailId?.publicURL,
            },
          },
        });
        setActiveTabeTab(1);
      }
      refetch();
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  // const validateYoutubeVideoUrl = (
  //   rule: any,
  //   value: string,
  //   setStatus: (value: Record<any, string>) => void
  // ) => {
  //   return new Promise(async (resolve, reject) => {
  //     if (!value) {
  //       setStatus({
  //         success: "success",
  //       });
  //       setYoutubeUrlExists(false);
  //       resolve(value);
  //     } else if (
  //       !/(https?:\/\/)?(www\.)?youtube\.com\/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z\-0-9]+/.test(
  //         value
  //       )
  //     ) {
  //       setStatus({
  //         error: "Invalid youtube url entered",
  //       });
  //       return;
  //     } else {
  //       setYoutubeUrlExists(true);
  //       setStatus({ success: "success" });
  //       resolve(value);
  //     }
  //   });
  // };

  return (
    <Flex vertical>
      <Flex
        className="uploadad__subsection__header"
        vertical
        align="flex-start"
      >
        <h3 className="header__text">Ad Content</h3>
        <span className="header__description">
          Content should meet specified format and size requirements.
        </span>
      </Flex>

      <Form form={form} onFinish={handleFormSubmit}>
        <Flex vertical>
          <Flex justify="center">
            <Flex vertical className="form__sub__wrap">
              <label>Title</label>
              <TextField
                validator={validateBasicField}
                placeholder="Enter Title"
                totalCharacters={250}
                name="title"
              />

              <label>Description</label>
              <TextField
                validator={validateDescriptionFields}
                placeholder="Give a brief, engaging description that communicates what the video is about"
                totalCharacters={2500}
                name="description"
                textarea
              />

              <label>Location</label>
              <SelectField
                placeholder="Select states to view this Ad"
                name="locations"
                validator={validateBasicFieldNoTextLimit}
                mode="multiple"
                valuesArray={states}
                setFieldsValue={form.setFieldsValue}
              >
                {states?.map((value: any, index: number) => (
                  <Select.Option value={value} key={index}>
                    {value}
                  </Select.Option>
                ))}
              </SelectField>

              <Flex justify="space-between" align="flex-end">
                <label>Upload Video</label>
                {/* <label className="rightinformation__label">
                  NB: this is disabled if you enter a youtube url below
                </label> */}
              </Flex>
              {isVideoSubmitting || !!videoId?.id ? (
                <Flex
                  className="upload__field fieldwith__content"
                  vertical
                  align="center"
                  justify="center"
                >
                  <Flex vertical align="start">
                    <label>video content.mp4</label>
                    <ProgressBar
                      width={
                        !videoUploadProgress && !!videoId?.id
                          ? "100%"
                          : videoUploadProgress && !videoId?.id
                          ? videoUploadProgress - 10 + "%"
                          : videoUploadProgress + "%"
                      }
                      hasPercentageValue
                    />
                    <Flex justify="flex-end" className="delete__pause__wrap">
                      <span
                        className="delete__file"
                        onClick={() => handleDeleteVideo("video")}
                      >
                        {isMediaDeleting === "video" ? (
                          <FiLoader />
                        ) : (
                          <RiDeleteBin6Line />
                        )}{" "}
                        Delete
                      </span>
                      {videoUploadProgress < 100 && !videoId && (
                        <>
                          {(videoUploadStatus === "play" ||
                            videoUploadStatus === "") && (
                            <span
                              className="pause_play__file"
                              onClick={() => setVideoUploadStatus("pause")}
                            >
                              <FiPause /> Pause
                            </span>
                          )}
                          {videoUploadStatus === "pause" && (
                            <span
                              className="pause_play__file"
                              onClick={() => setVideoUploadStatus("play")}
                            >
                              <FiPlay /> Play
                            </span>
                          )}{" "}
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  className={`upload__field ${
                    isDraggingOver && "upload__field__dragging"
                  }`}
                  vertical
                  align="center"
                  justify="center"
                  onDragEnter={(e) => handleDragEnter(e)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={(e) => handleDragLeave(e)}
                  onDrop={(e) => handleDrop(e)}
                >
                  <Flex justify="center" className="upload__icon">
                    <FiUploadCloud size={20} />
                  </Flex>
                  <span className="upload__text">
                    <input
                      hidden
                      type="file"
                      id="upload__video"
                      onChange={(e) => handleFileUpload(e, null)}
                    />
                    <label className="click__touploap" htmlFor="upload__video">
                      Click to upload
                    </label>{" "}
                    or drag and drop
                  </span>
                  <span className="max__size">mp4 (max-size:500mb)</span>
                </Flex>
              )}

              <Flex justify="space-between" align="flex-end">
                <label>Upload Thumbnail</label>
              </Flex>
              {isThumbnailSubmitting || !!thumbnailId?.id ? (
                <Flex
                  className="upload__field fieldwith__content"
                  vertical
                  align="center"
                  justify="center"
                >
                  <Flex vertical align="start">
                    <label>thumbnail content.png</label>
                    <ProgressBar
                      width={
                        !thumbnailUploadProgress && !!thumbnailId?.id
                          ? "100%"
                          : thumbnailUploadProgress && !thumbnailId?.id
                          ? thumbnailUploadProgress - 10 + "%"
                          : thumbnailUploadProgress + "%"
                      }
                      hasPercentageValue
                    />
                    <Flex justify="flex-end" className="delete__pause__wrap">
                      <span
                        className="delete__file"
                        onClick={() => handleDeleteVideo("image")}
                      >
                        {isMediaDeleting === "image" ? (
                          <FiLoader />
                        ) : (
                          <RiDeleteBin6Line />
                        )}{" "}
                        Delete
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  className="upload__field"
                  vertical
                  align="center"
                  justify="center"
                >
                  <Flex justify="center" className="upload__icon">
                    <FiUploadCloud size={20} />
                  </Flex>
                  <span className="upload__text">
                    <input
                      hidden
                      type="file"
                      id="upload__thumbnail"
                      onChange={(e) => handleThumbnailUpload(e, null)}
                    />
                    <label
                      className="click__touploap"
                      htmlFor="upload__thumbnail"
                    >
                      Click to upload
                    </label>{" "}
                  </span>
                  <span className="max__size">
                    png, svg, jpg or jpeg (max-size:10mb, dimension: 315/164)
                  </span>
                </Flex>
              )}
              {/* 
              <Flex justify="space-between" align="flex-end">
                <label>Youtube Video Url</label>
                <label className="rightinformation__label">
                  NB: this is disabled if you have uploaded a video above
                </label>
              </Flex>

              <TextField
                validator={validateYoutubeVideoUrl}
                placeholder="Enter youtube video url"
                name="youtubeVideoUrl"
                disabled={!!videoId?.id || isVideoSubmitting}
              /> */}

              <label>Call-to-action Link</label>
              <Flex className="calltoaction__subwrap" gap=".4rem">
                <TextField
                  validator={validateBasicField}
                  placeholder="Enter Link Title"
                  name="callToActionTitle"
                />
                <TextField
                  validator={validateBasicUrl}
                  placeholder="Enter Link"
                  name="callToActionURL"
                />
              </Flex>

              <LohCheckbox
                onChange={(e) => setIsTermsChecked(!isTermsChecked)}
                checked={isTermsChecked}
              >
                <label>
                  Click to accept{" "}
                  <a target="_blank" href="/Lohli- Terms and Conditions.pdf">
                    Terms & Condition
                  </a>
                </label>
              </LohCheckbox>
            </Flex>
          </Flex>
          {state?.activity === "Edit" ? (
            <Flex
              className="buttonandback__wrap"
              justify="flex-end"
              align="center"
            >
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  onClick={() => setActiveTabeTab(0)}
                >
                  Cancel
                </LohButton>
                <LohButton
                  onClick={() => setAction("edit")}
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={!isTermsChecked}
                >
                  Save
                </LohButton>
              </Flex>
            </Flex>
          ) : (
            <Flex
              className="buttonandback__wrap"
              justify="flex-end"
              align="center"
            >
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  htmlType="submit"
                  onClick={() => setAction("draft")}
                  loading={isSubmitting && action === "draft"}
                  disabled={!isTermsChecked}
                >
                  Save to draft
                </LohButton>
                <LohButton
                  onClick={() => setAction("continue")}
                  htmlType="submit"
                  disabled={!isTermsChecked}
                >
                  Continue
                </LohButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};

export default AdContent;
