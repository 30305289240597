import React, { useEffect, useState } from "react";
import { Flex, Form, notification } from "antd";
import { TextField } from "../../../../../components/lib/Form";
import { LohButton } from "../../../../../components/lib/Button";
import { MdArrowBack } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useAdvertiserContext } from "../../../../../context/advertiserContext/main";
import { createAdvert, updateAdvert } from "../../../../../network/advert";
import { ActionTypes } from "../../../../../context";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { getUserWallet } from "../../../../../network/wallet";
import { useQuery } from "react-query";
import { formatMoney } from "../../../../../utils/function-helpers/string-functions";
import { LohSwitch } from "../../../../../components/lib/Switch";
import { defaultShekelEarn } from "../uploadAd";

interface IProps {
  setActiveTabeTab?: any;
  activeTableTab: number;
  refetch?: any;
}
const AdBudget: React.FC<IProps> = ({
  setActiveTabeTab,
  activeTableTab,
  refetch,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [adBudget, setAdbudget] = useState(0);
  const [action, setAction] = useState("continue");
  const [adAmountPerView, setAmountPerView] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: wallet, isLoading: walletLoading } = useQuery(
    ["walletresult"],
    () => getUserWallet()
  );

  const {
    dispatch,
    state: { app },
  } = useAdvertiserContext();
  const { state } = location;

  const validateAdBudget = (
    rule: any,
    value: string,
    setStatus: (value: Record<any, string>) => void
  ) => {
    return new Promise(async (resolve, reject) => {
      if (!value) {
        setStatus({
          error: "This field is empty. Kindly re-check this entry",
        });
        return;
      } else {
        setStatus({ success: "success" });
        resolve(value);
        if (rule.field === "costPerView") {
          setAmountPerView(
            adAmountPerView === defaultShekelEarn
              ? adAmountPerView
              : Number(value)
          );
        }
        if (rule.field === "maxSpend") {
          setAdbudget(Number(value));
        }
      }
    });
  };

  useEffect(() => {
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          costPerView:
            app?.currentAdvert?.costPerView === defaultShekelEarn
              ? 1
              : app?.currentAdvert?.costPerView,
          maxSpend: app?.currentAdvert?.maxSpend,
        });
        setAdbudget(app?.currentAdvert?.maxSpend || 0);
        setAmountPerView(app?.currentAdvert?.costPerView || 0);
      } catch (error) {}
    }
    fillFormDetails();
  }, [app]);

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload: {
      questions?: any;
      videoUrl?: string;
      costPerView?: number;
      maxSpend?: number;
      thumbnailUrl?: string;
    } = {
      ...app?.currentAdvert,
      costPerView:
        defaultShekelEarn === adAmountPerView
          ? defaultShekelEarn
          : Number(values?.costPerView),
      maxSpend: Number(values?.maxSpend),
    };

    app?.currentAdvert?.questions[0]?.question === "" &&
      delete payload["questions"];
    delete payload["videoUrl"];
    delete payload["thumbnailUrl"];

    try {
      if (action === "draft" && !state?.id) {
        const draftId = await createAdvert(payload);
        location.state = {
          activity: state?.activity,
          id: draftId?.data?.advert?.id,
        };
        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (
        (action === "draft" || state?.activity === "Edit") &&
        state?.id
      ) {
        await updateAdvert(payload, state.id);

        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else {
        dispatch({
          type: ActionTypes.CurrentAdvert,
          payload: {
            currentAdvert: {
              ...app.currentAdvert,
              ...payload,
              // questions: [...],
            },
          },
        });
        setActiveTabeTab(2);
      }
      refetch();
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <Flex vertical>
      <Flex
        className="uploadad__subsection__header "
        vertical
        align="flex-start"
      >
        <h3 className="header__text">Budget</h3>
        <span className="header__description">
          Add / Set Shekels budget for each ad placement
        </span>
      </Flex>

      <Form form={form} onFinish={handleFormSubmit}>
        <Flex vertical>
          <Flex justify="center">
            <Flex vertical className="form__sub__wrap">
              <Flex justify="space-between" align="flex-end">
                <label>Set Ad Budget</label>
                <label className="rightinformation__label">
                  Current Balance:{" "}
                  {formatMoney(Number(wallet?.data?.balance), false) || "0.00"}{" "}
                  <span>
                    shekel{Number(wallet?.data?.balance) > 1 ? "s" : ""}
                  </span>{" "}
                  (Value in Naira: ₦
                  {formatMoney(
                    Number((wallet?.data?.balance ?? 0) * 200),
                    false
                  ) || "0.00"}
                  )
                </label>
              </Flex>
              <TextField
                validator={validateAdBudget}
                placeholder="What is your budget for this Ad?"
                name="maxSpend"
                type="number"
                shekels
              />

              <Flex justify="space-between" align="center">
                <label>Set amount of Shekels a Watcher can earn per view</label>
                <Flex
                  className="uploadad__default__shekels"
                  align="center"
                  gap={8}
                >
                  <label>Set to default (20 views per 1 Shekel)</label>
                  <LohSwitch
                    checked={
                      adAmountPerView === defaultShekelEarn ? true : false
                    }
                    onChange={(checked) => {
                      if (checked) {
                        form?.setFieldsValue({ costPerView: 1 });
                        setAmountPerView(defaultShekelEarn);
                      } else {
                        form?.setFieldsValue({ costPerView: 1 });
                        setAmountPerView(1);
                      }
                    }}
                  />
                </Flex>
              </Flex>
              <TextField
                disabled={adAmountPerView === defaultShekelEarn ? true : false}
                validator={validateAdBudget}
                placeholder="How much will a watchers earn per view?"
                name="costPerView"
                type="number"
                shekels
              />

              <Flex
                className="budget__wrap__box"
                justify="space-between"
                align="center"
              >
                <Flex vertical align="center">
                  <p className="budgetwrap__titletext">
                    Budget Amount in Shekels
                  </p>
                  <h3 className="budgetwrap__amount">{adBudget}</h3>
                  <span className="budgetwrap__footertext">
                    {" "}
                    (₦{formatMoney(adBudget * 200, false) || "0.00"})
                  </span>
                </Flex>
                <Flex vertical align="center">
                  <p className="budgetwrap__titletext">
                    Shekels allocation per watcher{" "}
                  </p>
                  <h3 className="budgetwrap__amount">
                    {adAmountPerView === defaultShekelEarn
                      ? 1
                      : adAmountPerView}
                  </h3>
                  <span className="budgetwrap__footertext">
                    (₦
                    {formatMoney(
                      (adAmountPerView === defaultShekelEarn
                        ? 1
                        : adAmountPerView) * 200,
                      false
                    ) || "0.00"}
                    )
                  </span>
                </Flex>
                <Flex vertical align="center">
                  <p className="budgetwrap__titletext">Projected Views </p>
                  <h3 className="budgetwrap__amount">
                    {Number(
                      adAmountPerView > 0 ? adBudget / adAmountPerView : 0
                    ).toFixed()}
                  </h3>
                  <span className="budgetwrap__footertext">Views</span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {state?.activity === "Edit" ? (
            <Flex
              className="buttonandback__wrap"
              justify="flex-end"
              align="center"
            >
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  onClick={() => setActiveTabeTab(0)}
                >
                  Cancel
                </LohButton>
                <LohButton htmlType="submit" loading={isSubmitting}>
                  Save
                </LohButton>
              </Flex>
            </Flex>
          ) : (
            <Flex
              className="buttonandback__wrap"
              justify="space-between"
              align="center"
            >
              <p
                className="goback"
                onClick={() => setActiveTabeTab(activeTableTab - 1)}
              >
                <MdArrowBack /> Go Back
              </p>
              <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  htmlType="submit"
                  onClick={() => setAction("draft")}
                  loading={isSubmitting && action === "draft"}
                >
                  Save to draft
                </LohButton>
                <LohButton
                  onClick={() => setAction("continue")}
                  htmlType="submit"
                >
                  Continue
                </LohButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};

export default AdBudget;
