import { instance } from "./axios";
import axios from "axios";

export const createPost = async (payload: Record<string, any>) => {
  const { data } = await instance.post("social/posts", payload);
  return data;
};

export const updateSinglePost = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await instance.patch(`social/posts/${id}`, payload);
  return data;
};

export const deletePost = async (id: string) => {
  const { data } = await instance.delete(`social/posts/${id}`);
  return data;
};

export const getSinglePost = async (id: string) => {
  const { data } = await instance.get(`social/posts/${id}`);
  return data;
};

export const getAllPosts = async (
  status: string,
  userId: number,
  page?: number,
  date?: string
) => {
  const { data } = await instance.get(
    `social/posts?status=${status}&userId=${userId}&limit=${
      page ? 18 : 1000
    }&page=${page || 1}&from=${date || ""}&to=${date || ""}`
  );
  return data;
};

export const getFeedPosts = async (
  status: string,
  type: string,
  page: number,
  search?: string
) => {
  const { data } = await instance.get(
    `social/posts?status=${status}&type=${type}&page=${page}&limit=10&search=${search}`
  );
  return data;
};

export const getSinglePostRelevanceScore = async (id: string) => {
  const { data } = await instance.get(
    `social/posts/${id}/calculate-relevance-score`
  );
  return data;
};

export const createPostVideoUrl = async (payload: Record<string, any>) => {
  const { data } = await instance.post(
    `social/posts/create-presigned-media-url`,
    payload
  );
  return data;
};

export const deletePostMediaUrl = async (mediaId: any) => {
  const { data } = await instance.delete(`social/posts/media/${mediaId}`);
  return data;
};

export const advertiserCommentOnPost = async (
  payload: Record<string, any>,
  postId: number
) => {
  const { data } = await instance.post(
    `/social/posts/engagements/${postId}/comment`,
    payload
  );
  return data;
};

export const advertiserReplyCommentOnPost = async (
  payload: Record<string, any>,
  postId: number,
  parentCommentId: number
) => {
  const { data } = await instance.post(
    `/social/posts/engagements/${postId}/comment/${parentCommentId}/reply`,
    payload
  );
  return data;
};

export const advertiserGetCommentsOnPost = async (postId: number) => {
  const { data } = await instance.get(
    `/social/posts/engagements/${postId}/comments`
  );
  return data;
};

export const advertiserGetCommentRepliesOnPost = async (
  postId: number,
  parentCommentId: number
) => {
  const { data } = await instance.get(
    `social/posts/engagements/${postId}/comments/${parentCommentId}/replies`
  );
  return data;
};

export const advertiserLikePost = async (
  payload: Record<string, any>,
  postId: number
) => {
  const { data } = await instance.post(
    `social/posts/engagements/${postId}/like`,
    payload
  );
  return data;
};

export const advertiserLikePostComment = async (
  payload: Record<string, any>,
  commentId: number
) => {
  const { data } = await instance.post(
    `social/posts/engagements/${commentId}/like-comment`,
    payload
  );
  return data;
};

export const advertiserDeleteCommentOnPost = async (
  postId: number,
  commentId: number
) => {
  const { data } = await instance.delete(
    `social/posts/engagements/${postId}/comment/${commentId}`
  );
  return data;
};
export const advertiserDeleteCommentReplyOnPost = async (
  postId: number,
  parentCommentId: number,
  replyId: number
) => {
  const { data } = await instance.delete(
    `/social/posts/engagements/${postId}/comment/${parentCommentId}/reply/${replyId}`
  );
  return data;
};
