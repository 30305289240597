import React, { useState, useEffect, useRef } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import { validateBasicField } from "../../../../utils/function-helpers/form-validators";
import { IoAt as AtIcon } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { updateAdvertiserProfile } from "../../../../network/auth";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { ActionTypes } from "../../../../context";

interface IProps {
  setStep: (values: string) => void;
  setProgressBarWidth: (values: string) => void;
  profileDetails: Record<string, any>;
}

const Username: React.FC<IProps> = ({ profileDetails }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { dispatch, state: {user} } = useAdvertiserContext();

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
      ...profileDetails,
    };

    try {
      const data = await updateAdvertiserProfile(payload);
      setIsSubmitting(false);
      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...user.user, ...data?.data},
      });
      navigate("/");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const formDetails = [
    {
      name: "username",
      placeholder: "username",
      validator: validateBasicField,
      icon: <AtIcon />,
    },
  ];

  return (
    <Flex className="userdetails__form__main" vertical align="center">
      <h2>Enter your Username</h2>
      <span className="userdetails__subheader">
        Your username is used to uniquely identify you in the community. You
        can’t change your username later.
      </span>
      <CustomForm
        formDetails={formDetails}
        handleSubmit={handleFormSubmit}
        buttonMarginTop="2rem 0 0 0"
        formFinishButtonText="Submit"
        isSubmitting={isSubmitting}
      />
    </Flex>
  );
};

export default Username;
