import React, { useState } from "react";
import { Flex, Form, Popover, notification } from "antd";
import { SlingWrap } from "./sling.styles";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import {
  BsDownload,
  BsEmojiSmileUpsideDownFill,
  BsExclamationCircle,
} from "react-icons/bs";
import { LohButton } from "../../../components/lib/Button";
import { FaPlus } from "react-icons/fa6";
import SlingTable from "./slingTable/slingtable";
import {
  exportTransactions,
  getUserWallet,
  getUserWalletTransactions,
  userCreateVirtualAccount,
  userFundWalletWithSquadco,
} from "../../../network/wallet";
import { useQuery } from "react-query";
import { formatMoney } from "../../../utils/function-helpers/string-functions";
import { TbCurrencyNaira } from "react-icons/tb";
import { TextField } from "../../../components/lib/Form";
import { validateBasicField } from "../../../utils/function-helpers/form-validators";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import AddShekelModal from "./Modals/fundWallet";
import dayjs from "dayjs";

const SlingPage = () => {
  const [page, setPage] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const navigate = useNavigate();
  const [slingType, setSlingType] = useState("watcher");
  const [showaAddDShekelModal, setShowAddShekelModal] = useState(false);
  // const [paymentTypeSelected, setPaymentTypeSelected] = useState("");
  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [virtualAccountLoading, setVirtualAccountLoading] = useState(false);
  const [virtualAccount, setVirtualAccount] = useState({});

  const {
    data: transactions,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useQuery(["transactions", page], () =>
    getUserWalletTransactions(page, 10)
  );

  const { data: wallet, isLoading: walletLoading } = useQuery(["wallet"], () =>
    getUserWallet()
  );

  const handleShowFundwalletModal = async () => {
    setVirtualAccountLoading(true);
    try {
      const response = await userCreateVirtualAccount();
      // setShowAddShekelModal(true);
      setVirtualAccount(response?.data);

      setVirtualAccountLoading(false);
    } catch (error: any) {
      setVirtualAccountLoading(false);

      if (error?.response) {
        console.error(error.response.data.message);
        // notification.open({
        //   message: `Update Failed`,
        //   description: error.response.data.message,
        //   icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        // });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    } finally {
      setShowAddShekelModal(true);
    }
  };

  const amountOfShekels = formatMoney(Number(wallet?.data?.adSpend), false);

  return (
    <DashboardLayout>
      <SlingWrap vertical>
        <Flex justify="space-between" align="center">
          <h1 className="sling__header">
            Sling
            <Popover
              placement={"right"}
              content={"Your Wallet"}
              trigger={["hover"]}
              arrow={false}
            >
              <a>
                <BsExclamationCircle />
              </a>
            </Popover>
          </h1>
        </Flex>

        {walletLoading ? (
          <LohliLoader />
        ) : (
          <Flex vertical className="sling__body">
            <Flex gap="1rem" className="sling__pricesection">
              <Flex vertical className="amount__card__left" align="center">
                <p className="currentbalance__text">Current Balance</p>
                <h1 className="shekels__amount">
                  {formatMoney(Number(wallet?.data?.balance), false) || "0.00"}{" "}
                  <span>
                    Shekel{Number(wallet?.data?.balance) > 1 ? "s" : ""}
                  </span>
                </h1>
                <span className="shekel__rating">1 shekel = ₦200</span>
                <LohButton
                  svgOpacity="1"
                  onClick={handleShowFundwalletModal}
                  loading={virtualAccountLoading}
                >
                  <FaPlus color="#fff" /> Add Shekel
                </LohButton>
              </Flex>

              <Flex vertical className="amount__card__right">
                <Flex
                  vertical
                  className="amount__card__right__inner"
                  align="start"
                >
                  <p className="rightcard__headertext">
                    Funds Budgeted for Ads
                  </p>
                  <h1 className="shekels__amount">
                    {formatMoney(Number(wallet?.data?.adBudget), false) ||
                      "0.00"}{" "}
                    <span>
                      Shekel{Number(wallet?.data?.adBudget) > 1 ? "s" : ""}
                    </span>
                  </h1>
                </Flex>

                <Flex
                  vertical
                  className="amount__card__right__inner amount__card__right__innerbottom"
                  align="start"
                >
                  <p className="rightcard__headertext">
                    Total amount of Shekels giving so far
                  </p>
                  <h1 className="shekels__amount">
                    {formatMoney(Number(wallet?.data?.adSpend), false) ||
                      "0.00"}{" "}
                    <span>
                      Shekel{Number(wallet?.data?.adSpend) > 1 ? "s" : ""}
                    </span>
                  </h1>
                </Flex>
              </Flex>
            </Flex>

            <Flex className="table__wrap " vertical>
              <Flex justify="space-between" align="center">
                <p className="table__headertext">Transaction History</p>
                <LohButton
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  width="10rem"
                  padding=".5rem 0"
                  svgOpacity="1"
                  loading={isExporting}
                  disabled={isExporting}
                  onClick={async () => {
                    setIsExporting(true);
                    const res = await exportTransactions();
                    console.log(res);
                    const data: any[] = res?.data;
                    const csvHeader = [
                      "Date",
                      "Time",
                      "Type",
                      "Description",
                      "Extra details",
                    ] as const;
                    const csvRow = data?.map((item) => {
                      return [
                        `${dayjs(item?.createdAt).format("DD/MM/YYYY")}`,
                        `${dayjs(item?.createdAt).format("HH:mm")}`,
                        item?.type,
                        `${
                          item?.type === "DEBIT"
                            ? `${formatMoney(
                                item?.amount,
                                true
                              )} Shekels has been deducted from ${item?.source}`
                            : `${item?.amount} Shekels has been credited `
                        }`,
                        item?.description,
                      ].join(",");
                    });
                    const csvData = [csvHeader, ...csvRow].join("\n");
                    const CSVFile = new Blob([csvData], { type: "text/csv" });
                    // Create to temporary hidden link to initiate the download process
                    const tempLink = document.createElement("a");
                    // const date = dayjs().format();
                    tempLink.download = `sling-transactions.csv`;
                    const url = window.URL.createObjectURL(CSVFile);
                    tempLink.href = url;
                    tempLink.style.display = "none";
                    document.body.appendChild(tempLink);
                    setIsExporting(false);
                    //automatically click to trigger download
                    tempLink.click();
                    document.body.removeChild(tempLink);
                  }}
                >
                  <BsDownload color="var(--bluePrimary)" /> Export
                </LohButton>
              </Flex>

              <SlingTable
                tableData={transactions?.data}
                onPaginationChange={(page) => {
                  setPage(page);
                }}
                total={transactions?.totalDocuments ?? 0}
                isLoading={isLoadingTransactions || isFetchingTransactions}
              />
            </Flex>
          </Flex>
        )}

        <AddShekelModal
          showaAddDShekelModal={showaAddDShekelModal}
          virtualAccount={virtualAccount}
          setShowAddShekelModal={setShowAddShekelModal}
        />
      </SlingWrap>
    </DashboardLayout>
  );
};

export default SlingPage;
