import { instance } from "./axios";

export const getUsers = async (
  page: number,
  search?: string,
  advertiserType?: string,
  role?: string
) => {
  const { data } = await instance.get(
    `users?page=${page}&limit=10&search=${search}&advertiserType=${advertiserType}&role=${role}`
  );
  return data;
};

export const getUser = async (id: number) => {
  const { data } = await instance.get(`users/${id}`);
  return data;
};

export const findUsers = async (page: number, search?: string) => {
  const { data } = await instance.get(
    `/users/find-by-username?username=${search}&page=${page}&limit=50`
  );
  return data;
};
