import React, { useState } from "react";
import { Flex } from "antd";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import VerifyIcon from "../../../assets/images/auth/verification.png";
import { VerificationsWrap } from "../../../components/layouts/AuthLayout/verification.styles";
import { LohButton } from "../../../components/lib/Button";
import { FaArrowLeft } from "react-icons/fa";
import { encodePhoneNumber } from "../../../utils/function-helpers/encode-phne-number";

const VerifyCredentials = () => {
  const [accessType] = useState<any>(
    JSON.parse(String(localStorage.getItem("signup-details")))
  );

  return (
    <VerificationsWrap>
      <Flex className="verification__main" vertical align="start">
        <div className="verification__logo__wrap">
          <img src={LohliLogo} alt="" />
        </div>

        <Flex className="verification__body__main" justify="center">
          <Flex className="verification__body__sub" vertical align="center">
            <div className="verification__icon__wrap">
              <img src={VerifyIcon} alt="" />
            </div>
            {accessType?.type === "EMAIL" ? (
              <>
                <h2>Check your email</h2>
                <span className="verification__subheader">
                  A magic link has been sent to your email that you provided
                  when creating an account <br />
                  <span>{accessType?.email}</span>
                </span>
                <LohButton>
                  <a href={`https://${accessType?.email}`}>Open Your email</a>
                </LohButton>
              </>
            ) : accessType?.type === "SMS" ? (
              <>
                <h2>Check your SMS box </h2>
                <span className="verification__subheader">
                  A magic link has been sent to your sms number that you
                  provided when creating an account{" "}
                  <span>{encodePhoneNumber(accessType.phoneNumber)}</span>
                </span>
              </>
            ) : (
              <>
                <h2>Check your WhatsApp</h2>
                <span className="verification__subheader">
                  A magic link has been sent to your whatsapp number that you
                  provided when creating an account{" "}
                  <span>{encodePhoneNumber(accessType.phoneNumber)}</span>
                </span>
                <LohButton>
                  <a href="https://web.whatsapp.com/">Open Whatsapp</a>
                </LohButton>
              </>
            )}

            {accessType.from === "signup" ? (
              <a href="/signup" className="reroute__link">
                {" "}
                <FaArrowLeft /> Back to signup
              </a>
            ) : (
              <a href="/login" className="reroute__link">
                {" "}
                <FaArrowLeft /> Back to log in
              </a>
            )}
          </Flex>
        </Flex>

        <span className="copyright__footer">© Lohli 2023</span>
      </Flex>
    </VerificationsWrap>
  );
};

export default VerifyCredentials;
