// general layout for investor General page

import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import { NotificationsPopupWrap } from "../header.styles";
import { useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import moment from "moment";
import { validateImageFile } from "../../../../../utils/function-helpers/form-validators";
import ReactPlayer from "react-player";
import { editNotificationUsernameString } from "../../../../../utils/function-helpers/string-functions";
import LohliAvatar from "../../../../lib/Avatar/avatar";

interface IProps {
  notificationsData: Record<string, any>;
}

const NotificationsPopup: React.FC<IProps> = ({ notificationsData }) => {
  const navigate = useNavigate();

  const [newNotification, setNewNotification] = useState<any>([]);
  const [oldNotification, setOldNotification] = useState<any>([]);

  useEffect(() => {
    const getNewNotification = notificationsData?.filter(
      (item: Record<string, any>) => !item?.read
    );

    setNewNotification(getNewNotification);
  }, [notificationsData]);

  useEffect(() => {
    const getOldNotification = notificationsData?.filter(
      (item: Record<string, any>) => !!item?.read
    );
    setOldNotification(getOldNotification);
  }, [notificationsData]);

  return (
    <NotificationsPopupWrap vertical justify="flex-start" align="flex-start">
      <Flex
        justify="space-between"
        className="notification__popup__header"
        align="center"
      >
        <h3 className="notification__popup__header__title">Notifications</h3>{" "}
        {notificationsData?.length > 0 && (
          <span
            className="notification__popup__header__navtext"
            onClick={() => navigate("/notifications")}
          >
            See All
          </span>
        )}
      </Flex>

      {notificationsData?.length > 0 ? (
        <>
          {newNotification?.length > 0 && (
            <>
              <h3 className="notification__popup__header__title notification__popup__body__title">
                New
              </h3>{" "}
              {newNotification
                ?.slice(0, 3)
                ?.map((item: Record<string, any>, idx: number) => (
                  <Flex className="notification__item" align="start">
                    <div className="notifcations___avatar">
                      <LohliAvatar
                        imageUrl={item?.user?.profile?.avatar?.publicURL || ""}
                      />
                    </div>
                    <p className="notification__body__text">
                      {editNotificationUsernameString(
                        item?.description,
                        item?.user?.profile?.username
                      ) || "No Subtext"}
                      <p className="notification__body__time">
                        {moment(item?.createdAt).fromNow()}
                      </p>
                    </p>
                    {item?.actionType?.includes("AD_") && (
                      <div className="notifcations___videoimage">
                        <img
                          src={item?.advertisement?.thumbnail?.publicURL}
                          alt=""
                        />
                      </div>
                    )}
                    {item?.actionType?.includes("POST_") && (
                      <div className="notifcations___videoimage">
                        {!validateImageFile(
                          item?.post?.media[0]?.publicURL || ""
                        ) ? (
                          <ReactPlayer
                            url={item?.post?.media[0]?.publicURLL}
                            width={"100%"}
                            height={"100%"}
                          />
                        ) : (
                          <img src={item?.post?.media[0]?.publicURL} alt="" />
                        )}
                      </div>
                    )}
                  </Flex>
                ))}
            </>
          )}
          <h3 className="notification__popup__header__title notification__popup__body__title">
            Older
          </h3>
          {oldNotification
            ?.slice(0, 3)
            ?.map((item: Record<string, any>, idx: number) => (
              <Flex className="notification__item" align="start">
                <div className="notifcations___avatar">
                  <LohliAvatar
                    imageUrl={item?.user?.profile?.avatar?.publicURL || ""}
                  />
                </div>
                <p className="notification__body__text">
                  {editNotificationUsernameString(
                    item?.description,
                    item?.user?.profile?.username
                  ) || "No Subtext"}
                  <p className="notification__body__time">
                    {moment(item?.createdAt).fromNow()}
                  </p>
                </p>
                {item?.actionType?.includes("AD_") && (
                  <div className="notifcations___videoimage">
                    <img
                      src={item?.advertisement?.thumbnail?.publicURL}
                      alt=""
                    />
                  </div>
                )}
                {item?.actionType?.includes("POST_") && (
                  <div className="notifcations___videoimage">
                    {!validateImageFile(
                      item?.post?.media[0]?.publicURL || ""
                    ) ? (
                      <ReactPlayer
                        url={item?.post?.media[0]?.publicURLL}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <img src={item?.post?.media[0]?.publicURL} alt="" />
                    )}
                  </div>
                )}
              </Flex>
            ))}
        </>
      ) : (
        <Flex className="no__notifications">
          <p>You have no notifications</p>
        </Flex>
      )}
    </NotificationsPopupWrap>
  );
};

export default NotificationsPopup;
