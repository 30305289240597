import React, { useEffect } from "react";
import { LohButton } from "../../Button/button.styles";
import { CustomFormContainer } from "./customForm.styles";
import { Flex, Form } from "antd";
import { FormDetails } from "./types";
import { TextField } from "../TextField";
import Select from "rc-select";
import SelectField from "../SelectFeild/select";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { LohDatepicker } from "../Datepicker/datepicker";
import dayjs from "dayjs";

interface IProps {
  formDetails: FormDetails[];
  formFinishButtonText: string;
  handleSubmit: (values: Record<string, any>) => void;
  buttonMarginTop?: string;
  buttonDisabled?: boolean;
  isSubmitting?: any;
  label?: string;
  leftFinishButtonText?: string;
  gap?: string;
  isFormDisabled?: boolean;
  handleCancel?: any;
  fillFormDetails?: any;
  formInUse?: any;
}

export const CustomForm: React.FC<IProps> = ({
  formFinishButtonText,
  formDetails,
  buttonMarginTop,
  handleSubmit,
  buttonDisabled,
  isSubmitting,
  gap,
  isFormDisabled,
  handleCancel,
  fillFormDetails,
}) => {
  const [form] = Form.useForm();

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  useEffect(() => {
    function prefillFormDetails() {
      try {
        form.setFieldsValue({
          ...fillFormDetails,
        });
      } catch (error) {}
    }

    if (fillFormDetails) {
      prefillFormDetails();
    }
  }, [fillFormDetails, user]);

  return (
    <CustomFormContainer>
      <Form onFinish={handleSubmit} form={form}>
        <Flex gap={gap} className="form__flexwrap">
          {formDetails.map((item, idx) => {
            return (
              <React.Fragment key={`${idx}__${item.name}`}>
                {item.type === "select" ? (
                  <SelectField
                    placeholder={item.placeholder}
                    name={item.name}
                    validator={item.validator}
                    icon={item.icon}
                    width={item?.width}
                    disabled={isFormDisabled || item.disabled}
                    label={item.label}
                  >
                    {typeof item?.dataSet !== "undefined" &&
                      item?.dataSet.map((value: any, index: number) => (
                        <Select.Option
                          value={item?.isDatasetObject ? value.code : value}
                          key={index}
                        >
                          {item?.isDatasetObject ? value.name : value}
                        </Select.Option>
                      ))}
                  </SelectField>
                ) : item.type === "datepicker" ? (
                  <Flex className="datepicker__wrap">
                    {item.label && <label>{item.label}</label>}
                    <LohDatepicker
                      placeholder={item.placeholder}
                      name={item.name}
                      disabled={isFormDisabled || item.disabled}
                      onChange={item?.handleChange && item?.handleChange}
                      value={
                        item.defaultValue ? dayjs(item.defaultValue) : undefined
                      }
                    />
                  </Flex>
                ) : (
                  <TextField
                    width={item?.width}
                    placeholder={item.placeholder}
                    name={item.name}
                    validator={item.validator}
                    textarea={item.type === "textarea" ? true : false}
                    icon={item.icon}
                    type={item.type}
                    totalCharacters={item.totalCharacters}
                    disabled={isFormDisabled || item.disabled}
                    label={item.label}
                    readonly={item.readonly}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Flex>
        {!isFormDisabled && (
          <Flex gap="1rem" justify="flex-end" className="form__buttons">
            {handleCancel && (
              <LohButton
                margin={buttonMarginTop}
                onClick={handleCancel}
                backgroundColor="transparent"
                color="var(--blackSecondary)"
                borderColor="#C8C8C8"
                width="7rem"
                height="2.5rem"
                padding="0"
              >
                Cancel
              </LohButton>
            )}

            <LohButton
              htmlType="submit"
              margin={buttonMarginTop}
              disabled={buttonDisabled}
              loading={isSubmitting}
              width={handleCancel ? "12rem" : "100%"}
              height={handleCancel && "2.5rem"}
              padding={handleCancel && "0"}
            >
              {formFinishButtonText || "Save"}
            </LohButton>
          </Flex>
        )}
      </Form>
    </CustomFormContainer>
  );
};
