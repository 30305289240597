import { Flex } from "antd";
import styled from "styled-components";

export const FooterWrap = styled(Flex)`
  width: 100%;
  height: 6rem;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 0 0.5rem 1.2rem 0.5rem;
  box-shadow: 0px 5px 6px 2px black;
  justify-content: center;
  z-index: 9;

  .footernav__items__wrap {
    width: 92%;
    height: 100%;

    .footernav__item {
      svg {
        color: var(--lightGrayPrimary);
      }
      .upload__icon {
        margin-bottom: 3px;
      }

      .footer__navitem__text {
        margin: 0;
        color: var(--lightGrayPrimary);
        font-weight: 500;
      }
    }

    .active__footernav__item {
      svg,
      .footer__navitem__text {
        color: var(--bluePrimary);
      }
    }
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;
