export const filterTribeData = [
  "Reuben",
  "Simeon",
  "Levi",
  "Judah",
  "Dan",
  "Naphtali",
  "Gad",
  "Asher",
  "Issachar",
  "Zebulun",
  "Joseph",
  "Benjamin",
];

export const filterAdvertiserData = ["Brands", "Agency"];

export const overviewSums = [
  {
    title: "Number of Views",
    amount: "100k",
  },
  {
    title: "Shekels Spent on Ads",
    amount: "₦500,000",
    footerText: "(25,000 in Shekel)",
  },
  {
    title: "Watchers /Audience Size",
    amount: "131.4k",
  },
  {
    title: "Ad Ratings",
    amount: "4.8",
  },
  {
    title: "Click-through Rates (CTR)",
    amount: "14.5k",
  },
  {
    title: "Conversion Rate",
    amount: "10%",
  },
];

export const topViewersLocations = [
  {
    location: "Ikeja, Lagos(NIG)",
    percentage: "70",
    views: "17k views",
  },
  {
    location: "Shagamu, Ogun(NIG)",
    percentage: "20",
    views: "5k views",
  },
  {
    location: "Yaba, Lagos(NIG)",
    percentage: "80",
    views: "18k views",
  },
  {
    location: "Shomolu, Lagos(NIG)",
    percentage: "90",
    views: "19k views",
  },
  {
    location: "Surulere, Lagos(NIG)",
    percentage: "60",
    views: "16k views",
  },
  {
    location: "Ikorodu, Lagos(NIG)",
    percentage: "10",
    views: "2k views",
  },
  {
    location: "Wuse II, Abuja(NIG)",
    percentage: "70",
    views: "17k views",
  },
  {
    location: "Badagry, Lagos(NIG)",
    percentage: "50",
    views: "15k views",
  },
];

export const barChartData = {
  label: ["<18", "18-27", "28-59", "60+"],
  datas: [
    {
      data: [20, 60, 90, 50],
      color: "#0062D6",
      hoverLabel: "Percentage",
    },
  ],
};

export const pieChartData = {
  label: ["Male", "Female"],
  datas: [
    {
      data: [40, 60],
      color: ["#E5C487", "#0062D6"],
    },
  ],
};
