import React, { useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { HomePageWrap } from "./homepage.styles";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import LohTable from "../../../components/lib/AdsTable/adsTable";
import { LohButton } from "../../../components/lib/Button";
import { FaPlus } from "react-icons/fa6";
import { getAllAdverts } from "../../../network/advert";
import { useQuery } from "react-query";
import { useAdvertiserContext } from "../../../context/advertiserContext/main";
import { orderBy, sortBy } from "lodash";
import NavTabs from "../../../components/lib/Tabs/navTabs";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import { BsEmojiSmileUpsideDownFill, BsPlusSquare } from "react-icons/bs";
import { PiVideoCamera } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { getAllStats, getTopPerformingAds } from "../../../network/metrics";
import { formatMoney } from "../../../utils/function-helpers/string-functions";
import { suggestedBrands } from "../../../utils/data-helpers/homepage";
import { feedData } from "../../../utils/data-helpers/feed";
import { getAllPosts, getFeedPosts } from "../../../network/posts";
import PostCard from "../../../components/lib/PostCard/postCard";
import { getUsers } from "../../../network/users";
import AvatarImage from "../../../assets/images/dashboard/avatar.svg";
import { advertiserFollowUser } from "../../../network/auth";

const HomePage = () => {
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();
  const navigate = useNavigate();

  const { data: topPerformingAds } = useQuery(["topads", user?.id], () =>
    getTopPerformingAds(user?.id)
  );

  const { data: suggestedBrands, refetch: refetchBrands } = useQuery(
    ["suggestedBrands"],
    () => getUsers(1, "", "BRAND", "advertiser")
  );

  const { data: stats } = useQuery(["stats"], () => getAllStats("", "", ""));

  const { data: advertiserPosts, isLoading: advertiserPostsLoading } = useQuery(
    ["advertiserposts"],
    () => getFeedPosts("ACTIVE", "ADVERTISER_POST", 1, "")
  );

  const { data: watchersPosts, isLoading: watchersPostsLoading } = useQuery(
    ["watcherpost"],
    () => getFeedPosts("ACTIVE", "WATCHER_POST", 1, "")
  );

  const [activeFeedTab, setActiveFeedTab] = useState({
    title: "",
    status: "",
    noDataState: "",
    data: [],
    isLoading: false,
    type: "ADVERTISER_POST",
  });
  const [isFollowingLoading, setIsFollowingLoading] = useState(-1);

  useEffect(() => {
    setActiveFeedTab({
      title: "Advertisers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: advertiserPosts?.data,
      isLoading: advertiserPostsLoading,
      type: "ADVERTISER_POST",
    });
  }, [advertiserPosts, advertiserPostsLoading]);

  const feedTabs = [
    {
      title: "Advertisers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: advertiserPosts?.data,
      isLoading: advertiserPostsLoading,
      type: "ADVERTISER_POST",
    },
    {
      title: "Watchers Post",
      status: "ACTIVE",
      noDataState: "No News / Articles has been published",
      data: watchersPosts?.data,
      isLoading: watchersPostsLoading,
      type: "WATCHER_POST",
    },
  ];

  const suggestedBrandsData: any[] = suggestedBrands?.data ?? [];

  const handleFollowUser = async (
    status: boolean | undefined,
    userId: number
  ) => {
    setIsFollowingLoading(userId);
    try {
      await advertiserFollowUser({ status: !status, userId: userId });
      await refetchBrands();
      setIsFollowingLoading(-1);
    } catch (error: any) {
      setIsFollowingLoading(-1);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <DashboardLayout>
      <HomePageWrap vertical>
        <Flex justify="space-between" className="header__section">
          <h1 className="homepage__header">Overview</h1>

          <Flex className="header__buttons__wrap">
            <LohButton
              width="10rem"
              svgOpacity="1"
              onClick={() => (window.location.href = "/ads/upload")}
            >
              <PiVideoCamera color="#fff" /> Upload Ads
            </LohButton>

            <LohButton
              width="10rem"
              svgOpacity="1"
              onClick={() => (window.location.href = "/posts/upload")}
              backgroundColor="transparent"
              color="var(--bluePrimary)"
            >
              <BsPlusSquare color="var(--bluePrimary)" /> Upload Post
            </LohButton>
          </Flex>
        </Flex>

        <Flex vertical className="homepage__section__wrap">
          <Flex className="homepage__overviewsums__wrap">
            <Flex className="overviewsums_main" vertical>
              <p className="overviewsums_ptext">Published Content</p>
              <h2 className="overviewsums_amount">
                {stats?.data?.uploadedAds + stats?.data?.uploadedPosts || "0"}
              </h2>
            </Flex>

            <Flex className="overviewsums_main" vertical>
              <p className="overviewsums_ptext">Total Followers</p>
              <h2 className="overviewsums_amount">
                {stats?.data?.totalFollowers}
              </h2>
            </Flex>

            <Flex className="overviewsums_main" vertical>
              <p className="overviewsums_ptext">Total Comments</p>
              <h2 className="overviewsums_amount">
                {stats?.data?.totalComments}
              </h2>
            </Flex>

            <Flex className="overviewsums_main" vertical>
              <p className="overviewsums_ptext">Total Likes</p>
              <h2 className="overviewsums_amount">{stats?.data?.totalLikes}</h2>
            </Flex>

            <Flex className="overviewsums_main" vertical>
              <p className="overviewsums_ptext">Total Ads Views</p>
              <h2 className="overviewsums_amount">
                {stats?.data?.totalAdsViews}
              </h2>
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical className="homepage__section__wrap">
          <Flex justify="space-between">
            <h3 className="homepage__h3">Top Performing Ads</h3>
            <a href="/ads" className="homepage__links">
              View all Ads
            </a>
          </Flex>
          <LohTable
            tableData={(topPerformingAds?.data
              ? [...topPerformingAds?.data]
              : []
            )?.splice(0, 3)}
            tableHeight="max-content"
          />
        </Flex>

        <Flex vertical className="homepage__section__wrap">
          <Flex justify="space-between">
            <h3 className="homepage__h3">Suggested for brand for you</h3>
          </Flex>
          <Flex className="suggestedbrands__wrap">
            {suggestedBrandsData?.length &&
              suggestedBrandsData?.map((item, idx) => (
                <Flex className="suggestedbrands_main" vertical key={idx}>
                  <div className="logo__wrap">
                    <img
                      alt=""
                      src={item?.profile?.avatar?.publicURL ?? AvatarImage}
                    />
                  </div>
                  <p className="suggested__company">
                    {item?.profile?.username}
                  </p>
                  <p className="suggested__username">
                    {item?.advertiserInfo?.name}
                  </p>

                  <Flex className="suggested__brandsbutton" gap=".5rem">
                    <LohButton
                      backgroundColor={
                        item?.followingUser ? "var(--bluePrimary)" : "#fff"
                      }
                      color={
                        item?.followingUser ? "#fff" : "var(--bluePrimary)"
                      }
                      loading={isFollowingLoading === item?.id}
                      padding="0"
                      height="2rem"
                      fontSize=".75rem"
                      onClick={() =>
                        handleFollowUser(item?.followingUser, item?.id)
                      }
                    >
                      {item?.followingUser ? "Following" : "Follow"}
                    </LohButton>

                    <LohButton
                      backgroundColor="#E4E4E4"
                      color="var(--darkGrayPrimary)"
                      borderColor="#E4E4E4"
                      padding="0"
                      height="2rem"
                      fontSize=".75rem"
                    >
                      Remove
                    </LohButton>
                  </Flex>
                </Flex>
              ))}
          </Flex>
        </Flex>

        <Flex
          vertical
          className="homepage__section__wrap homepage__section__wrap__feeds"
        >
          <Flex justify="space-between">
            <h3 className="homepage__h3">Feeds</h3>

            {activeFeedTab?.data?.length > 0 && (
              <a href="/feeds/overview" className="homepage__links">
                View all feeds
              </a>
            )}
          </Flex>

          <NavTabs
            navTabData={feedTabs}
            setActiveTab={setActiveFeedTab}
            activeTab={activeFeedTab}
            borderWidth="0 0 0.08rem  0"
          />

          {activeFeedTab?.data?.length > 0 ? (
            <Flex className="postcards__wrap">
              {activeFeedTab?.data?.map((item: Record<string, any>, idx) => (
                <Flex className="card__wrap" key={idx}>
                  <PostCard
                    data={item}
                    activepostTab={activeFeedTab}
                    type="feeds_home"
                    uploadViewType="feed"
                  />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex
              className="nodata__wrap "
              vertical
              align="center"
              justify="center"
            >
              <h3 className="noads__headtitle">{activeFeedTab?.noDataState}</h3>
              <p className="noads__subtitle">
                Increase Visibility and Engagement Through Your posts
              </p>
              <LohButton
                svgOpacity="1"
                width="10rem"
                onClick={() => navigate("/posts/upload")}
              >
                <FaPlus color="#fff" /> Upload
              </LohButton>
            </Flex>
          )}
        </Flex>
      </HomePageWrap>
    </DashboardLayout>
  );
};

export default HomePage;
