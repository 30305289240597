// general layout for investor General page

import React, { useEffect, useContext, useRef, useState } from "react";
import { DashboardLayoutWrap } from "./dashboardLayout.styles";
import Header from "./Header/header";
import Footer from "./Footer/footer";

interface IProps {
  children: any;
}

const DashboardLayout: React.FC<IProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DashboardLayoutWrap>
      <Header />
      <main className="dashboard__layout__main">{children}</main>
      <Footer />
    </DashboardLayoutWrap>
  );
};

export default DashboardLayout;
