import React, { useCallback, useState } from "react";
import { Flex, notification } from "antd";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { SearchWrap } from "./search.styles";
import { TextField } from "../../../components/lib/Form";
import { validateNonRequiredField } from "../../../utils/function-helpers/form-validators";
import { RiSearchLine } from "react-icons/ri";
import {
  recentSearch,
  suggestedSearch,
} from "../../../utils/data-helpers/search";
import SuggetedAvatar from "../../../assets/images/dashboard/feed-avatar.png";
import { LohButton } from "../../../components/lib/Button";
import { debounce } from "../../../utils/function-helpers/debounce";
import useInfiniteScroll from "../../../utils/hooks/useInifiniteScroll";
import { findUsers } from "../../../network/users";
import { advertiserFollowUser } from "../../../network/auth";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import AvatarImage from "../../../assets/images/dashboard/avatar.svg";

const SearchPage = () => {
  const navigate = useNavigate();
  const [suggested, setSuggested] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [isFollowingLoading, setIsFollowingLoading] = useState(-1);

  const {
    data: users,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: refetchUsers,
    lastElementRef,
    total: usersTotal,
  } = useInfiniteScroll({
    queryFn: ({ pageParam = 1 }) => findUsers(pageParam, search),
    queryKey: ["users", search],
  });

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
      },
      1000,
      false
    ),
    []
  );

  const handleFollowUser = async (
    status: boolean | undefined,
    userId: number
  ) => {
    setIsFollowingLoading(userId);
    try {
      await advertiserFollowUser({ status: !status, userId: userId });
      await refetchUsers();
      setIsFollowingLoading(-1);
    } catch (error: any) {
      setIsFollowingLoading(-1);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const searchedUsers = search?.trim() ? users?.data : [];

  return (
    <DashboardLayout>
      <SearchWrap vertical className="has__coinbg">
        <Flex justify="space-between" align="center">
          <h1 className="search__header">Search</h1>

          <div className="close__route" onClick={() => navigate("/")}>
            <AiOutlineClose size={25} color="#9F9F9F" />
          </div>
        </Flex>
        <Flex className="search__wrap" gap="1rem" wrap="nowrap" align="center">
          <TextField
            handleChange={(e: any) => {
              handleSearch(e);
            }}
            placeholder="Search"
            defaultValue={search}
            icon={<RiSearchLine />}
            margin="0"
          />
          <span className="cancel">Cancel</span>
        </Flex>

        <Flex className="suggested__wrap body__wrap" vertical>
          <h3 className="body__titletext">
            <RiSearchLine /> Search for “{search}”
          </h3>

          {searchedUsers?.map((item, idx) => (
            <Flex className="body__item" justify="flex-start" gap=".5rem">
              <div
                onClick={() =>
                  navigate("/feeds/poster-profile", {
                    state: {
                      data: {
                        user: {
                          id: item?.id,
                          profile: item?.profile,
                          totalFollowing: item?.totalFollowing,
                          totalFollowers: item?.totalFollowers,
                        },
                      },
                      type: "WATCHER_POST",
                      userId: item?.id,
                    },
                  })
                }
                className="suggested_avatar "
              >
                <img
                  src={item?.profile?.avatar?.publicURL ?? AvatarImage}
                  alt=""
                />
              </div>
              <Flex
                onClick={() =>
                  navigate("/feeds/poster-profile", {
                    state: {
                      data: {
                        user: {
                          id: item?.id,
                          profile: item?.profile,
                          totalFollowing: item?.totalFollowing,
                          totalFollowers: item?.totalFollowers,
                        },
                      },
                      type: "WATCHER_POST",
                      userId: item?.id,
                    },
                  })
                }
                vertical
                align="flex-start"
              >
                <p className="suggested__usertitle">
                  {item?.profile?.firstName} {item?.profile?.lastName}
                </p>
                <span className="suggested__username">
                  {item?.profile?.username ?? ""} {item?.totalFollowers ?? 0}{" "}
                  followers
                </span>
              </Flex>

              <LohButton
                padding="0"
                width="4.2rem"
                height="1.8rem"
                borderColor="var(--bluePrimary)"
                backgroundColor={
                  item?.followingUser ? "var(--bluePrimary)" : "#fff"
                }
                color={item?.followingUser ? "#fff" : "var(--bluePrimary)"}
                loading={isFollowingLoading === item?.id}
                fontSize=".8rem"
                margin="0 0 0 2rem"
                onClick={() => handleFollowUser(item?.followingUser, item?.id)}
              >
                {item?.followingUser ? "Following" : "Follow"}
              </LohButton>
            </Flex>
          ))}
        </Flex>
        {usersLoading || usersFetching ? <LohliLoader /> : null}
        <div ref={lastElementRef} />
        {/* {suggested ? (
          <Flex className="suggested__wrap body__wrap" vertical>
            <h3 className="body__titletext">
              <RiSearchLine /> Search for “tech”
            </h3>

            {suggestedSearch.map((item, idx) => (
              <Flex className="body__item" justify="flex-start" gap=".5rem">
                <div className="suggested_avatar">
                  <img src={SuggetedAvatar} alt="" />
                </div>
                <Flex vertical align="flex-start">
                  <p className="suggested__usertitle">{item.title}</p>
                  <span className="suggested__username">
                    {item.username} {item.followers}
                  </span>
                </Flex>

                <LohButton
                  padding="0"
                  width="4.2rem"
                  height="1.8rem"
                  backgroundColor="transparent"
                  borderColor="var(--bluePrimary)"
                  color="var(--bluePrimary)"
                  fontSize=".8rem"
                  margin="0 0 0 2rem"
                >
                  Follow
                </LohButton>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex className="recent__wrap body__wrap" vertical>
            <h3 className="body__titletext">Recent</h3>

            {recentSearch.map((item, idx) => (
              <Flex className="body__item" justify="space-between" gap=".5rem">
                <span className="recent__text">
                  <RiSearchLine size={20} color="#9F9F9F" /> {item}
                </span>

                <div className="cancel__recent">
                  <AiOutlineClose size={18} color="#9F9F9F" />
                </div>
              </Flex>
            ))}
          </Flex>
        )} */}
      </SearchWrap>
    </DashboardLayout>
  );
};

export default SearchPage;
