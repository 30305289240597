// general layout for investor General page

import React, { useEffect, useState } from "react";
import { ConnectWatcherModal, HeaderWrap } from "./header.styles";
import { Dropdown, Flex, Popover, notification } from "antd";
import LohliLogo from "../../../../assets/images/dashboard/lohli-logo-white.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsCheck, BsPersonCircle } from "react-icons/bs";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { FiLoader, FiLogOut } from "react-icons/fi";
import ConnectWatcherModalBody from "./WatcherModals/connectWatcherModal";
import { IoSearchOutline } from "react-icons/io5";
import NotificationsPopup from "./NotificationsPopover/notificationPopover";
import ViewConnectedWatcherModal from "./WatcherModals/viewConnectedWatcher";
import { advertiserGetConnectedWatcher } from "../../../../network/watcher";
import { useQuery } from "react-query";
import { advertiserGetAllNotifications } from "../../../../network/notifications";
import LohliAvatar from "../../../lib/Avatar/avatar";

interface IProps {}

const Header: React.FC<IProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [watcherConnectToken, setWatcherConnectToken] = useState(
    sessionStorage.getItem("connect__watcher__token")
  );

  const { data: connectedWatcher, isLoading: connectedWatcherLoading } =
    useQuery(
      ["connected-watcher", watcherConnectToken],
      () => advertiserGetConnectedWatcher(watcherConnectToken),
      { enabled: !!watcherConnectToken }
    );

  const { data: notification, isLoading: notificationsLoading } = useQuery(
    ["published"],
    advertiserGetAllNotifications
  );

  const [showViewConnectSuccessfulPopup, setShowViewConnectSuccessfulPopup] =
    useState(false);

  const [connectStep, setConnectStep] = useState(
    watcherConnectToken ? "Verification" : "Enter Details"
  );
  const [showViewWatcherModal, setShowViewWatcherModal] = useState(false);

  const {
    state: { user },
  } = useAdvertiserContext();

  const handleLogOut = () => {
    sessionStorage.removeItem("advertiser-tokens");
    navigate("/login");
  };

  const uploadItems = [
    {
      key: "1",
      label: (
        <a rel="noopener noreferrer" href="/ads" className="dropdown__items">
          Ads Upload
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a rel="noopener noreferrer" href="/posts" className="dropdown__items">
          Posts Uploads
        </a>
      ),
    },
  ];

  const notificationsItems = [
    {
      key: "1",
      label: (
        <p className="dropdown__items">
          {notificationsLoading ? (
            <FiLoader />
          ) : (
            <NotificationsPopup notificationsData={notification?.data} />
          )}
        </p>
      ),
    },
  ];

  const analyticsItems = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href="/analytics/overview"
          className="dropdown__items"
        >
          Overview
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          rel="noopener noreferrer"
          href="/analytics/your-uploads"
          className="dropdown__items"
        >
          Your Uploads
        </a>
      ),
    },
  ];

  const profileItems = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href="/profile"
          className="dropdown__items"
        >
          Profile
        </a>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <a rel="noopener noreferrer" href="" className="dropdown__items">
    //       Settings
    //     </a>
    //   ),
    // },

    {
      key: "3",
      label: (
        <p
          onClick={() => {
            connectedWatcher
              ? setShowViewWatcherModal(true)
              : setShowConnectModal(!showConnectModal);
          }}
          className="dropdown__items dropdown__items__blue"
        >
          {connectedWatcher
            ? "View Connected Watcher account"
            : "Connect to Watcher account"}
        </p>
      ),
    },

    {
      key: "4",
      label: (
        <p
          rel="noopener noreferrer"
          className="dropdown__items dropdown__items__red"
          onClick={handleLogOut}
        >
          <FiLogOut /> Log out
        </p>
      ),
    },
  ];

  const uploadMenuProps = {
    items: uploadItems,
  };

  const notificationsMenuProps = {
    items: notificationsItems,
  };

  const analyticsMenuProps = {
    items: analyticsItems,
  };

  const profileCardItemProps = {
    items: profileItems,
  };

  const menuItems = [
    {
      navTitle: "Home",
      navRoute: "/",
    },
    {
      navTitle: "Feeds",
      navRoute: "/feeds/overview",
    },
    {
      navTitle: "Analytics",
      subroutes: ["/analytics/overview", "/analytics/your-uploads"],
      doprdownItems: true,
      type: "analytics",
    },
    {
      navTitle: "Your Uploads",
      subroutes: ["/upload", "/ads", "/ads/upload", "/posts/upload", "/posts"],
      doprdownItems: true,
    },
    {
      navTitle: "Sling",
      navRoute: "/sling",
    },
  ];

  return (
    <HeaderWrap showViewConnectSuccessfulPopup={showViewConnectSuccessfulPopup}>
      <Flex className="header__wrap__main" justify="space-between">
        <div className="header__logo__wrap">
          <img src={LohliLogo} alt="" />
        </div>

        <Flex className="watcher__connected" justify="center">
          <Flex className="watcher__connected__inner">
            <BsCheck size={20} />{" "}
            <h3 className="watcher__connected__text">
              Your watchers sling had been linked
            </h3>
          </Flex>
        </Flex>

        <Flex className="nav__items__wrap">
          {menuItems.map((item: Record<string, any>, index: number) =>
            !item.doprdownItems ? (
              <p
                className={`nav__item ${
                  (location.pathname === item.navRoute ||
                    item?.subroutes?.includes(location.pathname)) &&
                  "active__nav__item"
                }`}
                onClick={() => navigate(item.navRoute)}
                key={index}
              >
                {item.navTitle}
              </p>
            ) : (
              <Dropdown
                menu={
                  item.type === "analytics"
                    ? analyticsMenuProps
                    : uploadMenuProps
                }
                placement="bottomLeft"
                trigger={["hover"]}
              >
                <a>
                  <p
                    className={`nav__item ${
                      (location.pathname === item.navRoute ||
                        item?.subroutes?.includes(location.pathname)) &&
                      "active__nav__item"
                    }`}
                    key={index}
                  >
                    {item.navTitle}
                  </p>
                </a>
              </Dropdown>
            )
          )}
        </Flex>

        <Flex className="header__profilenav__wrap">
          <Flex
            className={`header__iconwrap ${
              location.pathname === "/search" && "header__iconwrap__active"
            }`}
            onClick={() => navigate("/search")}
          >
            <IoSearchOutline
              color={
                location.pathname === "/search" ? "var(--blackPrimary)" : "#fff"
              }
            />
          </Flex>

          <Dropdown
            menu={notificationsMenuProps}
            placement="bottom"
            trigger={["hover"]}
          >
            <a>
              <Flex
                className={`header__iconwrap ${
                  location.pathname === "/notifications" &&
                  "header__iconwrap__active"
                }`}
              >
                {location.pathname !== "/notifications" &&
                  !notification?.data[0]?.read && (
                    <div className="newnotification__alert"></div>
                  )}
                <IoMdNotificationsOutline
                  color={
                    location.pathname === "/notifications"
                      ? "var(--blackPrimary)"
                      : "#fff"
                  }
                  className="notificatios__icon"
                />
              </Flex>
            </a>
          </Dropdown>

          <Dropdown
            menu={profileCardItemProps}
            placement="bottomRight"
            trigger={["hover"]}
          >
            <a>
              <Flex>
                <div className="user__image__wrap">
                  <LohliAvatar
                    imageUrl={user?.profile?.avatar?.publicURL || ""}
                  />
                </div>
                <Flex
                  className="profile__headername__wrap"
                  vertical
                  justify="flex-start"
                >
                  <p>{user?.advertiserInfo?.name}</p>

                  <span>
                    @{user?.profile?.username}
                    <span>({user?.advertiserInfo?.type})</span>
                  </span>
                </Flex>
              </Flex>
            </a>
          </Dropdown>
        </Flex>
      </Flex>

      <ConnectWatcherModal
        open={showConnectModal}
        onCancel={() => {
          setShowConnectModal(false);
        }}
        footer={null}
        centered
      >
        <ConnectWatcherModalBody
          setShowConnectModal={setShowConnectModal}
          setConnectStep={setConnectStep}
          connectStep={connectStep}
          setWatcherConnectToken={setWatcherConnectToken}
          watcherConnectToken={watcherConnectToken}
          setShowViewConnectSuccessfulPopup={setShowViewConnectSuccessfulPopup}
          onSuccess={() => {
            setShowConnectModal(false);
            setShowViewConnectSuccessfulPopup(true);
            setShowViewWatcherModal(true);
          }}
        />
      </ConnectWatcherModal>

      <ViewConnectedWatcherModal
        setShowViewWatcherModal={setShowViewWatcherModal}
        showViewWatcherModal={showViewWatcherModal}
        connectedWatcher={connectedWatcher}
      />
    </HeaderWrap>
  );
};

export default Header;
