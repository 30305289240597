import { Flex } from "antd";
import styled from "styled-components";
import CoinBagsBg from "../../../assets/images/dashboard/dashboard-coinbg.png";

export const DashboardLayoutWrap = styled(Flex)`
  width: 100%;
  height: max-content;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  flex-direction: column;

  .dashboard__layout__main {
    width: 100%;
    height: max-content;
    margin-top: 5rem;
    z-index: 2;

    .has__coinbg {
      background-image: url(${CoinBagsBg});
      background-repeat: no-repeat;
      background-size: 100;
      background-position: top;
      background-attachment: fixed;
    }
  }

  .ant-pagination {
    border: 1px solid var(--lightGraySecondary);
    border-radius: 10rem;
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    .ant-pagination-item {
      padding-top: 4px;
      border-radius: 0;

      a {
        color: var(--darkGrayPrimary);
        font-weight: 300;
      }
    }

    .ant-pagination-item-active {
      border: none;
      background-color: #ebebebf7;

      a {
        color: var(--darkGrayPrimary);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .dashboard__layout__main {
      margin-top: 0;
      padding-bottom: 5rem;
    }
  }
  @media screen and (max-width: 600px) {
    .ant-pagination {
      .ant-pagination-item {
        padding-top: 7px;
      }
    }
  }
`;
