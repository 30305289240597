import React, { useState } from "react";
import { Flex, notification } from "antd";
import { DeleteModalWrap } from "../uploadView.styles";
import { LohButton } from "../../../../components/lib/Button";
import { deleteAdvert } from "../../../../network/advert";
import { deletePost } from "../../../../network/posts";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface IProps {
  showDeleteModal?: boolean;
  setShowDeleteModal?: any;
  state: Record<string, any>;
}

const DeleteModal: React.FC<IProps> = ({
  showDeleteModal,
  setShowDeleteModal,
  state,
}) => {
  const closeModal = () => setShowDeleteModal(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const handleDeleteUpload = async () => {
    setIsDeleting(true);

    try {
      const deleteResult = await (state?.type === "post"
        ? deletePost(state?.id)
        : state?.type === "feed"
        ? ""
        : deleteAdvert(state?.id));
      setIsDeleting(false);
      notification.open({
        message: `Successful`,
        description: "Advert Has Been Deleted",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      closeModal();
      navigate(-1);
    } catch (e: any) {
      setIsDeleting(false);

      if (e?.response) {
        notification.open({
          message: `Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };
  return (
    <DeleteModalWrap
      open={showDeleteModal}
      onCancel={closeModal}
      footer={null}
      closeIcon={false}
      centered
    >
      <Flex vertical align="start">
        <h3>Delete Upload</h3>
        <p>You are about to delete this upload, are you sure?</p>
      </Flex>

      <Flex justify="center" gap="1rem" className="buttons__wrap">
        <LohButton
          backgroundColor="transparent"
          borderColor="var(--bluePrimary)"
          color="var(--bluePrimary)"
          htmlType="submit"
          onClick={closeModal}
          width="13rem"
          padding="0"
          height="2.5rem"
        >
          No! Cancel
        </LohButton>
        <LohButton
          onClick={handleDeleteUpload}
          width="13rem"
          loading={isDeleting}
          padding="0"
          height="2.5rem"
        >
          Yes! Sure
        </LohButton>
      </Flex>
    </DeleteModalWrap>
  );
};
export default DeleteModal;
