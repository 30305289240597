import React, { useState } from "react";
import { Flex, notification } from "antd";
// import { signUpUser } from "../../../network/user";
import { AuthWrap } from "../../../components/layouts/AuthLayout/registration.styles";
import { CustomForm } from "../../../components/lib/Form";
import {
  getMachineId,
  validateEmail,
} from "../../../utils/function-helpers/form-validators";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import SignupImage from "../../../assets/images/auth/signup-image.gif";
import { useNavigate } from "react-router";
import { IoMailSharp as MailIcon } from "react-icons/io5";
import { LohCheckbox } from "../../../components/lib/Checkbox";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { signupAdvertiser } from "../../../network/auth";

const Signup = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [activeInputType] = useState({
    name: "email",
    placeholder: "Enter your email address",
    validator: validateEmail,
    icon: <MailIcon />,
    notificationMedium: "EMAIL",
  });
  const navigate = useNavigate();

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      email: values?.email || "",
      phoneNumber: values?.phoneNumber || "",
      deviceId: getMachineId(),
      notificationMedium: activeInputType.notificationMedium,
      redirectUrl: `${window.location.origin}/authenticating`,
    };

    try {
      await signupAdvertiser(payload);
      setIsSubmitting(false);

      localStorage.setItem(
        "signup-details",
        JSON.stringify({
          type: payload.notificationMedium,
          phoneNumber: payload.phoneNumber,
          email: payload.email,
          from: "signup",
        })
      );
      navigate("/verify-credentials");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Signup Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  // const formDetails = [
  //   {
  //     name: "email",
  //     placeholder: "Enter your email address",
  //     validator: validateEmail,
  //     icon: <MailIcon />,
  //     notificationMedium: "EMAIL",
  //     buttonText: "Use Email Address",
  //   },
  //   {
  //     name: "phoneNumber",
  //     placeholder: "Enter your WhatsApp Number",
  //     validator: validatePhoneNumber,
  //     icon: <BsWhatsapp />,
  //     notificationMedium: "WHATSAPP",
  //     buttonText: "Use WhatsApp number",
  //   },
  //   {
  //     name: "phoneNumber",
  //     placeholder: "Enter your Phone Number",
  //     validator: validatePhoneNumber,
  //     icon: <BsTelephoneFill />,
  //     notificationMedium: "SMS",
  //     buttonText: "Use Phone number",
  //   },
  // ];
  return (
    <AuthWrap>
      <Flex className="auth__left__section__main" justify="center">
        <Flex className="auth__left__section__sub" vertical align="start">
          <div className="auth__logo__wrap">
            <img src={LohliLogo} alt="" />
          </div>

          <span className="auth__reroute__text">
            Already have an account? <a href="/login">Log in</a>
          </span>

          <Flex className="auth__left__section__form__main" justify="center">
            <Flex
              vertical
              align="start"
              className="auth__left__section__form__sub"
            >
              <h1 className="auth__header__text">Create an Account</h1>
              <p className="auth__body__text">
                Discover New Avenues for Growth and Success by Partnering with
                Lohli - Your Trusted Advertising Platform
              </p>
              <CustomForm
                formFinishButtonText="Continue"
                handleSubmit={handleFormSubmit}
                formDetails={[activeInputType]}
                buttonMarginTop="1rem 0 0 0"
                buttonDisabled={!isTermsChecked}
                isSubmitting={isSubmitting}
              />
              <LohCheckbox onChange={(e) => setIsTermsChecked(!isTermsChecked)}>
                <p className="checkbox__text">
                  By ticking, you are accepting the{" "}
                  <a href="/Lohli- Terms and Conditions.pdf" target="_blank">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="/Lohli Privacy Policy.pdf" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  for Lohili
                </p>
              </LohCheckbox>
              {/* <Flex className="or__divider" justify="center">
                <p>or</p>
                <div></div>
              </Flex>
              <Flex justify="space-between" className="formoptions__wrap">
                {formDetails.map(
                  (item: any, idx: number) =>
                    item.notificationMedium !==
                      activeInputType.notificationMedium && (
                      <LohButton
                        width="48%"
                        backgroundColor="transparent"
                        borderColor="var(--lightGraySecondary)"
                        onClick={() => setActiveInputType(item)}
                        color="var(--blackSecondary)"
                      >
                        {item.icon} {item.buttonText}
                      </LohButton>
                    )
                )}
              </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="auth__right__section">
        <img src={SignupImage} alt="" />
      </Flex>
      <span className="copyright__footer">© Lohli 2023</span>
    </AuthWrap>
  );
};

export default Signup;
