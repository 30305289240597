import { Flex } from "antd";
import styled from "styled-components";

export const EngagementMetricsWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  gap: 1rem;

  .metrics__header {
    font-size: 2rem;
  }

  .sections__header {
    font-size: 1rem;
    color: #101828;
    font-family: "LexendMedium", sans-serif;
  }

  .filter__exportbutton__wrap {
    width: 100%;

    .filter__wrap {
      width: 50%;
      gap: 1.2rem;
      justify-content: space-between;

      .ant-select-selector {
        padding: 0 0.3rem;
      }

      .ant-select-arrow {
        margin-top: -0.5rem;
      }
      .ant-picker-input {
        ::placeholder,
        input {
          font-size: 0.85rem;
          color: var(--blackPrimary);
        }
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 0.85rem;
      }
    }
  }

  .latestad__performance {
    padding: 1.5rem;
    background-color: #ebf4ff;
    border-radius: 0.5rem;
    margin-top: 1rem;
    .latestad__performance__body {
      padding: 0 1rem;
      gap: 1rem;
      margin: 0.3rem 0;

      .latestad__image {
        width: 30rem;
        height: 14rem;
        overflow: hidden;
        border-radius: 0.5rem;

        video {
          object-fit: cover;
        }
      }

      .latestad__textsection {
        width: calc(100% - 30rem);

        .latestad__descriptiom {
          width: 90%;
          text-align: start;
          word-break: break-all;
        }

        .adlikes__comments {
          gap: 1rem;
          margin: 0;
          margin-bottom: 2rem;

          .likescomment__text {
            color: var(--darkGrayPrimary);

            svg {
              font-size: 1rem;
              margin: 0 0 -0.2rem 0;
            }
          }
        }

        .latestad__footer__metrics {
          margin-bottom: 0.5rem;

          span {
            color: var(--darkGrayPrimary);
          }
        }
      }
    }
  }
  .overviewsums__wrap {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.4rem;

    .overviewsums_main {
      width: 23.5%;
      align-items: flex-start;
      padding: 1rem;
      border: 0.1rem solid #e4e4e4;
      border-radius: 0.6rem;

      .overviewsums_ptext {
        margin: 0;
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 0.4rem;
        font-family: "LexendMedium", sans-serif;
      }

      .overviewsums_amount {
        color: #353535;
        font-weight: 500;
        font-family: "LexendMedium", sans-serif;
        margin-bottom: 0.5rem;
      }
      .overviewsums_footertext {
        color: #646464;
        font-size: 0.74rem;
      }
    }
  }

  .viewerslocations__wrap {
    padding: 1.2rem 1rem;
    margin-top: 1.4rem;
    border: 0.1rem solid #e4e4e4;
    border-radius: 0.6rem;
    width: 100%;

    .viewerslocations__wrapsub {
      width: 100%;
      flex-wrap: wrap;

      .viewerslocations__main {
        width: 48%;
        margin: 1rem 0;

        .location__view {
          font-size: 0.85rem;
          color: var(--blackSecondary);
        }
      }
    }
  }

  .charts__wrap {
    margin-top: 1.4rem;
    gap: 1.4rem;
    .chartwrap__main {
      padding: 1.2rem 1rem;
      border-radius: 0.6rem;
      border: 0.1rem solid #e4e4e4;
    }
    .barchart__wrap {
      width: 66%;
    }
    .piechart__wrap {
      width: 30%;
    }
  }

  @media screen and (max-width: 1300px) {
    .filter__exportbutton__wrap {
      .filter__wrap {
        width: 78%;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .filter__exportbutton__wrap {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-end;

      .filter__wrap {
        width: 100%;
        gap: 0;
        margin-bottom: 1rem;

        & > div {
          width: 49%;
        }
      }
    }

    .latestad__performance {
      padding: 1rem;

      .latestad__performance__body {
        padding: 0;
        gap: 0.8rem;
        margin: 0;
        flex-direction: column;

        .latestad__image {
          width: 100%;
          height: 12rem;
          border-radius: 0.3rem;

          img {
            object-fit: cover;
          }
        }

        .latestad__textsection {
          width: 100%;

          .latestad__descriptiom {
            width: 100%;
          }
        }
      }
    }

    .overviewsums__wrap {
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid #e4e4e4;

      .overviewsums_main {
        width: 80%;
        min-width: 80%;
      }
    }

    .charts__wrap {
      flex-wrap: wrap;
      .chartwrap__main {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .viewerslocations__wrap {
      .viewerslocations__wrapsub {
        .viewerslocations__main {
          width: 100%;
          margin: 1rem 0;
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;
  }
`;
