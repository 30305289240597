import React, { useState } from "react";
import { FormItem, SelectMainWrap, LohSelect } from "./select.styles";
import { IoIosArrowDown as PlainArrowDown } from "react-icons/io";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useSelectAll } from "../../../../utils/hooks/useSelectAll";
import { Select } from "antd";

interface IProps {
  props?: any;
  placeholder?: string;
  validator: any;
  textarea?: boolean;
  type?: string;
  name?: string;
  children?: any;
  icon?: any;
  mode?: string;
  radius?: string;
  borderColor?: string;
  color?: string;
  onChange?: any;
  value?: any;
  valuesArray?: any;
  setFieldsValue?: any;
  width?: string;
  margin?: string;
  disabled?: boolean;
  label?: string;
}
type StatusProps = {
  success: string;
  error: string;
};

const SelectField: React.FC<IProps> = (
  {
    placeholder,
    name,
    validator,
    children,
    icon,
    mode,
    radius,
    borderColor,
    color,
    valuesArray,
    onChange,
    setFieldsValue,
    width,
    margin,
    disabled,
    label,
    value,
  },
  props
) => {
  const [status, setStatus] = useState<StatusProps>();

  const handleSelectAll = (value: any) => {
    if (onChange) {
      onChange(value);
      return;
    }
    if (value.includes("all")) {
      if (value.length === valuesArray.length + 1) {
        return setFieldsValue({
          locations: [],
        });
      }
      return setFieldsValue({
        locations: valuesArray,
      });
    }

    return value;
  };

  return (
    <SelectMainWrap width={width} margin={margin} {...props}>
      {icon && <div className="select__icon">{icon}</div>}

      {label && <label>{label}</label>}

      <FormItem
        name={name}
        rules={[
          {
            validator: (rules, value) => validator(rules, value, setStatus),
          },
        ]}
        hasicon={!!icon ? true : false}
        radius={radius}
        borderColor={borderColor}
        backgroundColor={disabled ? "#f2f2f2" : "#fff"}
      >
        <LohSelect
          color={color}
          placeholder={placeholder}
          onFocus={() => setStatus(status)}
          {...props}
          suffixIcon={
            status?.error ? (
              <BsExclamationCircleFill color="var(--errorPrimary)" size={18} />
            ) : (
              <PlainArrowDown />
            )
          }
          hasicon={!!icon ? true : false}
          mode={mode}
          onChange={handleSelectAll}
          disabled={disabled}
          value={value}
        >
          {mode && (
            <Select.Option key="all" value="all">
              SELECT ALL
            </Select.Option>
          )}
          {children}
        </LohSelect>
      </FormItem>

      {status?.error && <p className="error__text">{status.error}</p>}
    </SelectMainWrap>
  );
};

export default SelectField;
