import React, { useState, useEffect } from "react";
import { Flex } from "antd";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import { AdsUploadWrap } from "./uploadAd.styles";
import { AiOutlineClose } from "react-icons/ai";
import { FaDotCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AdContent from "./sections/adContent";
import AdBudget from "./sections/adBudget";
import Quiz from "./sections/quiz";
import ReviewAd from "./sections/reviewAd";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getSingleAdvert } from "../../../../network/advert";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { ActionTypes } from "../../../../context";

const tableTabs = ["Ad Content", "Ad Budget", "Quiz", "Review"];

//if default shekel earning is 1 shekel for every 20 views, if not it's 1 view for x shekels user sets
export const defaultShekelEarn = 1 / 20;

const UploadAds = () => {
  const [activeTableTab, setActiveTabeTab] = useState(0);
  const [hasProcessedAdvert, setHasProcessedAdvert] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const {
    data: advert,
    isLoading: advertLoading,
    refetch,
  } = useQuery(["advert", state?.id], () => getSingleAdvert(state?.id), {
    enabled: !!state?.id,
  });

  const {
    dispatch,
    state: { app },
  } = useAdvertiserContext();

  useEffect(() => {
    if (!!advert && !hasProcessedAdvert) {
      dispatch({
        type: ActionTypes.CurrentAdvert,
        payload: {
          currentAdvert: {
            title: advert?.data?.advert?.title || app?.currentAdvert?.title,
            description:
              advert?.data?.advert?.description ||
              app?.currentAdvert?.description,
            videoId:
              advert?.data?.advert?.videoId || app?.currentAdvert?.videoId,
            thumbnailId:
              advert?.data?.advert?.thumbnailId ||
              app?.currentAdvert?.thumbnailId,
            callToActionURL:
              advert?.data?.advert?.callToActionURL ||
              app?.currentAdvert?.callToActionURL,
            callToActionTitle:
              advert?.data?.advert?.callToActionTitle ||
              app?.currentAdvert?.callToActionTitle,
            locations:
              advert?.data?.advert?.locations || app?.currentAdvert?.locations,
            costPerView:
              advert?.data?.advert?.costPerView ||
              app?.currentAdvert?.costPerView,
            maxSpend:
              advert?.data?.advert?.maxSpend || app?.currentAdvert?.maxSpend,
            videoUrl:
              advert?.data?.advert?.video?.publicURL ||
              app?.currentAdvert?.videoUrl,
            thumbnailUrl:
              advert?.data?.advert?.thumbnail?.publicURL ||
              app?.currentAdvert?.thumbnailUrl,
            questions: !!advert?.data?.advert?.questions?.length
              ? advert?.data?.advert?.questions.map((item: any) => ({
                  question: item.question,
                  options: [
                    item.optionOne,
                    item.optionTwo,
                    item.optionThree,
                    item.optionFour,
                  ],
                  answer: item.answer,
                  id: item.id,
                }))
              : [
                  {
                    question: "",
                    options: ["", "", "", ""],
                    answer: "",
                    id: 0,
                  },
                ],
          },
        },
      });
      // Set flag to true after the first advert update is processed
      setHasProcessedAdvert(true);
    }
  }, [advert, hasProcessedAdvert]);

  const uploadAdScreens = [
    <AdContent
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      refetch={refetch}
    />,
    <AdBudget
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      refetch={refetch}
    />,
    <Quiz
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      advert={advert}
      refetch={refetch}
    />,
    <ReviewAd
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      status={advert?.data?.advert?.status}
      refetch={refetch}
      advertLoading={advertLoading}
    />,
  ];

  const editAdScreens = [
    <ReviewAd
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      status={advert?.data?.advert?.status}
      advertLoading={advertLoading}
      refetch={refetch}
    />,
    <AdContent
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      refetch={refetch}
    />,
    <AdBudget
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      refetch={refetch}
    />,
    <Quiz
      setActiveTabeTab={setActiveTabeTab}
      activeTableTab={activeTableTab}
      advert={advert}
      refetch={refetch}
    />,
  ];
  return (
    <DashboardLayout>
      <AdsUploadWrap vertical stepwidth={(activeTableTab + 1) * 33.3}>
        <Flex justify="space-between" align="center">
          <h1 className="adupload__header">
            {state?.activity === "Edit" && "Edit / "}Upload Ad
          </h1>

          <div className="close__route" onClick={() => navigate(-1)}>
            <AiOutlineClose size={25} color="#9F9F9F" />
          </div>
        </Flex>

        {state?.activity !== "Edit" && (
          <Flex className="adupload__stepper__wrap" justify="center">
            <Flex className="adupload__stepper__sub" justify="space-between">
              <Flex className="blue__line__outer">
                <div className="blue__line__inner"></div>
              </Flex>
              {tableTabs?.map((item: any, idx: number) => (
                <Flex
                  key={idx}
                  className={`step__item ${
                    activeTableTab > idx && "completed__step__item"
                  }`}
                  vertical
                  align="center"
                  id={`${activeTableTab === idx && "active__step__item"}`}
                >
                  <div className="checkcircle__wrap">
                    {activeTableTab > idx ? (
                      <IoIosCheckmarkCircle color="var(--bluePrimary)" />
                    ) : (
                      <FaDotCircle
                        className="dot__icon"
                        color={
                          activeTableTab === idx
                            ? "var(--bluePrimary)"
                            : "#ebebebf7"
                        }
                      />
                    )}
                  </div>
                  <p className="step__number">Step {idx + 1}</p>
                  <p className="step__text">{item}</p>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}

        {(state?.activity === "Edit" ? editAdScreens : uploadAdScreens)?.map(
          (item, idx) =>
            idx === activeTableTab && (
              <React.Fragment key={idx}>{item}</React.Fragment>
            )
        )}
      </AdsUploadWrap>
    </DashboardLayout>
  );
};

export default UploadAds;
