import React, { useState } from "react";
import { Flex, notification } from "antd";
import { validateBasicField } from "../../../../../utils/function-helpers/form-validators";
import { TextField } from "../../../../../components/lib/Form";
import { FiEdit } from "react-icons/fi";
import { LohButton } from "../../../../../components/lib/Button";
import { MdArrowBack } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAdvertiserContext } from "../../../../../context/advertiserContext/main";
import { decodeAnswerAoption } from "../../../../../utils/function-helpers/getAnswerOptions";
import ReactPlayer from "react-player";
import {
  createAdvert,
  publishAdvert,
  updateAdvert,
} from "../../../../../network/advert";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { formatMoney } from "../../../../../utils/function-helpers/string-functions";
import LohliLoader from "../../../../../components/lib/PageLoader/pageLoader";
import { defaultShekelEarn } from "../uploadAd";

interface IProps {
  setActiveTabeTab?: any;
  activeTableTab: number;
  status?: string;
  advertLoading?: boolean;
  refetch?: any;
}
const ReviewAd: React.FC<IProps> = ({
  setActiveTabeTab,
  activeTableTab,
  status,
  advertLoading,
  refetch,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [action, setAction] = useState("");

  const {
    state: { app },
  } = useAdvertiserContext();

  const handleFormSubmit = async (buttonAction: string) => {
    setIsSubmitting(true);
    const questionsUploadPayload = [{}];
    setAction(buttonAction);

    app?.currentAdvert?.questions?.map((item: any, idx: number) => {
      questionsUploadPayload.push({
        question: item.question,
        options: [
          item.options[0],
          item.options[1],
          item.options[2],
          item.options[3],
        ],
        answer: item.answer,
      });
    });
    questionsUploadPayload.shift();

    const payload: {
      videoUrl?: string;
      questions: any;
      thumbnailUrl?: string;
    } = {
      ...app?.currentAdvert,
      questions:
        state?.id && !!app?.currentAdvert?.questions[0].question
          ? app?.currentAdvert?.questions
          : questionsUploadPayload,
    };

    delete payload["videoUrl"];
    delete payload["thumbnailUrl"];

    try {
      if (
        state?.id &&
        buttonAction !== "continue" &&
        buttonAction !== "publish"
      ) {
        await updateAdvert(payload, state.id);

        notification.open({
          message: `Updated`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (
        buttonAction === "publish" ||
        (state?.id && buttonAction === "continue")
      ) {
        await updateAdvert(payload, state.id);
        await publishAdvert(state.id);

        notification.open({
          message: `Published`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (!state?.id && buttonAction === "draft") {
        const createId = await createAdvert(payload);
        location.state = {
          activity: state?.activity,
          id: createId?.data?.advert?.id,
        };
        notification.open({
          message: `Saved`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });
      } else if (!state?.id && buttonAction === "continue") {
        const createId = await createAdvert(payload);

        notification.open({
          message: `Success`,
          description: "Data uploaded successfully",
          icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
        });

        await publishAdvert(createId?.data?.advert?.id);
        navigate("/ads");

        location.state = {
          activity: state?.activity,
          id: createId?.data?.advert?.id,
        };
      }
      setIsSubmitting(false);
      refetch();
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <Flex vertical>
      {advertLoading && state?.activity === "Edit" ? (
        <LohliLoader height="25rem" />
      ) : (
        <>
          <Flex
            className="uploadad__subsection__header uploadad__subsection__header__withbordertop"
            justify="space-between"
          >
            <Flex vertical align="flex-start">
              <h3 className="header__text">Review Content</h3>
              <span className="header__description">
                Review and edit your ad before publishing.{" "}
              </span>
            </Flex>

            <span
              className="edit__button"
              onClick={() =>
                setActiveTabeTab(state?.activity === "Edit" ? 1 : 0)
              }
            >
              Edit <FiEdit />
            </span>
          </Flex>
          <Flex vertical>
            <Flex justify="center">
              <Flex vertical className="form__sub__wrap">
                <h3 className="review__h3_header">{app.currentAdvert.title}</h3>
                <span className="review__header__description">
                  {app.currentAdvert.description}
                </span>

                <h3 className="review__h3_header">Video</h3>

                <Flex className="review__media__wrap">
                  {app?.currentAdvert?.videoUrl && (
                    <ReactPlayer
                      url={app?.currentAdvert?.videoUrl}
                      width={"100%"}
                      height={"100%"}
                      controls
                    />
                  )}
                </Flex>

                <h3 className="review__h3_header">Thumbnail</h3>

                <Flex className="review__thumbnail__wrap">
                  {app?.currentAdvert?.thumbnailUrl && (
                    <img src={app?.currentAdvert?.thumbnailUrl} />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            className="uploadad__subsection__header uploadad__subsection__header__withbordertop"
            justify="space-between"
          >
            <Flex vertical align="flex-start">
              <h3 className="header__text">Quiz Question</h3>
              <span className="header__description">
                Review and edit questions before publishing.
              </span>
            </Flex>

            <span
              className="edit__button"
              onClick={() =>
                setActiveTabeTab(state?.activity === "Edit" ? 3 : 2)
              }
            >
              Edit <FiEdit />
            </span>
          </Flex>

          <Flex vertical>
            <Flex justify="center">
              <Flex vertical className="form__sub__wrap" justify="center">
                {app?.currentAdvert?.questions?.map(
                  (item: Record<string, any>, idx: number) => (
                    <Flex vertical key={item.id} className="question__blocks">
                      <Flex justify="space-between" align="flex-end">
                        <label>Question {idx + 1}</label>
                      </Flex>
                      <TextField
                        validator={validateBasicField}
                        placeholder="Enter Question"
                        disabled
                        defaultValue={item.question}
                      />
                      <TextField
                        validator={validateBasicField}
                        placeholder="A. Enter Answer"
                        hasCheckBox={`optiona${idx}`}
                        isChecked={
                          decodeAnswerAoption(item.answer, idx) ===
                          `optiona${idx}`
                        }
                        defaultValue={item.options[0]}
                        disabled
                      />
                      <TextField
                        validator={validateBasicField}
                        placeholder="B. Enter Answer"
                        hasCheckBox={`optionb${idx}`}
                        isChecked={
                          decodeAnswerAoption(item.answer, idx) ===
                          `optionb${idx}`
                        }
                        defaultValue={item.options[1]}
                        disabled
                      />
                      <TextField
                        validator={validateBasicField}
                        placeholder="C. Enter Answer"
                        name={`optionc${idx}`}
                        hasCheckBox={`optionc${idx}`}
                        isChecked={
                          decodeAnswerAoption(item.answer, idx) ===
                          `optionc${idx}`
                        }
                        defaultValue={item.options[2]}
                        disabled
                      />

                      <TextField
                        validator={validateBasicField}
                        placeholder="D. Enter Answer"
                        name={`optiond${idx}`}
                        hasCheckBox={`optiond${idx}`}
                        isChecked={
                          decodeAnswerAoption(item.answer, idx) ===
                          `optiond${idx}`
                        }
                        defaultValue={item.options[3]}
                        disabled
                      />
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            className="uploadad__subsection__header uploadad__subsection__header__withbordertop"
            justify="space-between"
          >
            <Flex vertical align="flex-start">
              <h3 className="header__text">Budget</h3>
              <span className="header__description">
                Add / Set Shekels budget for each ad placement
              </span>
            </Flex>

            <span
              className="edit__button"
              onClick={() =>
                setActiveTabeTab(state?.activity === "Edit" ? 2 : 1)
              }
            >
              Edit <FiEdit />
            </span>
          </Flex>

          <Flex vertical>
            <Flex justify="center">
              <Flex vertical className="form__sub__wrap">
                <Flex
                  justify="space-between"
                  align="flex-end"
                  className="labels__wrap__withborder"
                >
                  <label>Advert Budget</label>
                  <label
                    className="rightinformation__label  nrightinformation__label__nobg"
                    id="rightinformation__label__noborderspan"
                  >
                    {app.currentAdvert.maxSpend}{" "}
                    <span>
                      shekel
                      {Number(app.currentAdvert.maxSpend) > 1 ? "s" : ""}
                    </span>
                  </label>
                </Flex>
                <Flex
                  justify="space-between"
                  align="flex-end"
                  className="labels__wrap__withborder"
                >
                  <label>Shekels a Watcher can earn per view</label>
                  <label
                    className="rightinformation__label  nrightinformation__label__nobg"
                    id="rightinformation__label__noborderspan"
                  >
                    {app.currentAdvert.costPerView}{" "}
                    <span>
                      shekel
                      {Number(app.currentAdvert.costPerView) > 1 ? "s" : ""}
                    </span>
                  </label>
                </Flex>

                <Flex
                  className="budget__wrap__box"
                  justify="space-between"
                  align="center"
                >
                  <Flex vertical align="center">
                    <p className="budgetwrap__titletext">
                      Budget Amount in Shekels
                    </p>
                    <h3 className="budgetwrap__amount">
                      {app.currentAdvert.maxSpend || 0}
                    </h3>
                    <span className="budgetwrap__footertext">
                      (₦
                      {formatMoney(app.currentAdvert.maxSpend * 200, false) ||
                        "0.00"}
                      )
                    </span>
                  </Flex>
                  <Flex vertical align="center">
                    <p className="budgetwrap__titletext">
                      Shekels allocation per watcher{" "}
                    </p>
                    <h3 className="budgetwrap__amount">
                      {app.currentAdvert.costPerView === defaultShekelEarn
                        ? 1
                        : app.currentAdvert.costPerView || 0}
                    </h3>
                    <span className="budgetwrap__footertext">
                      (₦
                      {formatMoney(
                        (app.currentAdvert.costPerView === defaultShekelEarn
                          ? 1
                          : app.currentAdvert.costPerView || 0) * 200,
                        false
                      ) || "0.00"}
                      )
                    </span>
                  </Flex>
                  <Flex vertical align="center">
                    <p className="budgetwrap__titletext">Projected Views </p>
                    <h3 className="budgetwrap__amount">
                      {Number(
                        app.currentAdvert.costPerView > 0 &&
                          app.currentAdvert.maxSpend /
                            app.currentAdvert.costPerView
                      ).toFixed() || 0}
                      {/* {Number(
                    Number(app.currentAdvert.costPerView) > 0 &&
                      Number(app.currentAdvert.maxSpend) /
                        Number(app.currentAdvert.costPerView)
                  ).toFixed(2) || 0} */}
                    </h3>
                    <span className="budgetwrap__footertext">Views</span>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className="buttonandback__wrap"
              justify="space-between"
              align="center"
            >
              {state?.activity === "Edit" ? (
                <p className="goback"></p>
              ) : (
                <p
                  className="goback"
                  onClick={() => setActiveTabeTab(activeTableTab - 1)}
                >
                  <MdArrowBack /> Go Back
                </p>
              )}

              {state?.activity === "Edit" ? (
                <Flex
                  className="buttonandback__wrap"
                  justify="flex-end"
                  align="center"
                >
                  {status === "DRAFT" ? (
                    <Flex
                      className="button__wrap"
                      justify="flex-end"
                      gap="0.5rem"
                    >
                      <LohButton
                        backgroundColor="transparent"
                        borderColor="var(--bluePrimary)"
                        color="var(--bluePrimary)"
                        onClick={() => handleFormSubmit("edit")}
                        htmlType="submit"
                        loading={isSubmitting && action === "edit"}
                      >
                        Update
                      </LohButton>
                      <LohButton
                        htmlType="submit"
                        loading={isSubmitting && action === "publish"}
                        onClick={() => handleFormSubmit("publish")}
                      >
                        Publish
                      </LohButton>
                    </Flex>
                  ) : (
                    <Flex
                      className="button__wrap"
                      justify="flex-end"
                      gap="0.5rem"
                    >
                      <LohButton
                        backgroundColor="transparent"
                        borderColor="var(--bluePrimary)"
                        color="var(--bluePrimary)"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </LohButton>
                      <LohButton
                        onClick={() => handleFormSubmit("edit")}
                        htmlType="submit"
                        loading={isSubmitting}
                      >
                        Update
                      </LohButton>
                    </Flex>
                  )}
                </Flex>
              ) : (
                <Flex className="button__wrap" justify="flex-end" gap="0.5rem">
                  <LohButton
                    backgroundColor="transparent"
                    borderColor="var(--bluePrimary)"
                    color="var(--bluePrimary)"
                    onClick={() => handleFormSubmit("draft")}
                    loading={isSubmitting && action === "draft"}
                    htmlType="submit"
                  >
                    Save to draft
                  </LohButton>
                  <LohButton
                    onClick={() => handleFormSubmit("continue")}
                    loading={isSubmitting && action === "continue"}
                    htmlType="submit"
                  >
                    Publish
                  </LohButton>
                </Flex>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default ReviewAd;
