import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import { AdsWrap } from "./adsOverview.styles";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/dashboardLayout";
import LohTable from "../../../../components/lib/AdsTable/adsTable";
import { LohButton } from "../../../../components/lib/Button";
import { FaPlus } from "react-icons/fa6";
import { getAllAdverts } from "../../../../network/advert";
import { useQuery } from "react-query";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { orderBy, sortBy } from "lodash";
import NavTabs from "../../../../components/lib/Tabs/navTabs";
import LohliLoader from "../../../../components/lib/PageLoader/pageLoader";

const AdsPage = () => {
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  const {
    data: publishedAds,
    isLoading,
    refetch: refetchPubished,
    isRefetching: publishedRefetching,
  } = useQuery(["published", user?.id], () =>
    getAllAdverts("PUBLISHED", user?.id)
  );

  const {
    data: draftsAds,
    refetch: refetchDrafts,
    isRefetching: draftsRefetching,
    isLoading: draftsLoading,
  } = useQuery(["drafts", user?.id], () => getAllAdverts("DRAFT", user?.id));

  const { data: archivedAds, refetch: refetchArchived } = useQuery(
    ["archives", user?.id],
    () => getAllAdverts("ARCHIVED", user?.id)
  );

  useEffect(() => {
    setActiveTabeTab({
      title: "Published Ads",
      status: "PUBLISHED",
      noDataState: "No ads have been published",
      data: publishedAds?.data,
      refetch: refetchPubished,
      isRefetching: publishedRefetching,
      isLoading: isLoading,
    });
  }, [publishedAds, isLoading]);

  const [activeTableTab, setActiveTabeTab] = useState({
    title: "",
    status: "",
    noDataState: "",
    data: [],
    refetch: refetchPubished,
    isRefetching: publishedRefetching,
    isLoading: isLoading,
  });

  const tableTabs = [
    {
      title: "Published Ads",
      status: "PUBLISHED",
      noDataState: "No ads have been published",
      data: publishedAds?.data,
      refetch: refetchPubished,
      isRefetching: publishedRefetching,
      isLoading: isLoading,
    },
    {
      title: "Drafts",
      status: "DRAFT",
      noDataState: "No ads in your drafts",
      data: draftsAds?.data,
      refetch: refetchDrafts,
      isRefetching: draftsRefetching,
      isLoading: draftsLoading,
    },
    {
      title: "Archived",
      status: "ARCHIVED",
      noDataState: "No ads in your archives",
      data: archivedAds?.data,
      refetch: refetchArchived,
      // isRefetching: publishedRefetching,
      // isLoading: isLoading,
    },
  ];

  const handleAdUploadRoute = () => {
    window.location.href = "/ads/upload";
  };

  const orderedList = orderBy(
    activeTableTab.status === "PUBLISHED"
      ? publishedAds?.data
      : activeTableTab.status === "DRAFT"
      ? draftsAds?.data
      : archivedAds?.data,
    "advert.id",
    ["desc"]
  );

  return (
    <DashboardLayout>
      <AdsWrap vertical>
        <Flex justify="space-between">
          <h1 className="adupload__header">Ad Upload</h1>

          <Flex gap="1rem">
            {activeTableTab?.data?.length > 0 && (
              <LohButton
                width="10rem"
                svgOpacity="1"
                onClick={handleAdUploadRoute}
              >
                <FaPlus color="#fff" /> Upload Ads
              </LohButton>
            )}
          </Flex>
        </Flex>

        <NavTabs
          setActiveTab={setActiveTabeTab}
          activeTab={activeTableTab}
          navTabData={tableTabs}
          hasItemCount
        />

        {draftsLoading ||
        isLoading ||
        publishedRefetching ||
        draftsRefetching ? (
          <LohliLoader />
        ) : (
          <LohTable tableData={orderedList} activeTableTab={activeTableTab} />
        )}
      </AdsWrap>
    </DashboardLayout>
  );
};

export default AdsPage;
