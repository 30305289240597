import React from "react";
import { Flex } from "antd";
import { ThreeDots } from "react-loader-spinner";

interface IProps {
  height?: string;
}

const LohliLoader: React.FC<IProps> = ({ height }) => {
  return (
    <Flex
      className="loader"
      justify="center"
      align="center"
      style={{ height: height || "24rem" }}
    >
      <ThreeDots
        height="70"
        width="70"
        radius="9"
        color="#646464"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </Flex>
  );
};

export default LohliLoader;
