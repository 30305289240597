import styled from "styled-components";
import { Button } from "antd";

type Custom = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  opacity?: string;
  radius?: string;
  boxShadow?: string;
  fontSize?: string;
  color?: string;
  hoverColor?: string;
  fontWeight?: string;
  hoverBg?: string;
  outlined?: boolean;
  maxWidth?: string;
  svgOpacity?: string;
};

export const LohButton = styled(Button)<Custom>`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "max-content"};
  min-height: ${({ height }) => height || "45px"};
  padding: ${({ padding }) => padding || ".8rem 0rem .8rem 0rem"};
  border: ${({ border }) => border || "1px solid var(--bluePrimary)"};
  border-color: ${({ borderColor, backgroundColor }) =>
    borderColor || "var(--bluePrimary)"};
  background: ${({ backgroundColor }) =>
    backgroundColor || "var(--bluePrimary)"};
  border-radius: ${({ radius }) => radius || "2.0625rem"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (max-width: 350px) {
    height: ${({ height }) => height === "68px" && "50px"};
  }

  @media (max-width: 450px) {
    min-height: ${({ height }) => height || "35px"};
    padding: ${({ padding }) => padding || ".5rem 0rem .5rem 0rem"};
  }

  span,
  a {
    color: ${({ color }) => color || "#fff"};
    font-size: ${({ fontSize }) => fontSize || "1rem"};
  }
  svg {
    margin: 0 10px -3px 0;
    opacity: ${({ svgOpacity }) => svgOpacity || ".5"};
    font-size: 1rem;
  }

  :hover,
  :focus,
  :active {
    background-color: transparent;
    border-color: ${({ hoverBg, borderColor }) =>
      hoverBg ? hoverBg : borderColor || "var(--bluePrimary)"};
    outline: none;

    span {
      color: ${({ borderColor }) => borderColor || "var(--bluePrimary)"};
    }
  }
`;
