import styled from "styled-components";

export const ChartWrap = styled("div")`
  height: 23rem;
  padding-top: 1rem;

  .piechart__nodata {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 0.8rem;
    }
  }
`;
