// general layout for investor General page

import React, { useEffect, useState } from "react";
import { Dropdown, Flex, notification as toastMessage } from "antd";
import { useNavigate } from "react-router-dom";
import { NotificationsWrap } from "./notifications.styles";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import { FaEllipsisV } from "react-icons/fa";
import { TbEye } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { useQuery } from "react-query";
import {
  advertiserDeleteSingleNotifications,
  advertiserGetAllNotifications,
  advertiserReadAllNotifications,
  advertiserReadSingleNotifications,
} from "../../../network/notifications";
import moment from "moment";
import {
  BsEmojiSmileFill,
  BsEmojiSmileUpsideDownFill,
  BsPersonCircle,
} from "react-icons/bs";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import { LohButton } from "../../../components/lib/Button";
import { useAdvertiserContext } from "../../../context/advertiserContext/main";
import ReadSingleNotificationModal from "./Modals/readNotificationModal";
import { FiLoader } from "react-icons/fi";
import { validateImageFile } from "../../../utils/function-helpers/form-validators";
import ReactPlayer from "react-player";
import { editNotificationUsernameString } from "../../../utils/function-helpers/string-functions";
import LohliAvatar from "../../../components/lib/Avatar/avatar";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface IProps {}

const NotificationsPage: React.FC<IProps> = () => {
  const navigate = useNavigate();

  const {
    state: { user },
  } = useAdvertiserContext();

  const {
    data: notification,
    isLoading,
    refetch,
  } = useQuery(["published"], advertiserGetAllNotifications);

  const [newNotification, setNewNotification] = useState<any>([]);
  const [oldNotification, setOldNotification] = useState<any>([]);
  const [isReadingAll, setIsReadingAll] = useState(false);
  const [showReadSingleNotificationModal, setShowReadSingleNotificationModal] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [singleNotification, setSingleNotification] = useState<any>({
    id: -1,
    description: "",
    createdAt: "",
  });

  useEffect(() => {
    filterNotifications();
  }, [notification]);

  const filterNotifications = () => {
    const getOldNotification = notification?.data?.filter(
      (item: Record<string, any>) => !!item.read
    );
    const getNewNotification = notification?.data?.filter(
      (item: Record<string, any>) => !item.read
    );

    setNewNotification(getNewNotification);
    setOldNotification(getOldNotification);
  };

  const notificationMenuItems = [
    {
      key: "2",
      label: (
        <p
          className="dropdown__items"
          onClick={async () => {
            await readSingleNotification(singleNotification?.id);
            setShowReadSingleNotificationModal(true);
          }}
        >
          <TbEye /> View Notification
        </p>
      ),
    },

    {
      key: "4",
      label: (
        <p
          className="dropdown__items dropdown__items__red"
          onClick={() => handleDeleteNotification(singleNotification?.id)}
        >
          {isDeleting ? <FiLoader /> : <RiDeleteBinLine />} Delete Notification
        </p>
      ),
    },
  ];

  const notificationsMenuProps = {
    items: notificationMenuItems,
  };

  const readAllNotifications = async () => {
    setIsReadingAll(true);
    try {
      await advertiserReadAllNotifications({
        read: true,
        userId: user?.id,
      });
      await refetch();
      filterNotifications();
      setIsReadingAll(false);
    } catch (e) {
      setIsReadingAll(false);
    }
  };

  const readSingleNotification = async (notificationId: number) => {
    try {
      await advertiserReadSingleNotifications({
        read: true,
        notificationId: notificationId,
      });
      await refetch();
      filterNotifications();
    } catch (e) {}
  };

  const handleDeleteNotification = async (notificationId: number) => {
    setIsDeleting(true);

    try {
      await advertiserDeleteSingleNotifications({
        notificationId: notificationId,
      });
      await refetch();
      filterNotifications();
      setIsDeleting(false);
      toastMessage.open({
        message: `Successful`,
        description: "Advert Has Been Deleted",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
    } catch (e: any) {
      setIsDeleting(false);

      if (e?.response) {
        toastMessage.open({
          message: `Delete Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        toastMessage.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  return (
    <DashboardLayout>
      <NotificationsWrap
        vertical
        justify="flex-start"
        align="center"
        className="has__coinbg"
      >
        {notification?.data?.length > 0 ? (
          <>
            <Flex vertical className="notifications__section__wrap ">
              {newNotification?.length > 0 && (
                <>
                  <Flex justify="space-between" align="center">
                    <h3 className="notification__header__title">New</h3>
                    {newNotification?.length > 1 && (
                      <span
                        className="readall__text"
                        onClick={readAllNotifications}
                      >
                        {isReadingAll ? (
                          <AiOutlineLoading3Quarters />
                        ) : (
                          "Read All"
                        )}
                      </span>
                    )}
                  </Flex>
                  {newNotification.map(
                    (item: Record<string, any>, idx: number) => (
                      <Flex className="notification__item" align="start">
                        <div className="notifcations___avatar">
                          <LohliAvatar
                            imageUrl={
                              item?.user?.profile?.avatar?.publicURL || ""
                            }
                          />
                        </div>
                        <p className="notification__body__text">
                          {editNotificationUsernameString(
                            item?.description,
                            item?.user?.profile?.username
                          ) || "No Subtext"}
                          <p className="notification__body__time">
                            {moment(item?.createdAt).fromNow()}
                          </p>
                        </p>

                        {item?.actionType?.includes("AD_") && (
                          <div
                            className="notifcations___videoimage"
                            onClick={() =>
                              navigate("/uploads/view", {
                                state: {
                                  activity: "View",
                                  id: item?.advertisement?.id,
                                  type: "ad",
                                },
                              })
                            }
                          >
                            <img
                              src={item?.advertisement?.thumbnail?.publicURL}
                              alt=""
                            />
                          </div>
                        )}
                        {item?.actionType?.includes("POST_") && (
                          <div
                            className="notifcations___videoimage"
                            onClick={() =>
                              navigate("/uploads/view", {
                                state: {
                                  activity: "View",
                                  id: item?.post?.id,
                                  type: "post",
                                },
                              })
                            }
                          >
                            {!validateImageFile(
                              item?.post?.media[0]?.publicURL || ""
                            ) ? (
                              <ReactPlayer
                                url={item?.post?.media[0]?.publicURLL}
                                width={"100%"}
                                height={"100%"}
                              />
                            ) : (
                              <img
                                src={item?.post?.media[0]?.publicURL}
                                alt=""
                              />
                            )}
                          </div>
                        )}
                        <Dropdown
                          menu={notificationsMenuProps}
                          placement="bottom"
                          trigger={["hover"]}
                          onOpenChange={() => {
                            console.log("opens");
                            setSingleNotification(item);
                          }}
                        >
                          <a>
                            <Flex className="ellipsis">
                              <FaEllipsisV
                                className="notificatios__icon"
                                color="var(--lightGrayPrimary)"
                              />
                            </Flex>
                          </a>
                        </Dropdown>
                      </Flex>
                    )
                  )}
                </>
              )}
            </Flex>

            {oldNotification?.length > 0 && (
              <Flex vertical className="notifications__section__wrap">
                <h3 className="notification__header__title ">Older</h3>

                {oldNotification?.map(
                  (item: Record<string, any>, idx: number) => (
                    <Flex className="notification__item" align="start">
                      <div className="notifcations___avatar">
                        <LohliAvatar
                          imageUrl={
                            item?.user?.profile?.avatar?.publicURL || ""
                          }
                        />
                      </div>
                      <p className="notification__body__text">
                        {editNotificationUsernameString(
                          item?.description,
                          item?.user?.profile?.username
                        ) || "No Subtext"}

                        <p className="notification__body__time">
                          {" "}
                          {moment(item?.createdAt).fromNow()}
                        </p>
                      </p>
                      {item?.actionType?.includes("AD_") ? (
                        <div
                          className="notifcations___videoimage"
                          onClick={() =>
                            navigate("/uploads/view", {
                              state: {
                                activity: "View",
                                id: item?.advertisement?.id,
                                type: "ad",
                              },
                            })
                          }
                        >
                          <img
                            src={item?.advertisement?.thumbnail?.publicURL}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="notifcations___videoimage"></div>
                      )}
                      {item?.actionType?.includes("POST_") && (
                        <div
                          className="notifcations___videoimage"
                          onClick={() =>
                            navigate("/uploads/view", {
                              state: {
                                activity: "View",
                                id: item?.post?.id,
                                type: "post",
                              },
                            })
                          }
                        >
                          {!validateImageFile(
                            item?.post?.media[0]?.publicURL || ""
                          ) ? (
                            <ReactPlayer
                              url={item?.post?.media[0]?.publicURLL}
                              width={"100%"}
                              height={"100%"}
                            />
                          ) : (
                            <img src={item?.post?.media[0]?.publicURL} alt="" />
                          )}
                        </div>
                      )}
                      <Dropdown
                        menu={notificationsMenuProps}
                        placement="bottom"
                        onOpenChange={() => {
                          setSingleNotification(item);
                        }}
                        trigger={["hover"]}
                      >
                        <a>
                          <Flex className="ellipsis">
                            <FaEllipsisV
                              className="notificatios__icon"
                              color="var(--lightGrayPrimary)"
                            />
                          </Flex>
                        </a>
                      </Dropdown>
                    </Flex>
                  )
                )}
              </Flex>
            )}
          </>
        ) : isLoading ? (
          <LohliLoader />
        ) : (
          <Flex className="no__notifications">
            <p>You have no notifications</p>
          </Flex>
        )}
      </NotificationsWrap>

      <ReadSingleNotificationModal
        showReadSingleNotificationModal={showReadSingleNotificationModal}
        setShowReadSingleNotificationModal={setShowReadSingleNotificationModal}
        singleNotification={singleNotification}
      />
    </DashboardLayout>
  );
};

export default NotificationsPage;
