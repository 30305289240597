import { instance } from "./axios";

export const getUserWallet = async () => {
  const { data } = await instance.get(`user/wallet`);
  return data;
};

export const getUserWalletTransactions = async (
  page?: number,
  limit?: number
) => {
  const { data } = await instance.get(
    `user/transactions?limit=${limit}&page=${page}`
  );
  return data;
};

export const exportTransactions = async () => {
  const { data } = await instance.get(`user/transactions?isForExports=true`);
  return data;
};

export const userCreateVirtualAccount = async () => {
  const { data } = await instance.post(`bank/create-virtual-bank`);
  return data;
};
export const userFundWalletWithSquadco = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post(`squadco/payment-link`, payload);
  return data;
};

export const getBankList = async () => {
  const { data } = await instance.get(`bank/banks-list`);
  return data;
};
