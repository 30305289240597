import { Flex } from "antd";
import styled from "styled-components";

type Custom = {
  stepwidth?: number;
};
export const AdsUploadWrap = styled(Flex)<Custom>`
  padding: 2.5rem 4rem;
  overflow-y: auto;

  .adupload__header {
    font-size: 2rem;
  }
  .close__route {
    cursor: pointer;
  }

  .adupload__stepper__wrap {
    width: 100%;

    .adupload__stepper__sub {
      width: 70%;
      position: relative;

      .blue__line__outer {
        position: absolute;
        top: 3rem;
        z-index: 1;
        width: 92%;
        height: 0.1rem;
        left: 5%;
        background-color: #e4e7ec;

        .blue__line__inner {
          width: ${({ stepwidth }) => stepwidth + "%"};
          height: 100%;
          background-color: var(--bluePrimary);
        }
      }

      .step__item {
        margin-top: 2rem;
        z-index: 2;
        .checkcircle__wrap {
          border-radius: 100%;
          background-color: #fff;
          svg {
            font-size: 2rem;
            margin-bottom: 1.2rem;
          }
          .dot__icon {
            font-size: 1.8rem;
          }
        }

        .step__number {
          margin: 0;
          font-weight: 400;
          color: var(--blackPrimary);
          font-size: 0.9rem;
          font-family: "LexendMedium", sans-serif;
        }
        .step__text {
          /* font-family: "LexendLight", sans-serif; */
          color: var(--lightGrayPrimary);
          font-size: 0.9rem;
        }
      }

      #active__step__item {
        .step__number,
        .step__text {
          color: var(--bluePrimary);
        }
        .checkcircle__wrap {
          .dot__icon {
            font-size: 2rem;
            border-radius: 100%;
            border: 0.2rem solid #ebe0fc;
          }
        }
      }
    }
  }

  .uploadad__subsection__header {
    margin-top: 1rem;
    border-bottom: 0.1rem solid #e4e7ec;
    width: 100%;
    .header__text {
      font-size: 1rem;
    }
    .header__description {
      color: var(--lightGrayPrimary);
      font-size: 0.85rem;
      margin-bottom: 0.9rem;
      max-width: 44rem;
    }
  }
  .uploadad__subsection__header__withbordertop {
    border-bottom: 0;
    border-top: 0.1rem solid #e4e7ec;
    padding-top: 1.3rem;

    .edit__button {
      color: var(--bluePrimary);
      font-size: 0.9rem;
      border-bottom: 0.1rem solid var(--bluePrimary);
      height: max-content;
      cursor: pointer;
      svg {
        margin: 0 0 -0.1rem 0.1rem;
      }
    }
  }
  .uploadad__default__shekels {
    margin-bottom: 6px;
    label {
      margin-bottom: 0;
    }
  }
  .form__sub__wrap {
    width: 75%;
    padding-top: 2rem;
    label {
      font-size: 0.85rem;
      margin-bottom: 0.15rem;
      white-space: nowrap;
      a {
        font-size: 0.8rem;
        text-decoration: underline;
      }
    }

    .review__h3_header {
      font-size: 1rem;
      margin: 0.7rem 0;
    }
    .review__header__description {
      color: var(--lightGrayPrimary);
      font-size: 0.85rem;
      margin-bottom: 0.9rem;
    }

    .review__thumbnail__wrap,
    .review__media__wrap {
      margin-bottom: 1.3rem;
      height: 30rem;
    }
    .labels__wrap__withborder {
      border-bottom: 0.1rem solid var(--lightGrayPrimary);
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    .question__blocks {
      margin-bottom: 1rem;
    }

    .rightinformation__label {
      border-radius: 2rem;
      background-color: #f2f2f2;
      padding: 0.3rem 1rem;
      margin-bottom: 0.3rem;
      white-space: normal;
      text-align: end;
      span {
        font-size: 0.7rem;
        color: var(--lightGrayPrimary);
        padding-right: 0.3rem;
        margin-right: 0.3rem;
        border-right: 0.1rem solid var(--lightGrayPrimary);
      }
    }

    #rightinformation__label__noborderspan {
      span {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }
    }

    .nrightinformation__label__nobg {
      padding: 0;
      background-color: transparent;
      margin-bottom: 0.15rem;
    }
    ::placeholder {
      font-size: 0.9rem;
    }
    .ant-form-item {
      border-radius: 0.6rem;
      border-color: 0.1rem solid #e4e7ec;

      .ant-select-selection-placeholder {
        margin-top: 0.7rem;
      }

      .ant-select-selection-search {
        input {
          font-size: 1rem;
        }
      }
    }
    .upload__field {
      border: 0.1rem solid #e4e7ec;
      border-radius: 0.6rem;
      height: 8rem;
      margin-bottom: 1.5rem;

      .upload__icon {
        background-color: #e4e7ec;
        padding: 0.7rem;
        border-radius: 100%;
        margin-bottom: 0.7rem;
      }
      .upload__text {
        color: var(--lightGrayPrimary);
        .click__touploap {
          color: var(--bluePrimary);
        }
      }
      .max__size {
        color: var(--lightGrayPrimary);
        margin-top: 0.15rem;
        font-size: 0.7rem;
      }
    }

    .upload__field__dragging {
      border-color: var(--bluePrimary);
      background-color: #a7c8ef3d;
    }

    .fieldwith__content {
      border: none;
      background-color: #f7f7f7;
      & > div {
        width: 100%;
        padding: 0 1rem;
        gap: 0.6rem;

        .delete__pause__wrap {
          width: 100%;
          gap: 0.5rem;
          margin-top: 0.3rem;
          z-index: 6;
          .delete__file {
            color: var(--errorPrimary);
            font-size: 0.8rem;
            cursor: pointer;
            svg {
              margin: 0 0 -0.15rem 0;
              font-size: 0.9rem;
            }
          }

          .pause_play__file {
            font-size: 0.8rem;
            cursor: pointer;
            opacity: 0.4;
            svg {
              margin: 0 -0.15rem -0.15rem 0;
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .budget__wrap__box {
      background-color: var(--bluePrimary);
      border-radius: 0.6rem;
      height: 8rem;
      box-shadow: 0px 0.5rem 0.6rem 0.2rem #e4e7ec;
      padding: 0 5rem;

      .budgetwrap__titletext {
        color: #fff;
        margin: 0;
        white-space: nowrap;
      }
      .budgetwrap__amount {
        color: #fff;
        font-weight: 600;
        font-size: 1.2rem;
        margin: 0;
      }
      .budgetwrap__footertext {
        font-size: 0.8rem;
        margin: 0;
        color: #b0cef2;
      }
    }
    .questionguide__wrap {
      position: absolute;
      right: 4rem;
      bottom: 9.5rem;
      z-index: 9;

      .questionguide__suggestionbox {
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04),
          0px 20px 24px -4px rgba(16, 24, 40, 0.1);
        border-radius: 1rem;
        border: 0.1rem solid #b2d6ff;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        background-color: #fff;
        max-width: 100%;
        width: 0;
        height: 0;
        margin: 0 1rem -0.7rem 0;
        padding: 0;

        .suggestion__header {
          font-size: 1rem;
        }
        .suggestion__subheader {
          color: var(--lightGrayPrimary);
          font-size: 0.8rem;
          margin-bottom: 0.7rem;
        }

        .suggestion__questions {
          text-align: start;
          width: 100%;
          border-radius: 4rem;
          border: 0.1rem solid var(--lightGraySecondary);
          font-size: 0.8rem;
          padding: 0.3rem 1rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
      }

      .questionguide__suggestionbox__headervidible {
        width: 11rem;
        height: 3rem;
        padding: 0.8rem 0 1rem 0;
      }
      .questionguide__suggestionbox__bodyvisible {
        padding: 1rem;
        width: 26rem;
        height: 18rem;
      }
      .questionguide__popupicon {
        border: 0.1rem solid #fff;
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem;
        background-color: var(--bluePrimary);
        box-shadow: 0px 0.01rem 0.8rem 0.1rem var(--lightGraySecondary);
        z-index: 5;
        cursor: pointer;

        svg {
          font-size: 1rem;
        }
      }
    }
  }
  .buttonandback__wrap {
    width: 100%;
    padding-top: 2rem;
    border-top: 0.1rem solid #e4e7ec;
    margin-top: 2rem;

    .goback {
      cursor: pointer;
      color: var(--lightGrayPrimary);
      font-weight: 500;

      svg {
        margin: 0 0.2rem -0.1rem 0;
      }
    }

    .button__wrap {
      button {
        width: 10rem;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .adupload__stepper__wrap {
      .adupload__stepper__sub {
        width: 95%;

        .blue__line__outer {
          width: 90%;
          left: 7%;
        }
      }
    }

    .buttonandback__wrap {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding-top: 1rem;
      margin-top: 1rem;
      .goback {
        margin-top: 2rem;
      }

      .button__wrap {
        flex-direction: column;
        align-items: center;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }

    .form__sub__wrap {
      width: 100%;
      .calltoaction__subwrap {
        flex-direction: column;
      }

      .budget__wrap__box {
        padding: 0 1rem;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .form__sub__wrap {
      .rightinformation__label {
        font-size: 0.61rem;
        padding: 0.1rem 0.7rem;
      }
      .nrightinformation__label__nobg {
        padding: 0;
        font-size: 0.5rem;
      }

      .addquestion__button__wrap {
        button {
          span {
            font-size: 0.9rem;
          }
        }
      }

      .questionguide__wrap {
        right: 15px;
        bottom: 8rem;
      }

      .budget__wrap__box {
        .budgetwrap__titletext {
          white-space: wrap;
          text-align: center;
          font-size: 0.8rem;
          line-height: 1rem;
        }
        .budgetwrap__amount {
          margin: 0.3rem;
        }
      }
    }
  }
`;
