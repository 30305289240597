import { Flex } from "antd";
import styled from "styled-components";

export const FeedsPosterProfilePageWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  .post__loading {
    width: 100%;
  }

  .goback__text {
    color: var(--lightGrayPrimary);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    svg {
      margin: 0 0.1rem -0.22rem 0;
      font-size: 1.2rem;
    }

    &:hover {
      color: var(--darkGrayPrimary);
    }
  }

  .nodata__wrap {
    width: 100%;
    height: 20rem;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }

  .feedposter__profileheader {
    gap: 1rem;
    .avatar__wrap {
      width: 10rem;
      height: 10rem;
      border-radius: 100%;
    }

    .feedposter__details {
      gap: 0.5rem;
      cursor: pointer;

      .feedposter__user {
        margin: 0.2rem 0.5rem 0 0;
        white-space: nowrap;

        .feedposter__username {
          text-align: start;
          font-size: 0.75rem;
          color: var(--darkGrayPrimary);
        }
      }

      .feedposter__description {
        color: var(--lightGrayPrimary);
        text-align: start;
        font-size: 0.9rem;
        max-width: 40rem;
      }

      .feedpost__time {
        font-size: 0.7rem;
        color: var(--lightGrayPrimary);
        margin-top: 0.5rem;
      }

      .feedposter__buttons {
        border-radius: 1rem;
        padding: 0.3rem 1rem;
        background-color: #f2f2f2;
        color: var(--darkGrayPrimary);
        font-size: 0.8rem;
        border: 0.08rem solid #f2f2f2;
        white-space: nowrap;

        svg {
          font-size: 1rem;
          margin: 0 0.1rem -0.2rem 0;
        }
      }
    }
  }

  .feedposts__posterprofile__wrap {
    width: 100%;
    padding: 1rem 0;

    .feedposts__posterprofile__wrapsub {
      padding: 0 12.5rem;
      width: 100%;
      gap: 1.5rem;

      .feedposts__posterprofile__main {
        width: 100%;
        gap: 1rem;
        border-bottom: 0.1rem solid #e4e4e4;
        padding: 1rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #f2f2f2;
          border-radius: 0.5rem;
        }

        .adpost__header {
          gap: 1rem;
          .adpost__header__top {
            gap: 0.5rem;
            .adpost__avatar__wrap {
              width: 3rem;
              height: 3rem;
              border-radius: 100%;
            }

            .adpost__user {
              margin: 0.2rem 0.5rem 0 0;
              white-space: nowrap;
              width: calc(100% - 8rem);

              .adpost__username {
                text-align: start;
                font-size: 0.75rem;
                color: var(--darkGrayPrimary);
              }
            }

            .adpost__time {
              font-size: 0.7rem;
              color: var(--lightGrayPrimary);
              margin-top: 0.5rem;
              white-space: nowrap;
            }
          }

          .adpost__description {
            text-align: start;
            font-size: 0.9rem;
            margin: 0;
            line-height: 0.5rem;
          }
        }

        .adpost__video {
          width: 100%;
          height: 22rem;
          border-radius: 0.5rem;
          overflow: hidden;

          img {
            object-fit: cover;
          }
        }

        .likes__comments__wrap {
          margin-top: -0.5rem;
          .likes__comments {
            font-size: 0.9rem;
            color: var(--lightGrayPrimary);

            svg {
              margin: 0 -0.1rem -0.2rem 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 0;

    .goback__text,
    .feedposter__profileheader {
      padding: 0 15px;
    }

    .feedposter__profileheader {
      gap: 1rem;
      .avatar__wrap {
        width: 3rem;
        height: 3rem;

        position: absolute;
      }

      .feedposter__details {
        .feedposter__details__top {
          margin-left: 3.5rem;
        }
      }
    }

    .feedposts__posterprofile__wrap {
      padding: 1rem 0;
      .feedposts__posterprofile__wrapsub {
        padding: 0;
      }
    }
  }
`;
