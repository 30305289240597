import { Flex, Modal } from "antd";
import styled from "styled-components";

type Custom = {
  copied?: boolean;
};
export const ProfilePageWrap = styled(Flex)<Custom>`
  padding: 2.5rem 4rem;
  overflow-y: hidden;
  gap: 1.5rem;

  .profile__header {
    font-size: 2rem;
  }

  .close__route {
    cursor: pointer;
  }
  .profile__headersection {
    width: 100%;
    border-bottom: 0.1rem solid #e4e7ec;
    padding-bottom: 2rem;

    .profile__headersection__left {
      .userimage__wrap {
        width: 12rem;
        height: 12rem;
        border-radius: 100%;
        position: relative;

        .upload__wrap {
          position: absolute;
          right: -0;
          bottom: 0.5rem;
          cursor: pointer;
        }
      }

      .header__lefttexts {
        margin-bottom: 2rem;

        .agency__name {
          font-weight: 600;
          font-size: 1.4rem;
          margin-bottom: 0.25rem;
          color: var(--blackSecondary);
        }
        .username {
          text-align: start;
        }
      }
    }

    .accountnumber__section {
      .accountnumber__section__top {
        border-radius: 3rem;
        background-color: var(--blackSecondary);
        padding: 0.6rem 1.3rem;
        gap: 0.5rem;
        position: relative;
        cursor: pointer;

        .copied__text {
          position: absolute;
          background-color: var(--successPrimary);
          color: #fff;
          transition: all 0.3s ease-in-out;
          visibility: ${({ copied }) => (copied ? "visible" : "hidden")};
          font-size: 0.7rem;
          padding: 0 0.4rem;
          right: 0.5rem;
          border-radius: 0.3rem;
          top: 0.5rem;
        }

        .bank__name {
          color: #fff;
          font-size: 0.8rem;
          margin: 0;
        }
        .account__number {
          color: #fff;
          font-size: 1rem;
          margin: 0;
        }
      }
      .tap__instructions {
        font-size: 0.77rem;
      }
    }
  }

  .details__section {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #e4e7ec;
    gap: 4rem;

    .details__left {
      width: 22rem;

      .details__subtitle {
        text-align: left;
        font-size: 0.9rem;
        line-height: 1.25rem;
        opacity: 0.7;
      }
    }

    form {
      margin-top: 1.8rem;
      .ant-picker-input > input {
        font-size: 1rem;
      }

      :where(.css-dev-only-do-not-override-2i2tap).ant-picker
        .ant-picker-input
        > input-disabled,
      :where(.css-dev-only-do-not-override-2i2tap).ant-picker
        .ant-picker-input
        > input[disabled] {
        color: var(--blackSecondary);
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .profile__headersection {
      .profile__headersection__left {
        gap: 0.7rem;
        .userimage__wrap {
          width: 9.5rem;
          height: 9.5rem;
        }

        .header__lefttexts {
          margin-bottom: 0;
        }
      }

      .accountnumber__section {
        margin-top: 3rem;
      }
    }

    .details__section {
      flex-direction: column;
      gap: 0;

      .details__left {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;

        & > div {
          width: calc(100% - 8.5rem);
        }
      }

      form {
        margin-top: 1rem;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 2.5rem 15px;

    .profile__headersection {
      flex-direction: column;
      align-items: flex-start;

      .profile__headersection__left {
        gap: 0.7rem;

        .header__lefttexts {
          margin-bottom: 2.5rem;
          .agency__name {
            font-size: 1.3rem;
          }
          .username {
            font-size: 0.9rem;
          }
        }
      }
      .accountnumber__section {
        margin: -3rem 0 0 10.3rem;

        .accountnumber__section__top {
          padding: 0.3rem 1rem;
          gap: 0.5rem;

          .bank__name {
            font-size: 0.7rem;
          }
          .account__number {
            font-size: 0.8rem;
          }
        }
        .tap__instructions {
          display: none;
        }
      }
    }
  }
`;

export const VerifyBvnModalWrap = styled(Modal)`
  .bvn__subheader {
    font-size: 0.9rem;
    color: #ffa500;
    text-align: left;
  }
`;
