import { Flex, Modal } from "antd";
import styled from "styled-components";
import { FiCheck } from "react-icons/fi";

export const UploadViewWrap = styled(Flex)`
  padding: 2.5rem 8rem;

  svg {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      scale: 1.2;
    }
  }

  .goback__text {
    color: var(--lightGrayPrimary);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    svg {
      margin: 0 0.1rem -0.22rem 0;
      font-size: 1.2rem;
    }

    &:hover {
      color: var(--darkGrayPrimary);
    }
  }

  .demo__image {
    width: 100%;
    height: 23rem;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 2rem 0 1.3rem 0;
  }
  .upload__title {
    line-height: 2rem;
    width: 80%;
  }
  .upload__description,
  .hashtag {
    color: var(--lightGrayPrimary);
    text-align: start;
    font-size: 0.9rem;
  }

  .upload__description {
    margin-bottom: 1rem;
  }

  .uploadbuttons {
    border-radius: 1rem;
    padding: 0.3rem 1rem;
    background-color: #f2f2f2;
    color: var(--darkGrayPrimary);
    font-size: 0.8rem;
    border: 0.08rem solid #f2f2f2;

    svg {
      font-size: 1rem;
      margin: 0 0.1rem -0.2rem 0;
    }
  }
  .uploadbuttons__wrap {
    .view__questions {
      text-decoration: underline;
      color: var(--bluePrimary);
      font-size: 0.85rem;
      cursor: pointer;
    }
    .uploadbuttons__wrap__left {
      gap: 0.5rem;
      .avatar__wrap {
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
      }

      .upload__user {
        margin: 0;
        line-height: 0.5rem;
        white-space: nowrap;
        margin-right: 0.5rem;
        font-size: 0.8rem;
        .upload__username {
          font-size: 0.7rem;
          text-align: left;
          margin: 1rem 0 0 0;
          line-height: 0.5rem;
        }
      }

      .clickable__uploadbutton {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #ebf4ff;
        }
      }

      #active__buttom {
        background-color: #ebf4ff;
        border: 0.08rem solid var(--bluePrimary);
        color: var(--bluePrimary);

        svg {
          color: var(--bluePrimary);
        }
      }
    }
  }

  .upload__buttons_content {
    border-top: 0.01rem solid var(--lightGraySecondary);
    margin-top: 2rem;
    padding-top: 1rem;

    .analytics__body {
      width: 100%;
      gap: 1rem;

      .analytics__shekels {
        padding: 10px 8px;
        background-color: #0062d6;
        &__text-one {
          font-size: 1rem;
          font-weight: 500;
          line-height: 24px;
          color: #ffffff;
          margin: 0;
        }
        &__text-two {
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 18px;
          color: #ffffff;
          opacity: 0.7;
          margin: 0;
        }
        &__divider {
          width: 1px;
          height: 14px;
          background-color: #ffffff;
          opacity: 0.7;
        }
      }

      .analytics__topcards__wrap {
        width: 100%;

        .analytics__topcards__main {
          border: 0.01rem solid var(--lightGraySecondary);
          border-radius: 0.5rem;
          width: 49%;
          &--earners {
            width: 100%;
          }
          padding: 1.5rem;
          align-items: flex-start;
          .activity__number {
            color: var(--blackSecondary);
            font-size: 2rem;
          }

          .activity__progress__wrap {
            width: 100%;
            align-items: center;
            flex-wrap: nowrap;
            gap: 1rem;
            margin: 0.3rem 0;

            .activity__progessvalue {
              color: var(--lightGrayPrimary);
              font-size: 0.8rem;
              white-space: nowrap;
            }

            .left__preogresstext {
              width: 26%;
            }
            .right__preogresstext {
              text-align: right;
              width: 10%;
            }
          }
        }
      }
      .charts__wrap {
        gap: 1.4rem;
        border-radius: 0.6rem;
        border: 0.01rem solid var(--lightGraySecondary);

        .chartwrap__main {
          padding: 1.2rem 1rem;
        }
        .barchart__wrap {
          width: 50%;
        }
        .piechart__wrap {
          width: 35%;
        }
      }

      .viewerslocations__wrap {
        padding: 1.2rem 1rem;
        margin-top: 1.4rem;
        border: 0.1rem solid #e4e4e4;
        border-radius: 0.6rem;
        width: 100%;

        .viewerslocations__wrapsub {
          width: 100%;
          flex-wrap: wrap;

          .viewerslocations__main {
            width: 48%;
            margin: 1rem 0;

            .location__view {
              font-size: 0.85rem;
              color: var(--blackSecondary);
            }
          }
        }
      }
    }

    .comments__body {
      gap: 1rem;
      width: 100%;
      .comments__header {
        .upload__buttons_content__title {
          margin: 0;
        }
      }

      .comment__input {
        position: relative;
        .avatar__wrap__input {
          margin: -0.7rem 0 0 -0.4rem;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 100%;
        }
        .ant-form-item {
          height: 2.8rem;

          input {
            margin: -0.3rem 0 0 1rem;
            width: 70%;
            font-size: 0.9rem;
            padding-top: 1rem;
          }
          ::placeholder {
            font-size: 0.9rem;
          }
        }

        .ant-btn {
          position: absolute;
          right: 0.3rem;
          top: 0.3rem;
          padding: 0;
          span {
            font-size: 0.8rem;
            margin-right: 0.3rem;
          }
          svg {
            margin-right: 0;
          }
        }
      }

      .comments__main {
        gap: 1rem;
        width: 100%;

        .comment__item {
          background-color: #f2f2f2;
          padding: 1rem;
          border-radius: 0.5rem;
          width: 100%;

          .commenter_avatar {
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
          }

          .comment__item__right {
            margin-left: 0.3rem;
            width: 100%;
            .commenter__name {
              font-weight: 600;
              font-size: 0.8rem;

              .commenter__username {
                color: var(--lightGrayPrimary);
                font-size: 0.8rem;
              }
            }
            .commenter__text {
              font-size: 0.8rem;
              max-width: 20rem;
              text-align: start;
              line-height: 1.1rem;
            }

            .comment__footer__wrap {
              .comment__footer {
                font-size: 0.8rem;
                color: var(--lightGrayPrimary);
                cursor: pointer;

                svg {
                  margin: 0 0 -0.13rem 0;
                }
              }

              .comment__likes {
                color: var(--bluePrimary);
              }

              .report__comment {
                color: var(--errorPrimary);
              }
            }

            button {
              margin-top: 1rem;

              span {
                font-size: 0.8rem;
              }
              svg {
                margin: 0 0 -0.3rem 0.1rem;
              }
            }

            .comment__reply__input {
              margin-top: 1rem;
              width: 60%;

              button {
                margin: 0;
                top: 0.4rem;
              }
            }

            .comment__replieswrap {
              border-top: 0.01rem solid var(--lightGraySecondary);
              width: 100%;
              margin-top: 1rem;
              padding-top: 1rem;
              gap: 1rem;

              .commentreplies__header {
                margin-bottom: 0.3rem;
                align-items: center;

                .commentreplies_avatar {
                  margin-right: 0.3rem;
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .postcards__wrap {
      flex-wrap: wrap;
      padding-top: 1rem;
      justify-content: space-between;
      .card__wrap {
        width: 48%;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .uploadbuttons {
      font-size: 0.7rem;
      white-space: nowrap;
      padding: 0.1rem 0.7rem;
      border: 0.05rem solid #f2f2f2;
      margin-top: 0.5rem;

      svg {
        font-size: 0.8rem;
      }
    }

    .uploadbuttons__wrap {
      position: relative;

      .view__questions {
        font-size: 0.7rem;
        position: absolute;
        right: 0;
        top: 0.5rem;
      }

      .uploadbuttons__wrap__left {
        width: 100%;
        gap: 0.5rem;
        flex-wrap: wrap;

        .avatar__wrap {
          width: 3rem;
          height: 3rem;
          overflow: hidden;
          border-radius: 100%;
        }

        .upload__user {
          width: calc(100% - 4rem);
        }

        #active__buttom {
          border: 0.05rem solid var(--bluePrimary);
        }
      }
    }

    .upload__buttons_content {
      .analytics__body {
        .analytics__topcards__wrap {
          flex-direction: column;
          gap: 1rem;
          .analytics__topcards__main {
            width: 100%;
            padding: 0.7rem;
          }
        }

        .charts__wrap {
          gap: 1.4rem;
          flex-direction: column;
          padding: 1.2rem 0;

          .chartwrap__main {
            padding: 0 1rem;
          }
          .barchart__wrap {
            width: 100%;
          }
          .piechart__wrap {
            width: 100%;
          }
        }
        .viewerslocations__wrap {
          .viewerslocations__wrapsub {
            .viewerslocations__main {
              width: 100%;
              margin: 1rem 0;
            }
          }
        }
      }

      .comments__body {
        .comments__main {
          .comment__item {
            background-color: #f2f2f2;
            padding: 1rem 0.7rem;

            .commenter_avatar {
              width: 2.2rem;
              height: 2.2rem;
            }

            .comment__item__right {
              margin-left: 0.3rem;

              .comment__reply__input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

export const ReportModalWrap = styled(Modal)`
  .buttons__wrap {
    margin-top: 1rem;
  }

  .reportmiodal__subtitle {
    font-size: 0.8rem;
  }

  .checkbox__wrap {
    margin: 1rem 0 2rem 0;
    gap: 0.5rem;

    span {
      font-size: 0.8rem;
    }
  }
`;

export const DeleteModalWrap = styled(Modal)`
  .buttons__wrap {
    margin-top: 2rem;
  }
`;

export const QuestionsModalWrap = styled(Modal)`
  .modal__header {
    font-size: 1.2rem;
  }
  .questionmodal__subtitle {
    margin: 1rem 0 0.3rem 0;
    color: var(--blackSecondary);
    font-size: 0.9rem;
  }
  .question {
    text-align: start;
    color: var(--bluePrimary);
    font-size: 0.9rem;
  }
  .answers {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0.05rem solid var(--lightGraySecondary);
    border-radius: 0.5rem;
    font-size: 0.9rem;
  }

  #correct__answer {
    background-color: var(--successPrimary);
    color: #fff;
    position: relative;
  }
  .buttons__wrap {
    margin-top: 2rem;
    border-top: 0.05rem solid var(--lightGraySecondary);
    padding-top: 1rem;
  }
`;
