import { Flex, Modal } from "antd";
import { Header } from "antd/es/layout/layout";
import styled from "styled-components";

export const NotificationsWrap = styled(Flex)`
  background-repeat: no-repeat;
  background-size: 100 100;
  background-position: center;
  padding: 3rem 1rem 1rem 1rem;

  .notifications__section__wrap {
    border-radius: 0.5rem;
    width: 41rem;
    background-color: #f5f5f5;
    padding: 1.5rem;
    margin-bottom: 1rem;

    .notification__header__title {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .readall__text {
      color: var(--bluePrimary);
      text-decoration: underline;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }

    .notification__item {
      margin-bottom: 0.7rem;
      width: 100%;
      flex-wrap: nowrap;

      .notificatios__icon {
        margin: 0.7rem 0 0 0.5rem;
      }

      .notifcations___avatar {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
      }

      .notification__body__text {
        text-align: start;
        margin: 0;
        width: calc(100% - 6rem);
        margin-left: 0.3rem;
        white-space: wrap;
        font-size: 0.85rem;
        line-height: 1.2rem;

        .notification__body__time {
          text-align: start;
          color: var(--lightGrayPrimary);
          font-size: 0.75rem;
          margin: 0;
        }
      }

      .notifcations___videoimage {
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      padding: 1.5rem 0.7rem;
    }
  }
`;
export const NotificationModalWrap = styled(Modal)`
  .buttons__wrap {
    margin-top: 2rem;
  }

  .notification__body {
    font-size: 0.8rem;
    margin: 0;
  }
  small {
    opacity: 0.5;
  }
`;
