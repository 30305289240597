import React, { useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateFacebook,
  validateInstagram,
  validateTiktok,
  validateTwitter,
  validateYoutube,
} from "../../../../utils/function-helpers/form-validators";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { updateAdvertiserSocialLinks } from "../../../../network/auth";

interface IProps {
  setStep: (values: string) => void;
  setProgressBarWidth: (values: string) => void;
}

const SocialMediaLinks: React.FC<IProps> = ({
  setStep,
  setProgressBarWidth,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (values: Record<string, any>) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
    };

    try {
      await updateAdvertiserSocialLinks(payload);
      setIsSubmitting(false);
      setStep("Profile Details");
      setProgressBarWidth("75%");
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const formDetails = [
    {
      name: "facebook",
      placeholder: "Facebook Link (optional)",
      validator: validateFacebook,
      icon: <FaFacebook />,
    },
    {
      name: "twitter",
      placeholder: "Twitter (X) Link (optional)",
      validator: validateTwitter,
      icon: <FaXTwitter />,
    },
    {
      name: "instagram",
      placeholder: "Instagram Link (optional)",
      validator: validateInstagram,
      icon: <FaInstagram />,
    },
    {
      name: "tiktok",
      placeholder: "Tiktok Link (optional)",
      validator: validateTiktok,
      icon: <FaTiktok />,
    },
    {
      name: "youtube",
      placeholder: "Youtube Link (optional)",
      validator: validateYoutube,
      icon: <FaYoutube />,
    },
  ];
  const handleSkip = () => {
    setStep("Profile Details");
    setProgressBarWidth("75%");
  };

  return (
    <Flex className="userdetails__form__main" vertical align="center">
      <h2>Basic information</h2>
      <span className="userdetails__subheader">
        You will be able to change this later.
      </span>
      <small className="skip__text" onClick={handleSkip}>
        Skip
      </small>
      <CustomForm
        formDetails={formDetails}
        handleSubmit={handleFormSubmit}
        formFinishButtonText="Continue"
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
      />
    </Flex>
  );
};

export default SocialMediaLinks;
