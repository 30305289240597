import React, { useState } from "react";
import { Flex } from "antd";
import LohliLogo from "../../../assets/images/dashboard/lohli-logo.svg";
import { UserDetailsWrap } from "../../../components/layouts/AuthLayout/userdetails.styles";
import BasicInformation from "./sections/basic-information";
import Username from "./sections/username";
import PersonalDetails from "./sections/profile-details";
import SocialMediaLinks from "./sections/social-media";

const UserDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [progressBarWidth, setProgressBarWidth] = useState("25%");
  const [step, setStep] = useState("Basic Information");
  const [profileDetails, setProfileDetails] = useState({});

  return (
    <UserDetailsWrap progressBarWidth={progressBarWidth}>
      <Flex className="userdetails__main" vertical align="start">
        <div className="userdetails__logo__wrap">
          <img src={LohliLogo} alt="" />
        </div>

        <Flex className="userdetails__form__main" justify="center">
          <Flex className="userdetails__form__sub" vertical>
            <Flex className="progress__bar" justify="start">
              <div className="progress__bar__inner"></div>
            </Flex>

            {step === "Basic Information" && (
              <BasicInformation
                setStep={setStep}
                setProgressBarWidth={setProgressBarWidth}
              />
            )}
            {step === "Username" && (
              <Username
                setStep={setStep}
                setProgressBarWidth={setProgressBarWidth}
                profileDetails={profileDetails}
              />
            )}

            {step === "Socials" && (
              <SocialMediaLinks
                setStep={setStep}
                setProgressBarWidth={setProgressBarWidth}
              />
            )}
            {step === "Profile Details" && (
              <PersonalDetails
                setStep={setStep}
                setProgressBarWidth={setProgressBarWidth}
                setProfileDetails={setProfileDetails}
              />
            )}
          </Flex>
        </Flex>

        <span className="copyright__footer">© Lohli 2023</span>
      </Flex>
    </UserDetailsWrap>
  );
};

export default UserDetails;
