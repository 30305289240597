import { Flex, Modal } from "antd";
import styled from "styled-components";

export const PostCardWrap = styled(Flex)`
  width: 100%;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: max-content;
  padding: 1rem;

  .postcard__header {
    gap: 0.5rem;

    .post__avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      margin-bottom: 0.6rem;
      transition: all 0.3s ease-in-out;
    }

    .postcard__header__textwrap {
      width: 65%;
      .post__title {
        text-align: start;
        margin: 0;
        color: var(--blackPrimary);
        font-size: 0.9rem;
        width: 95%;
        white-space: wrap;
      }
      .post__username {
        font-size: 0.7rem;
        color: var(--lightGrayPrimary);
      }
    }
    .post__date {
      color: var(--lightGrayPrimary);
      margin-top: 0.2rem;
      font-size: 0.8rem;
      white-space: nowrap;
    }
  }
  .post__description {
    text-align: start;
    color: var(--blackSecondary);
    margin: 0;
    font-size: 0.9rem;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .post__video {
    width: 100%;
    height: 12rem;
    border-radius: 0.75rem;
    overflow: hidden;
    position: relative;
    video {
      object-fit: cover;
      z-index: 1;
    }

    .play__icon {
      position: absolute;
      z-index: 2;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-color: #00000033;
      top: 0;

      .play__icon__main {
        width: 2.5rem;
        height: 2.5rem;
        background-color: #f2f2f266;
        border-radius: 100%;
      }
    }
  }
  .card__footer {
    margin: 0.5rem 0;

    & > div {
      gap: 0.8rem;
    }
    .footer__text {
      font-size: 0.9rem;
      color: var(--lightGrayPrimary);

      svg {
        margin: 0 -0.1rem -0.2rem 0;
      }
    }
  }

  &:hover {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 1100px) {
    .postcard__header {
      .post__avatar {
        width: 2rem;
        height: 2rem;
      }
      .postcard__header__textwrap {
        .post__title {
          font-size: 0.8rem;
          line-height: 1rem;
        }
        .post__username {
          font-size: 0.6rem;
        }
      }

      .post__date {
        margin-top: 0;
        font-size: 0.6rem;
      }
    }

    .card__footer {
      .footer__text {
        font-size: 1rem;
        svg {
          width: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem 0;
    .post__video {
      height: 15rem;
    }
    .postcard__header {
      .postcard__header__textwrap {
        width: calc(100% - 5rem);
      }
    }
  }
`;

export const DeleteModal = styled(Modal)`
  .buttons__wrap {
    margin-top: 2rem;
  }
`;
