import React, { useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateBasicField50CharactersTextLimit,
  validateNonRequiredField,
} from "../../../../utils/function-helpers/form-validators";

import { LohButton } from "../../../../components/lib/Button";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { FaIndustry } from "react-icons/fa6";
import {
  getLoggedInAdvertiser,
  submitBankAccountDetails,
  updateAdvertiserBasicInfo,
  verifyBankAccountDetails,
} from "../../../../network/auth";
import { MdDescription as About } from "react-icons/md";
import { ActionTypes } from "../../../../context";
import { getBankList } from "../../../../network/wallet";

interface IProps {
  activeForm?: number;
  setActiveForm?: any;
  setShowBvnModal?: any;
}

const ProfilePageBankInformation: React.FC<IProps> = ({
  setActiveForm,
  activeForm,
  setShowBvnModal,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fillFormDetails, setFillFormDetails] = useState({
    accountName: "",
    accountNumber: "",
    bankCode: "",
  });
  const [bankList, setBankList] = useState();
  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  useEffect(() => {
    const getBanks = async () => {
      const { data } = await getBankList();
      setBankList(data);
    };
    getBanks();
  }, []);

  useEffect(() => {
    setFillFormDetails({
      accountName: user?.bankAccounts[0]?.accountName,
      accountNumber: user?.bankAccounts[0]?.accountNumber,
      bankCode: user?.bankAccounts[0]?.bankCode,
    });
  }, [user]);

  const handleBasicInformationSubmit = async (values: Record<string, any>) => {
    console.log(values);
    const submitPayload = {
      ...values,
    };

    const verifyPayload = {
      accountNumber: values.accountNumber,
      bankCode: values.bankCode,
    };

    setIsSubmitting(true);
    try {
      const verifiedData = await verifyBankAccountDetails(verifyPayload);
      console.log(verifiedData?.data?.accountName);

      setFillFormDetails({
        bankCode: values.bankCode,
        accountNumber: values.accountNumber,
        accountName: verifiedData?.data?.accountName,
      });

      await submitBankAccountDetails({
        ...submitPayload,
        accountName: verifiedData?.data?.accountName,
      });

      const userResult = await getLoggedInAdvertiser();

      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...userResult?.data },
      });
      notification.open({
        message: `Success`,
        description: "Bank information submitted successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setShowBvnModal(true);
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const basicInfoFormDetails = [
    {
      name: "accountNumber",
      placeholder: `Account Number`,
      validator: validateBasicField,
      width: "48.4%",
      label: "Account Number",
    },
    {
      name: "bankCode",
      placeholder: "Bank",
      validator: validateBasicField,
      type: "select",
      width: "48.4%",
      label: "Bank",
      isDatasetObject: true,
      dataSet: bankList,
      icon: <FaIndustry />,
    },
    {
      name: "accountName",
      placeholder: `Account Name`,
      validator: validateNonRequiredField,
      width: "48.4%",
      label: "Account Name (This is filled upon validation)",
      disabled: true,
    },
  ];

  return (
    <Flex className="details__section">
      <Flex className="details__left" vertical>
        <Flex vertical>
          <h3 className="details__title">Bank Account info</h3>
          <p className="details__subtitle">Write a short information</p>
        </Flex>
        {activeForm !== 1 && (
          <LohButton
            padding="0"
            width="7rem"
            height="2.5rem"
            onClick={() => setActiveForm(1)}
          >
            {!user?.submittedBankAccount ? "Add Bank" : "Edit"}
          </LohButton>
        )}
      </Flex>
      <CustomForm
        formFinishButtonText={
          !user?.submittedBankAccount ? "Submit" : "Save Changes"
        }
        handleSubmit={handleBasicInformationSubmit}
        formDetails={basicInfoFormDetails}
        fillFormDetails={fillFormDetails}
        handleCancel={() => setActiveForm(0)}
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
        isFormDisabled={activeForm !== 1}
        gap="1.5rem"
      />
    </Flex>
  );
};

export default ProfilePageBankInformation;
