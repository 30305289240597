import { IAction } from "../types/action.interface";
import { userReducer } from "./user";
import { appReducer } from "./app";
import { v4 as uuidv4 } from "uuid";

export const initialState: Record<string, any> = {
  user: {
    name: "",
  },

  app: {
    currentAdvert: {
      title: "",
      description: "",
      videoId: "",
      thumbnailId: "",
      callToActionURL: "",
      callToActionTitle: "",
      locations: [],
      costPerView: null,
      maxSpend: null,
      videoUrl: "",
      thumbnailUrl: "",
      // youtubeVideoUrl: "",
      questions: [
        {
          question: "",
          options: ["", "", "", ""],
          answer: "",
          id: uuidv4(),
        },
      ],
    },
    deletedQuestionIds: [],
  },
};

export type MainReducerState = typeof initialState;
export type UserReducerState = typeof initialState.user;
export type AppReducerState = typeof initialState.app;

export const mainReducer = (
  state: MainReducerState,
  action: IAction
): MainReducerState => {
  const { user, app } = state;

  return {
    app: appReducer(app, action),
    user: userReducer(user, action),
  };
};
